import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';
//import { map } from 'rxjs/operators';
import { map, tap } from 'rxjs/operators';
//import 'rxjs/add/operator/toPromise';
import { Observable } from 'rxjs';
import { IUserResponse, User } from '../../Models/Users';
import { PostStudentLectureGroupParams, PostSelectedGroups, PostStudentEnrollHistory } from '../../Models/PostParameters';
import { ReturnResultValue } from '../../Models/ResultReturnParameters';
import { StudSearchSubjectsParams, StudentTimeTableOnLecture, PostStudentLectureGroupFullDataParams } from '../../Models/StudentProfile';
import { StudentExamTimeTable } from '../../Models/ExamCenter';
import { CourseSelectFullDataForStudent } from '../../Models/CourseSelect';



@Injectable()
export class RegistrationService {

  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  private headers2 = new HttpHeaders({ 'Content-Type': 'multipart/form-data' });
  private heroesUrl = '/';
  constructor(private http: HttpClient) { }


  GetStudyCourseListForStudentByCurrentSession(params: StudSearchSubjectsParams): Promise<any> {

    return this.http.post("/api/Registration/GetStudyCourseListForStudentByCurrentSession", params, { headers: this.headers })
      .toPromise()
      .then(response => response)
      .catch(this.handleError);
  }

  GetSelectedCourseLectureWithAllData(param: PostStudentLectureGroupFullDataParams): Promise<CourseSelectFullDataForStudent> {

    return this.http.post("/api/Registration/GetSelectedCourseLectureWithAllData", param, { headers: this.headers })
      .toPromise()
      .then(response => response)
      .catch(this.handleError);
  }


  GetStudentCurrentSessionSelectedCourses(): Promise<any> {

    return this.http.post("/api/Registration/GetStudentCurrentSessionSelectedCourses", { headers: this.headers })
      .toPromise()
      .then(response => response)
      .catch(this.handleError);
  }

  GetGroupsOnCourseForStudentSelect(courseId: number): Promise<any> {
    return this.http.post("/api/Registration/GetGroupsOnCourseForStudentSelect", courseId, { headers: this.headers })
      .toPromise()
      .then(response => response)
      .catch(this.handleError);
  }

    GetSelectedCoursesTimeTable(): Promise<Array<StudentTimeTableOnLecture>> {
    return this.http.post("/api/Registration/GetSelectedCoursesTimeTable", { headers: this.headers })
      .toPromise()
      .then(response => response)
      .catch(this.handleError);
  }

  GetExamTimeTable(): Promise<Array<StudentExamTimeTable>> {
    return this.http.post("/api/Registration/GetExamTimeTable", { headers: this.headers })
      .toPromise()
      .then(response => response)
      .catch(this.handleError);
  }



  AvailableCreditsForSelect(): Promise<any> {
    return this.http.post("/api/Registration/AvailableCreditsForSelect", { headers: this.headers })
      .toPromise()
      .then(response => response)
      .catch(this.handleError);
  }

  EnabledGreditForSubjectSelect(): Promise<any> {
    return this.http.post("/api/Registration/EnabledGreditForSubjectSelect", { headers: this.headers })
      .toPromise()
      .then(response => response)
      .catch(this.handleError);
  }

  EnabledRegistrationStatus(): Promise<any> {
    return this.http.post("/api/Registration/EnabledRegistrationStatus", { headers: this.headers })
      .toPromise()
      .then(response => response)
      .catch(this.handleError);
  }

  GetSelectedCourseLectureGroups(enroll: number): Promise<any> {
    return this.http.post("/api/Registration/GetSelectedCourseLectureGroups", enroll, { headers: this.headers })
      .toPromise()
      .then(response => response)
      .catch(this.handleError);
  }

  GetCourseSelectData(courseId: number): Promise<any> {
    return this.http.post("/api/Registration/GetCourseSelectData", courseId, { headers: this.headers })
      .toPromise()
      .then(response => response)
      .catch(this.handleError);
  }

  SaveStudentSelectedCourse(selectedGroups: PostSelectedGroups): Promise<ReturnResultValue> {
    return this.http.post("/api/Registration/SaveStudentSelectedCourse", selectedGroups, { headers: this.headers })
      .toPromise()
      .then(response => response)
      .catch(this.handleError);
  }

  DeleteStudentSelectedCourse(enrollId: number): Promise<ReturnResultValue> {
    return this.http.post("/api/Registration/DeleteStudentSelectedCourse", enrollId, { headers: this.headers })
      .toPromise()
      .then(response => response)
      .catch(this.handleError);
  }


  private handleError(error: any): Promise<any> {
    console.error('An error occurred', error); // for demo purposes only
    return Promise.reject(error.message || error);
  }
}
