import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { HomeComponent } from './home/home.component';
import { CounterComponent } from './counter/counter.component';
import { FetchDataComponent } from './fetch-data/fetch-data.component';
import { Profile } from './Student/Profile/Profile';
import { Card } from './Student/Card/Card';
import { CardService } from './Student/Service/CardService';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatListModule } from '@angular/material/list';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatCardModule } from '@angular/material/card';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTabsModule } from '@angular/material/tabs';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatBadgeModule } from '@angular/material/badge';
import { MatDividerModule } from '@angular/material/divider';
import { MatTreeModule } from '@angular/material/tree';
import { MatRippleModule, MatNativeDateModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';

import { CdkTableModule } from '@angular/cdk/table';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
import { CdkTreeModule } from '@angular/cdk/tree';
import { CreateViewLetterDocument } from './Student/Correspondence/Dialogs/CreateViewLetterDocument';
import { CorrespondenceMainForm } from './Student/Correspondence/CorrespondenceMainForm';
import { PersoneListDialog } from './Components/Dialogs/PersonListDialog/PersoneListDialog';
import { StatementFilter } from './Components/CorrespondenceFilter/StatementFilter';
import { CorrespondenceService } from './Student/Correspondence/Service/CorrespondenceService';
import { SharedService } from './Student/Service/SharedService';
import { ProfileService } from './Student/Service/ProfileService';
import { AuthGuard } from './Identity/_guards';
import { LoginComponent } from './Identity/login';
import { FinanceMainForm } from './Finance/FinanceMainForm';
import { FinanceService } from './Finance/Service/FinanceService';
import { AddWithCredentialsInterceptorService, ErrorInterceptor, fakeBackendProvider } from './Identity/_helpers';
import { FileUpload } from './Components/FileUpload/FileUpload';
import { ProfileImage } from './Components/ProfileImage/ProfileImage';
import { LanguageService } from './Service/LanguageService';
import { SanitizeHtmlPipe } from './Components/Pips/SanitizeHtml/SanitizeHtml';
import { CurrentSemesterSelectSubject } from './Student/Registration/CurrentSemesterSelectSubject/CurrentSemesterSelectSubject';
import { CurrentSemesterSubjectsForSelect } from './Student/Registration/CurrentSemesterSubjectsForSelect/CurrentSemesterSubjectsForSelect';
import { RegistrationService } from './Student/Service/RegistrationService';
import { CourseService } from './Student/Service/CourseService';
import { StudyProgramService } from './Student/Service/StudyProgramService';
import { StudyProgram } from './Student/StudyProgram/StudyProgram';
import { StudentFinanceMainForm } from './Student/Finance/StudentFinanceMainForm';
import { LanguagePipe } from './Models/language';
import { AlertComponent } from './Identity/_components';
import { InformationBoardService } from './Student/Service/InformationBoardService';
import { ViewNewsComponent } from './home/ViewNews/view-news/ViewNews';
import { CookieService } from 'ngx-cookie-service';
import { GraideService } from './Student/Service/GraideService';
import { EditIndStudentProfileData } from './Student/Card/SubDialogs/EditIndStudentProfileData';
import { StudyMaterialService } from './Student/Service/StudyMaterialService';
import { StudentStudyMaterials } from './Student/Card/SubDialogs/StudentStudyMaterials/StudentStudyMaterials';
import { ChartsModule } from 'ng2-charts';
import { ChangePassword } from './Identity/ChangePassword/ChangePassword';
import { ChangeStudentProfile } from './Identity/ChangeStudentProfile/ChangeStudentProfile';
import { UserService } from './Identity/_services/user.service';


import { ButtonAllModule, CheckBoxAllModule } from '@syncfusion/ej2-angular-buttons';;
import { TreeGridAllModule} from '@syncfusion/ej2-angular-treegrid';
import { GridAllModule } from '@syncfusion/ej2-angular-grids';

import { RecurrenceEditorAllModule, MonthAgendaService } from '@syncfusion/ej2-angular-schedule';
import { AudExamSchedule } from './Student/Schedule/AudExamSchedule';
import { RecoverPassword } from './Identity/RecoverPassword/RecoverPassword';

import { ScheduleModule, AgendaService, DayService, WeekService, WorkWeekService, MonthService } from '@syncfusion/ej2-angular-schedule';
import { ShearedDataService } from './Student/Service/ShearedData';
import { QuizService } from './Student/Quiz/Service/QuizService';
import { Quiz } from './Student/Quiz/Quiz';
import { AlertOne } from './Components/Dialogs/AlertDialogs/AlertOne';
import { AngularEditorModule } from '@kolkov/angular-editor';


@NgModule({
    declarations: [
        AppComponent,
        NavMenuComponent,
        HomeComponent,
        CounterComponent,
        FetchDataComponent,
        Profile,
        Card,
        CreateViewLetterDocument,
        CorrespondenceMainForm,
        PersoneListDialog,
        StatementFilter,
        LoginComponent,
        FinanceMainForm,
        FileUpload,
        ProfileImage,
        SanitizeHtmlPipe,
        CurrentSemesterSelectSubject,
        CurrentSemesterSubjectsForSelect,
        StudyProgram,
        StudentFinanceMainForm,
        LanguagePipe,
        AlertComponent, ViewNewsComponent,
        EditIndStudentProfileData, StudentStudyMaterials,
        ChangePassword, ChangeStudentProfile,
        AudExamSchedule, RecoverPassword, Quiz, AlertOne
        //DropDownListComponent
    ],
    imports: [
        BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
        //
        TreeGridAllModule,
        ButtonAllModule,
        CheckBoxAllModule,
        GridAllModule,
        RecurrenceEditorAllModule, ScheduleModule,
        //DialogModule,
        //RichTextEditorAllModule,
        //RichTextEditorAllModule,
        //
        HttpClientModule,
        FormsModule,
        MatFormFieldModule,
        CdkTableModule,
        MatPaginatorModule,
        MatAutocompleteModule,
        MatBadgeModule,
        MatBottomSheetModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatCardModule,
        MatCheckboxModule,
        MatChipsModule,
        MatDatepickerModule,
        MatDialogModule,
        MatDividerModule,
        MatExpansionModule,
        MatGridListModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatMenuModule,
        MatNativeDateModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        MatRippleModule,
        MatSelectModule,
        MatSidenavModule,
        MatSliderModule,
        MatSlideToggleModule,
        MatSnackBarModule,
        MatSortModule,
        MatStepperModule,
        MatTableModule,
        MatTabsModule,
        MatToolbarModule,
        MatTooltipModule,
        MatTreeModule,
        BrowserAnimationsModule,
        ReactiveFormsModule,
        NoopAnimationsModule,
        AngularEditorModule,
        CdkTreeModule,
        ChartsModule,
        RouterModule.forRoot([
            { path: '', component: HomeComponent, pathMatch: 'full', canActivate: [AuthGuard] },
            { path: 'profile', component: Profile, canActivate: [AuthGuard] },
            { path: 'card', component: Card, canActivate: [AuthGuard] },
            { path: 'fetch-data', component: FetchDataComponent, canActivate: [AuthGuard] },
            { path: 'correspondece', component: CorrespondenceMainForm, canActivate: [AuthGuard] },
            { path: 'CurrentSemesterSubjectsForSelect', component: CurrentSemesterSubjectsForSelect, canActivate: [AuthGuard] },
            { path: 'StudyProgram', component: StudyProgram, canActivate: [AuthGuard] },
            { path: 'StudentFinanceMainForm', component: StudentFinanceMainForm, canActivate: [AuthGuard] },
            { path: 'AudExamSchedule', component: AudExamSchedule, canActivate: [AuthGuard] },
            { path: 'quiz', component: Quiz, canActivate: [AuthGuard] },
            { path: 'login', component: LoginComponent },
        ], { relativeLinkResolution: 'legacy' })
    ],
    providers: [ShearedDataService, CardService, CorrespondenceService, SharedService,
        ProfileService, FinanceService, LanguageService, RegistrationService,
        CourseService, StudyProgramService, InformationBoardService,
        CookieService, GraideService, StudyMaterialService, UserService, QuizService, Quiz,
        { provide: HTTP_INTERCEPTORS, useClass: AddWithCredentialsInterceptorService, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        // provider used to create fake backend
        fakeBackendProvider, DayService,
        WeekService,
        WorkWeekService,
        MonthService,
        AgendaService,
        MonthAgendaService
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
