import { Location } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Caption, PortalLanguageCaption } from '../../../LanguageModel/Caption';
import { ClassRoomsListForTree } from '../../../Models/ClassRooms';
import { CourseLecture } from '../../../Models/Course';
import { CourseCroupTypesWithGroupsData, CourseSelectData, LecturesForSelect } from '../../../Models/CourseSelect';
import { PostSelectedGroups } from '../../../Models/PostParameters';
import { StudentTimeTableOnLecture } from '../../../Models/StudentProfile';
import { CourseService } from '../../Service/CourseService';
import { RegistrationService } from '../../Service/RegistrationService';
import { SharedService } from '../../Service/SharedService';



@Component({
  selector: 'CurrentSemesterSelectSubject',
  templateUrl: 'CurrentSemesterSelectSubject.html',
  styleUrls: ['CurrentSemesterSelectSubject.css'],
  changeDetection: ChangeDetectionStrategy.OnPush 
  // providers: [ChecklistDatabase]
})



export class CurrentSemesterSelectSubject {

  lectureGroupList: Array<LecturesForSelect>;
  courseCroupTypesWithGroupsDataList: Array<CourseCroupTypesWithGroupsData>;
  selectedCroups: Array<LecturesForSelect>;
  subGroupingEnabled: boolean;
  //public syllabusData: SyllabusData;
  public studentData: CourseSelectData;
  public courseData: CourseLecture;
  audList = new Array<ClassRoomsListForTree>();
  public LectureTypes: any;
  public captions: Caption;
  public studTimeTable: Array<StudentTimeTableOnLecture>;


  constructor(public dialog: MatDialog,
    public dialogRef: MatDialogRef<CurrentSemesterSelectSubject>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private activatedRoute: ActivatedRoute,
    private SharedService: SharedService,
    private route: ActivatedRoute,
    private location: Location,
    private StudListService: RegistrationService,
    private router: Router, private fb: UntypedFormBuilder,
    //private SyllabusService: SyllabusService,
    private CourseService: CourseService,
    private cd: ChangeDetectorRef) {
    this.captions = PortalLanguageCaption[parseInt(localStorage.getItem("language")) - 1];
    //this.SharedService.GetClassRooms().then(e => {
    //  this.audList = e
    //});

    this.StudListService.GetSelectedCourseLectureWithAllData({
      courseId: data.courseId, studId: -1, typeId: data.operation, enrollId: data.enrollId
    }).then(e => {
      this.audList = e.audList;
      this.studentData = e.courseSelectData;
      this.lectureGroupList = e.lecturesForSelect;
      this.LectureTypes = e.lectureTypes;
      this.studTimeTable = e.studTimeTable;
      this.subGroupingEnabled = this.studentData.subGroupingEnabled;
      this.LoadLectureGroups();
    });
   
  }

  LoadLectureGroups(): void {
  
    this.courseCroupTypesWithGroupsDataList = new Array<CourseCroupTypesWithGroupsData>();


    for (let k = 0; k < this.LectureTypes.length; k++) {
      this.courseCroupTypesWithGroupsDataList.push({ groupTypeId: this.LectureTypes[k].id, groupTypeCaption: this.LectureTypes[k].caption, groupDataList: null });
    }

    for (let i = 0; i < this.courseCroupTypesWithGroupsDataList.length; i++) {
      this.courseCroupTypesWithGroupsDataList[i].groupDataList = new Array<LecturesForSelect>();
      for (let k = 0; k < this.lectureGroupList.length; k++) {
        if (this.courseCroupTypesWithGroupsDataList[i].groupTypeId == this.lectureGroupList[k].lectureTypeID) {

          if (this.lectureGroupList[k].lectureQuotes.length > 0) {
            let passesOk = false;
            for (let qv = 0; qv < this.lectureGroupList[k].lectureQuotes.length; qv++) {
              let lqfs = this.lectureGroupList[k].lectureQuotes[qv];

              if ((lqfs.facultyId == null || lqfs.facultyId == this.studentData.facultyId) &&
                (lqfs.studyLevelId == null || lqfs.studyLevelId == this.studentData.studyLevelId)) {
                if ((lqfs.maxQuantities - lqfs.studQuantity) > 0) {

                  passesOk = true;
                  break;
                }
              }

            }
            this.lectureGroupList[k].disabled = !passesOk;

          }
          else {
            this.lectureGroupList[k].disabled = true;
          }
          if (this.data.operation == 2) {

            for (let sc = 0; sc < this.studentData.lectureGroups.length; sc++) {
              if (this.studentData.lectureGroups[sc] == this.lectureGroupList[k].id) {
                this.lectureGroupList[k].disabled = false;
                this.lectureGroupList[k].selected = true;

                if (this.subGroupingEnabled == true) {

                  for (let tt = 0; tt < this.LectureTypes.length; tt++) {
                    if (this.lectureGroupList[k].lectureTypeID < this.LectureTypes[tt].id) {
                      this.SelectGroup(this.lectureGroupList[k], this.lectureGroupList, true);
                      break;
                    }
                  }


                  //this.SelectGroup(this.lectureGroupList[k], this.lectureGroupList, true);
                }
              }
            }
          }
          this.courseCroupTypesWithGroupsDataList[i].groupDataList.push(this.lectureGroupList[k]);

        }
      }
    }

    this.cd.detectChanges();
  }

  

  OnInit(): void {
   
  }


  ClassRoomRootShow(classRoomId: number): string {

    let parentId = 0;
    let parentRoom = "";
    for (let i = 0; i < this.audList.length; i++) {
      if (this.audList[i].id == classRoomId) {
        parentId = this.audList[i].parentid;
        parentRoom = this.audList[i].text;
        break;
      }
    }
    let v = new Array<string>();
    this.ClassRoomParentShow(parentId, v);
    let retS = "";
    for (let i = (v.length - 1); i >= 0; i--) {
      retS += v[i] + "/";
    }

    retS += parentRoom;
    return retS;
  }
  ClassRoomParentShow(classRoomId: number, v: Array<string>): void {

    for (let i = 0; i < this.audList.length; i++) {
      if (this.audList[i].id == classRoomId) {
        v.push(this.audList[i].text);
        this.ClassRoomParentShow(this.audList[i].parentid, v);
        break;
      }
    }
  }

  SelectGroup(row: LecturesForSelect, arrayRows: Array<LecturesForSelect>, checked: boolean): void {
    for (let i = 0; i < arrayRows.length; i++) {
      arrayRows[i].selected = false;
    }

    row.selected = checked;
    if (this.subGroupingEnabled == true) {

      if (checked) {
        this.StudListService.GetGroupsOnCourseForStudentSelect(row.id).then(e => {
          for (let i = 0; i < this.courseCroupTypesWithGroupsDataList.length; i++) {

            if (this.courseCroupTypesWithGroupsDataList[i].groupTypeId > row.lectureTypeID) {

              this.courseCroupTypesWithGroupsDataList[i].groupDataList = new Array<LecturesForSelect>();
            }

          }
          let groups = e as Array<LecturesForSelect>;
          if (groups.length > 0) {
            for (let i = 0; i < this.courseCroupTypesWithGroupsDataList.length; i++) {
              for (let k = 0; k < groups.length; k++) {
                if (this.courseCroupTypesWithGroupsDataList[i].groupTypeId == groups[k].lectureTypeID) {

                  if (groups[k].lectureQuotes.length > 0) {
                    let passesOk = false;
                    for (let qv = 0; qv < groups[k].lectureQuotes.length; qv++) {
                      let lqfs = groups[k].lectureQuotes[qv];
                      //  console.log(lqfs);
                      //console.log(this.studentData);
                      if ((lqfs.facultyId == null || lqfs.facultyId == this.studentData.facultyId) &&
                        (lqfs.studyLevelId == null || lqfs.studyLevelId == this.studentData.studyLevelId)) {
                        if ((lqfs.maxQuantities - lqfs.studQuantity) > 0) {

                          passesOk = true;
                          break;
                        }
                      }

                    }
                    groups[k].disabled = !passesOk;
                  }
                  else {
                    groups[k].disabled = true;
                  }
                  if (this.data.operation == 2) {
                    for (let sc = 0; sc < this.studentData.lectureGroups.length; sc++) {
                      if (this.studentData.lectureGroups[sc] == groups[k].id) {
                        groups[k].disabled = false;
                        groups[k].selected = true;
                        if (this.subGroupingEnabled == true) {
                          for (let tt = 0; tt < this.LectureTypes.length; tt++) {
                            if (groups[k].lectureTypeID < this.LectureTypes[tt].id) {
                              this.SelectGroup(groups[k], groups, true);
                              break;
                            }
                          }
                        }
                      }
                    }
                  }

                  this.courseCroupTypesWithGroupsDataList[i].groupDataList.push(groups[k]);
                }
              }
            }
          }

         
          this.cd.detectChanges();
        });
      }

    }
  }

  isSaving: boolean = false;

  SaveSelectedGroups(): void {

    if (this.isSaving) return;


    this.isSaving = true;

    let selectedGroupTuSend = new Array<number>();
    for (let i = 0; i < this.courseCroupTypesWithGroupsDataList.length; i++) {
      for (let k = 0; k < this.courseCroupTypesWithGroupsDataList[i].groupDataList.length; k++) {
        if (this.courseCroupTypesWithGroupsDataList[i].groupDataList[k].selected) {
          selectedGroupTuSend.push(this.courseCroupTypesWithGroupsDataList[i].groupDataList[k].id);
          break;
        }
      }
    }

    if (selectedGroupTuSend.length == this.LectureTypes.length) {

      let ps = new PostSelectedGroups();
      ps.groups = selectedGroupTuSend;
      ps.syllabusId = this.studentData.syllabusId;
      ps.studentId = this.data.studId;
      ps.enrollId = this.data.enrollId;
      ps.operation = this.data.operation;
      this.StudListService.SaveStudentSelectedCourse(ps).then(e => {
        if (e.seccuss) {
          this.dialogRef.close({ value: 1 });
        } else { this.dialogRef.close({ value: 2 }); }

        this.isSaving = false;
      }).catch(e => {
        this.isSaving = false;

      });
      
    }
    else {
      alert("არჩეული ჯგუფების რაოდენობა არ ემთხვევა სილაბუსსზე არსებულ სალექციო ტიპების რაოდენობა.")
    }

    
  }

  DeleteEnrollSubject(enrollId: number) {
    
  }

  CloseWindows(): void {

  }

}
