<div class="_cs_flex _cs_size-w-percent--100">
  <div class="_cs_size-w-percent--100 _cs_p-left--lg _cs_p-right--lg _cs_p-down--lg">
    <div class="_cs_size-h-percent--100 sidebarbg _cs_flex">
      <!-- <div class="sidebarbg__sidebar _cs_border-radius--sm _cs_color-bg--primary-5 _cs_shadow-primary-0-0--1 _cs_m-right--md">

      </div> -->
      <div
        class="sidebarbg__wrapper _cs_size-w-percent--100 _cs_flex _cs_flex-d--column _cs_p-left--md _cs_mat-tab _cs_p-left--none-i">
        <div class="_cs_m-down--md _cs_m-top--xxl">
          <div
            class="_cs_p-xs _cs_shadow-primary-0-0--1 _cs_border-radius--sm _cs_border-down--primary-6 _cs_flex _cs_flex-a--center">
            <span
              class="_cs_icon _cs_icon-xl _cs_shadow-primary-6-0--2 _cs_border-radius--full _cs_border--primary-6 _cs_m-right--md">
              <span class="_cs_icon _cs_icon-md _cs_color-primary-6 _cs_icon-financial-data"></span>

            </span>
            <span class="_cs_label _cs_label-sm _cs_label-font-setting-case-on _cs_label-bold
                _cs_color-primary-6">{{captions.financialData}}</span>
          </div>
        </div>
        <app-FinanceMainForm>

        </app-FinanceMainForm>
      </div>
    </div>
  </div>
</div>
