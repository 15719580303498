import { Component,  Input,  ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
;
import { of as ofObservable, Observable} from 'rxjs';

import { SharedService } from '../../Student/Service/SharedService';



@Component({
  selector: 'ProfileImage',
  templateUrl: 'ProfileImage.html',
  styleUrls: ['ProfileImage.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProfileImage {

  @Input() fileId: Observable<string>;
  @Input() width: string = "150px";
  @Input() height: string = "200px";
  @Input() imageClass: string = null;

  constructor(
    private cd: ChangeDetectorRef,
    private SharedService: SharedService,
    private route: ActivatedRoute,
    private location: Location,
    private router: Router) {


  }
  ngOnInit() {
    
  }

  ngAfterContentInit(): void {

    this.fileId.subscribe(vl => {
      if (vl != null && vl.length > 3) {
        this.getImageFromService(vl);
      }

      this.cd.markForCheck();
    });

  }


  imageToShow: any;

  createImageFromBlob(image: Blob) {
    let reader = new FileReader();
    reader.addEventListener("load", () => {
      this.imageToShow = reader.result;
    }, false);

    if (image) {
      reader.readAsDataURL(image);
    }
  }


  isImageLoading: boolean=true;

  getImageFromService(fileId:any) {
    this.isImageLoading = true;
    

      this.SharedService.getImage(fileId).subscribe(data => {
        this.createImageFromBlob(data);
        this.isImageLoading = false;
      }, error => {
        this.isImageLoading = false;
        console.log(error);
      });
    
  }


}


