<div
  class="_cs_flex _cs_flex-a--center _cs_color-bg-gradient--primary-3 _cs_position-absolute _cs_position-left-px--0 _cs_position-up-px--0 _cs_size-w-percent--100 _cs_p-top--sm _cs_p-down--sm _cs_p-left--md _cs_p-right--md">
  <span class="_cs_label _cs_label-lg _cs_label-font-setting-case-on _cs_label-500 _cs_color-primary-5">{{captions.studyMaterial}}: {{data.subjectName}}</span>
  <button
    class="_cs_m-left--auto _cs_icon _cs_icon-lg _cs_border-radius--full _cs_shadow-primary-0-0--3 _cs_color-bg--primary-5"
    [mat-dialog-close]="true">
    <span class="_cs_icon _cs_icon-xs _cs_icon-cross _cs_color-primary-2"></span>
  </button>
</div>
<mat-dialog-content>

  <div class="_cs_size-w-percent--100 _cs_p-left--sm _cs_p-right--sm">
    <!--<mat-form-field class="_cs_p-md _cs_shadow-primary-0-0--1 _cs_border-radius--xxl _cs_size-w-percent--100 _cs_color-bg--primary-5 _cs_m-down--md _cs_p-down--none">
      <mat-select placeholder={{captions.lectureType}} (selectionChange)="getGroupsOnSelected($event.value)">
        <mat-option [value]="examEvalution">{{examEvalution.lectureCaption}}</mat-option>
        <mat-option *ngFor="let ls of lecutreTypeList" [value]="ls">{{ls.lectureCaption}}</mat-option>
      </mat-select>
    </mat-form-field>-->

    <section class="example-section">
      <mat-radio-group [(ngModel)]="labelPosition" (change)="getGroupsOnSelected($event.value)" class="Radio-margin">
        <mat-radio-button [value]="examEvalution">{{examEvalution.lectureCaption}}</mat-radio-button>
        <mat-radio-button *ngFor="let ls of lecutreTypeList" [value]="ls">{{ls.lectureCaption}}</mat-radio-button>
      </mat-radio-group>
    </section>
    <!--<span class="_cs_label _cs_label-sm _cs_label-font-setting-case-on">აირჩიეთ სალექციო ტიპი</span>
  <button *ngFor="let ls of lecutreTypeList" class="_cs_button _cs_button-border--positive _cs_button-xs _cs_m-dow--none"
          (click)="ShowCourseGroupsForSelect(ls)">
    <span class="_cs_label _cs_label-sm _cs_label-font-setting-case-on">{{ls.lectureCaption}}</span>
  </button>-->
  </div>
  <div class="_cs_size-w-percent--100 _cs_p-left--sm _cs_p-right--sm">
    <table class="table table-bordered">
      <thead>
        <tr>
          <th>#</th>
          <th>{{captions.weekDay}}</th>
          <th>{{captions.date}}</th>
          <th>{{captions.downloadDocument}}</th>
          <th>{{captions.description}}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let s of materialList; let k=index">
          <td>{{(k+1)}}</td>
          <td>
            {{s.weekName}}
          </td>
          <td>
            {{s.forDayDate}}
          </td>
          <td>
           <button class="_cs_button _cs_button-border--positive _cs_button-xs"
             (click)="DownloadDocument(s.fileStreamId)">
             <i class="_cs_m-right--sm" class="material-icons _cs_color-primary-6">
               keyboard_arrow_down
             </i>
             <span class="_cs_label _cs_label-xs _cs_color-primary-6">{{s.fileName}}</span>
           </button>
          </td>
          <td>
            <input matInput type="text" [(ngModel)]="s.description" />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</mat-dialog-content>
