
<mat-tab-group *ngIf="studentPaymentResult||StudentsGrants">
  <mat-tab>
    <ng-template mat-tab-label>
      <!-- <span class="_cs_m-right--md _cs_color-primary-5 _cs_icon _cs_icon-xl _cs_border-radius--full  _cs_label--sm _cs_shadow-primary-0-0--1">
          <span class="_cs_icon _cs_icon-md _cs_color-primary-6 _cs_icon-scholarship"></span>
  </span> -->
      {{captions.bills}}
    </ng-template>
    <table class="table table-bordered table-hover" *ngIf="studentPaymentResult">
      <thead>
        <tr>
          <th>#</th>
          <th>{{captions.yearSemesterName}}</th>
          <th>{{captions.paymentType}}</th>
          <th>{{captions.status}}</th>
          <th>{{captions.payment}}</th>
          <th>{{captions.credit}}</th>
          <th>{{captions.creditCost}}</th>
          <th>{{captions.discount}}</th>
          <th>{{captions.finePercentage}}</th>
          <th>{{captions.sumPay}}</th>
          <th>{{captions.currency}}</th>
          <th>{{captions.grantMoney}}</th>
          <th>{{captions.remainingAmountToPaid}}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let v of studentPaymentResult.paymentList;let i=index" class='clickable-row' (click)="setPaymentClickedRow(i,v)" [class.active]="i == selectedPaymentRow">
          <td>{{i+1}}</td>
          <td>{{v.studyYear+'-'+v.studySemester}}</td>
          <td>{{v.paymentType}} </td>
          <td>{{v.paymentStatus}} </td>
          <td>{{v.payment| number : '1.2-2'}}</td>
          <td>{{v.credit}} </td>
          <td>{{v.creditPayment| number : '1.2-2'}}</td>
          <td>{{v.discount}}</td>
          <td>{{v.paymentPercentage}}</td>
          <td>{{v.sumPay}}</td>
          <td>{{v.currencyCaption}}</td>
          <td>{{v.finGranSum| number : '1.2-2'}}</td>
          <td>{{v.leftPayment| number : '1.2-2'}}</td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td colspan="13">
            <div style="text-align:right"> <span><b> {{captions.totalDebt}}:</b>{{studentPaymentResult.debt| number : '1.2-2'}}</span></div>
          </td>
        </tr>
      </tfoot>
    </table>
    <mat-tab-group *ngIf="StudentPays||StudentDebtDistributions">
      <mat-tab>
        <ng-template mat-tab-label>
          <!-- <span
    class="_cs_m-right--md _cs_color-primary-5 _cs_icon _cs_icon-xl _cs_border-radius--full  _cs_label--sm _cs_shadow-primary-0-0--1">
    <span class="_cs_icon _cs_icon-md _cs_color-primary-6 _cs_icon-wallet"></span>
  </span> -->
          {{captions.payments}}
        </ng-template>
        <table class="table table-bordered table-hover" *ngIf="StudentPays">
          <thead>

            <tr>
              <th>#</th>
              <th>{{captions.operation}}</th>
              <th>{{captions.payer}}</th>
              <th>{{captions.date}}</th>
              <th>{{captions.amount}}</th>
              <th>{{captions.currency}}</th>
              <th>{{captions.inMajorCurrency}}</th>
              <th>{{captions.ratio}}</th>
              <th>{{captions.course}}</th>
              <th>{{captions.payerIdentificator}}</th>

            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let v of StudentPays;let i=index">
              <td>{{i+1}}</td>
              <td>

                {{v.payDirectionCaption}} &nbsp;

              </td>
              <td>

                {{v.payerTypeCaption}} &nbsp;
              </td>
              <td>

                {{v.payDateTime| date:'yyyy-MM-dd'}} &nbsp;
              </td>
              <td>

                {{v.payMoney}} &nbsp;
              </td>
              <td>

                {{v.currencyCaption}} &nbsp;
              </td>
              <td>
                {{v.inMainCurrency}}
              </td>
              <td>
                {{v.currencyRateCaption}}
              </td>
              <td>
                {{v.rate}}
              </td>
              <td>

                {{v.payerIdentificationDatum}} &nbsp;
              </td>

            </tr>
          </tbody>
          
        </table>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <!-- <span
    class="_cs_m-right--md _cs_color-primary-5 _cs_icon _cs_icon-xl _cs_border-radius--full  _cs_label--sm _cs_shadow-primary-0-0--1">
    <span class="_cs_icon _cs_icon-md _cs_color-primary-6 _cs_icon-time-and-date"></span>
  </span>  -->
          {{captions.graph}}
        </ng-template>
        <table class="table table-bordered table-hover" *ngIf="studentFinDebtDistributionResult">
          <thead>

            <tr>
              <th>#</th>
              <th>{{captions.payment}}</th>
              <th>{{captions.term}}</th>
              <th>{{captions.status}}</th>
              <th>{{captions.paid}}</th>
              <th>{{captions.paidInTheTime}}</th>
              <th>{{captions.theRemainingTax}}</th>
              <th>{{captions.accruedFine}}</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let v of studentFinDebtDistributionResult.studentFinDebtDistributionList;let i=index">
              <td>{{i+1}}</td>
              <td>

                {{v.payMoney}} &nbsp;
              </td>
              <td>

                {{v.endDate| date:'yyyy-MM-dd'}} &nbsp;
              </td>
              <td>

                {{v.statusCaption}} &nbsp;
              </td>
              <td>
                {{v.sumPay}}
              </td>
              <td>
                {{v.sumPayInDebtDistribution}}
              </td>
              <td>
                {{v.leftMoneyToPay}}
              </td>
              <td>
                {{v.fine}}
              </td>
              
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td colspan="7">
                <div style="text-align:right">
                  <b>{{captions.totalRemainingDebt}}:</b>{{studentFinDebtDistributionResult.leftPaydMony}} , 
                <b>{{captions.totalFine}}:</b>{{studentFinDebtDistributionResult.sumFine}}
                </div>
              </td>
            </tr>
          </tfoot>
        </table>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <!-- <span
    class="_cs_m-right--md _cs_color-primary-5 _cs_icon _cs_icon-xl _cs_border-radius--full  _cs_label--sm _cs_shadow-primary-0-0--1">
    <span class="_cs_icon _cs_icon-md _cs_color-primary-6 _cs_icon-grant-status"></span>
  </span> -->
          {{captions.accruedGrants}}
        </ng-template>
        <table class="table table-bordered table-hover" *ngIf="StudentSessionGrantLists">
          <thead>
           
            <tr>
              <th>#</th>
              <th>{{captions.numberN}}</th>
              <th>{{captions.subjectType}}</th>
              <th>{{captions.date}}</th>
              <th>{{captions.amountToBeAccrual}}</th>
             
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let v of StudentSessionGrantLists;let i=index">
              <td>{{i+1}}</td>
              <td>
                
                  {{v.grantNumber}} &nbsp;
              </td>
              <td>{{v.granTypeCaption}}</td>
              <td>{{v.grantDate| date:'yyyy-MM-dd'}}</td>
              <td>{{v.sessionTransaction}}</td>
            </tr>
          </tbody>
        </table>
      </mat-tab>
    </mat-tab-group>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <!-- <span
    class="_cs_m-right--md _cs_color-primary-5 _cs_icon _cs_icon-xl _cs_border-radius--full  _cs_label--sm _cs_shadow-primary-0-0--1">
    <span class="_cs_icon _cs_icon-md _cs_color-primary-6 _cs_icon-tax"></span>
  </span> -->
        {{captions.grant}}
      </ng-template>
      <table class="table table-bordered table-hover" *ngIf="StudentsGrants">
        <thead>
         
          <tr>
            <th>#</th>
            <th>{{captions.numberN}}</th>
            <th>{{captions.date}}</th>
            <th>{{captions.description}}</th>
            <th>{{captions.subjectType}}</th>
            <th>{{captions.grantDuration}}</th>
            <th>{{captions.theRemainingGrantForAccrual}}</th>
            <th>{{captions.amountToBeAccrual}}</th>
            <th>{{captions.currency}}</th>

          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let v of StudentsGrants;let i=index">
            <td>{{i+1}}</td>
            <td>
              
                {{v.grantNumber}} &nbsp;
            </td>
            <td>{{v.grantDate| date:'yyyy-MM-dd'}}</td>
            <td>{{v.grantDescription}}</td>
            <td>{{v.grantTypeCaption}}</td>
            <td>
              
                {{v.sessionDuration}} &nbsp;

            </td>
            <td>{{v.sessionDurationRemaining}}</td>
            <td>
              
                {{v.sessionTransaction}} &nbsp;

            </td>
            <td>{{v.currencyCaption}}</td>
            
          </tr>
        </tbody>
      </table>
    </mat-tab>
</mat-tab-group>

