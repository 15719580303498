import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
//import { map } from 'rxjs/operators';
import { map, tap } from 'rxjs/operators';
//import 'rxjs/add/operator/toPromise';
import { Observable } from 'rxjs';
import { QuizParameters } from '../Models/QuizRetResult';




@Injectable()
export class QuizService {

  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  private headers2 = new HttpHeaders({ 'Content-Type': 'multipart/form-data' });
  private heroesUrl = '/';

  constructor(private http: HttpClient) { }



  QuizList(): Promise<any> {
    // var pr = { Id: Id };
    return this.http.post("/api/Quiz/QuizList", null, { headers: this.headers })
      .toPromise()
      .then(response => response)
      .catch(this.handleError);
  }

  Questions(data: QuizParameters): Promise<any> {

    return this.http.post("/api/Quiz/Questions", data, { headers: this.headers })
      .toPromise()
      .then(response => response)
      .catch(this.handleError);
  }

  InsertQuizResult(data: any): Promise<any> {
    //var pr = { Id: Id };
    return this.http.post("/api/Quiz/InsertQuizResult", data, { headers: this.headers })
      .toPromise()
      .then(response => response)
      .catch(this.handleError);
  }



  private handleError(error: any): Promise<any> {
    console.error('An error occurred', error); // for demo purposes only
    return Promise.reject(error.message || error);
  }
}
