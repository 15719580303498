export class Caption {
  public userName: string;
  public password: string;
  public subjectName: string;
  public subjectType: string;
  public card: string;
  public credit: string;
  public yearName: string;
  public yearSemesterName: string;
  public semName: string;
  public mainPage: string;
  public profile: string;
  public acadRegistration: string;
  public deleteSelectedSubject: string;
  public studyProgram: string;
  public correspondece: string;
  public informationBoard: string;
  public studentFinanceMainForm: string;
  public creditVolume: string;
  public doneCredits: string;
  public done: string;
  public isNotDone: string;
  public status: string;
  public caption: string;
  public recomendedSemester: string;
  public structType: string;
  public schedule: string;
  public creditForDone: string;
  public leftCredits: string;
  public timeTable: string;
  public changePassword: string;
  public switchProfile: string;
  public logOut: string;
  public semesterReport: string;
  public news: string;
  public title: string;
  public date: string;
  public readMore: string;
  public announcements: string;
  public trainingsConferences: string;
  public guide: string;
  public fileSelect: string;
  public upload: string;
  public currentPassword: string;
  public newPassword: string;
  public repeatPassword: string;
  public change: string;
  public close: string;
  public customerExchange: string;
  public documents: string;
  public arithmeticAverage: string;
  public lecturers: string;
  public subjectStatus: string;
  public semester: string;
  public mark: string;
  public gradeSimbole: string;
  public complete: string;
  public failed: string;
  public notDefined: string;
  public detailedEvaluation: string;
  public createNewMessage: string;
  public givingGroupResponse: string;
  public read: string;
  public sender: string;
  public content: string;
  public faculty: string;
  public position: string;
  public importanceLevel: string;
  public datetime: string;
  public incoming: string;
  public outgoing: string;
  public createMessage: string;
  public readMessageAuthor: string;
  public answer: string;
  public messageType: string;
  public answer2: string;
  public file: string;
  public delete: string;
  public send: string;
  public financialData: string;
  public bills: string;
  public paymentType: string;
  public payment: string;
  public creditCost: string;
  public discount: string;
  public finePercentage: string;
  public sumPay: string;
  public currency: string;
  public grantMoney: string;
  public remainingAmountToPaid: string;
  public totalDebt: string;
  public payments: string;
  public operation: string;
  public payer: string;
  public amount: string;
  public inMajorCurrency: string;
  public ratio: string;
  public course: string;
  public payerIdentificator: string;
  public graph: string;
  public term: string;
  public paid: string;
  public paidInTheTime: string;
  public theRemainingTax: string;
  public accruedFine: string;
  public totalRemainingDebt: string;
  public totalFine: string;
  public accruedGrants: string;
  public numberN: string;
  public amountToBeAccrual: string;
  public grant: string;
  public description: string;
  public grantDuration: string;
  public theRemainingGrantForAccrual: string;
  public chooseSpecialization: string;
  public curriculumDescription: string;
  public academicRegistrationProcessIsClosed: string;
  public allowedAcademicRegistration: string;
  public allocatedCreditsInSemester: string;
  public abandonedCreditsForChoose: string;
  public selectFaculty: string;
  public selectStudyProgram: string;
  public courseName: string;
  public comment: string;
  public syllabus: string;
  public selectCourse: string;
  public select: string;
  public studyMaterial: string;
  public electiveTrainingCourses: string;
  public selectedStudyCourse: string;
  public add: string;
  public updated: string;
  public successfullyDeleted: string;
  public anErrorOccurred: string;
  public subjectSelect: string;
  public lectureType: string;
  public group: string;
  public totalPlace: string;
  public selected: string;
  public allocatedPlace: string;
  public theRemaining: string;
  public fName: string;
  public sName: string;
  public pName: string;
  public persNumber: string;
  public birthDay: string;
  public sex: string;
  public studyLevel: string;
  public specialization: string;
  public enterYear: string;
  public naecCode: string;
  public anketa: string;
  public contactInformation: string;
  public educationActivities: string;
  public education: string;
  public conferences: string;
  public publications: string;
  public professionalDevelopment: string;
  public practicalExperience: string;
  public projects: string;
  public transferSkills: string;
  public orders: string;
  public executedCreditsBySemester: string;
  public GPAsemester: string;


  public secondaryArithmeticSemester: string;
  public resetPassword: string;
  public rememberMe: string;
  public login: string;
  public pleaseLogin: string;
  public email: string;
  public sendCode: string;
  public confirmationCode: string;
  public language: string;
  public category: string;
  public contactType: string;
  public quality: string;
  public assignmentDate: string;
  public diplomaNumber: string;
  public releaseDate: string;
  public topic: string;
  public field: string;
  public qualification: string;
  public grantingFacility: string;
  public documentCategory: string;
  public documentName: string;
  public exam: string;
  public weekDay: string;
  public downloadDocument: string;
  public documentType: string;
  public document: string;

  public conferenceName: string;
  public place: string;
  public role: string;
  public topicReport: string;
  public thetitleOfThePaper: string;
  public publishingHouse: string;
  public nameOfThePublication: string;
  public coAuthor: string;
  public activity: string;
  public institution: string;
  public fromTheDate: string;
  public beforeTheDate: string;
  public organization: string;
  public positionP: string;
  public function: string;
  public nameOfProjects: string;
  public briefDescriptionOfTheProjects: string;
  public placeOfImplementation: string;
  public level: string;
  public types: string;
  public dataType: string;
  public data: string;
  public quiz: string;
  public detailEvalution: string;
  public LearnScheduler: string;
  public ExmaScheduler: string;
  public subjectForExamCorrespondece: string;
  public open: string;
  public forFill: string;
  public filled: string;
  public pleasFill: string;
  public saveResult: string;
  public messageSendSeccuss: string;
  public accessFailedOnSubject: string;
}




export class CaptionGE implements Caption  {
  public userName: string = "მომხმარებელი";
  public password: string = "პაროლი";
  public subjectName: string = "სასწავლო კურსი";
  public subjectType: string = "ტიპი";
  public card: string = "სასწავლო ბარათი";
  public credit: string = "კრედიტი";
  public yearName: string = "სასწავლო წელი";
  public yearSemesterName: string = "სასწავლო წელი/სემესტრი";
  public semName: string = "სასწავლო სემესტრი";
  public mainPage: string = "მთავარი";
  public profile: string = "პროფილი";
  public acadRegistration: string = "საგნების არჩევა";
  public deleteSelectedSubject: string = "გინდათ წაშალოთ არჩეული სასწავლო კურსი?";
  public studyProgram: string = "სასწავლო პროგრამა";
  public correspondece: string = "შეტყობინებები";
  public informationBoard: string = "საინფორმაციო დაფა";
  public studentFinanceMainForm: string = "ფინანსები";
  public creditVolume: string = "კრედიტების მოცულობა";
  public doneCredits: string = "შესრულებული კრედიტები";
  public done: string = "შესრულებული";
  public isNotDone: string = "არ არის შესრულებული";
  public status: string = "სტატუსი";
  public caption: string = "დასახელება";
  public recomendedSemester: string = "რეკომენდ. სემესტრი";
  public structType: string = "ტიპი";
  public schedule: string = "ცხრილი";
  public creditForDone: string = "შესასრულებელი ECTS";
  public leftCredits: string = "მისაღები ECTS";
  public timeTable: string = "ცხრილი";
  public changePassword: string = "პაროლის შეცვლა";
  public switchProfile: string = "პროფილის გადართვა";
  public logOut: string = "გამოსვლა";
  public semesterReport: string = "სემესტრული ანგარიში";
  public news: string = "სიახლეები";
  public title: string = "სათაური";
  public date: string = "თარიღი";
  public readMore: string = "ვრცლად";
  public announcements: string = "ანონსები";
  public trainingsConferences: string = "ტრენინგები/კონფერენციები";
  public guide: string = "გზამკვლევი";
  public fileSelect: string = "ფაილის არჩევა";
  public upload: string = "ატვირთვა";
  public currentPassword: string = "მიმდინარე პაროლი";
  public newPassword: string = "ახალი პაროლი";
  public repeatPassword: string = "გაიმეორეთ პაროლი";
  public change: string = "შეცვლა";
  public close: string = "დახურვა";
  public customerExchange: string = "მომხმარებლის გადაცვლა";
  public documents: string = "დოკუმენტები";
  public arithmeticAverage: string = "საშუალო არითმეტიკული";
  public lecturers: string = "პედაგოგ(ებ)ი";
  public subjectStatus: string = "საგნის სტატუსი";
  public semester: string = "სემესტრი";
  public mark: string = "ქულა";
  public gradeSimbole: string = "შეფასება";
  public complete: string = "ჩაბარებული";
  public failed: string = "ჩაჭრილი";
  public notDefined: string = "არ არის განსაზღვრული";
  public detailedEvaluation: string = "დეტალური შეფასება";
  public createNewMessage: string = "ახალი შეტყობინების შექმნა";
  public givingGroupResponse: string = "ჯგუფური პასუხის გაცემა";
  public read: string = 'წაკითხვა';
  public sender: string = "გამომგზავნი";
  public content: string = "შინაარსი";
  public faculty: string = "ფაკულტეტი";
  public position: string = "თან. პოზიცია";
  public importanceLevel: string = "მნიშვნ. დონე";
  public datetime: string = "თარიღი/დრო";
  public incoming: string = "შემოსული";
  public outgoing: string = "გასული";
  public createMessage: string = "შეტყობინების შექმნა";
  public readMessageAuthor: string = "წერილის წაკითხვა (წერილის ავტორი";
  public answer: string = "პასუხის გაცემა";
  public messageType: string = "შეტყობინების ტიპი";
  public answer2: string = "პასუხი";
  public file: string = "ფაილი";
  public delete: string = "წაშლა";
  public send: string = "გაგზავნა";
  public financialData: string = "ფინანსური მონაცემები";
  public bills: string = "გადასახადები";
  public paymentType: string = "გადახდის ტიპი";
  public payment: string = "გადასახადი";
  public creditCost: string = "კრედიტის ღირებულება";
  public discount: string = "ფასდაკლება";
  public finePercentage: string = "ჯარიმის პროცენტი";
  public sumPay: string = "სულ გადახდილი";
  public currency: string = "ვალუტა";
  public grantMoney: string = "გრანტ. თანხა";
  public remainingAmountToPaid: string = "დარჩ. გად. თანხა";
  public totalDebt: string = "ჯამური დავალიანება";
  public payments: string = "გადახდები";
  public operation: string = "ოპერაცია";
  public payer: string = "გადამხდელი";
  public amount: string = "თანხა";
  public inMajorCurrency: string = "მთავარ ვალუტაში";
  public ratio: string = "შეფარდება";
  public course: string = "კურსი";
  public payerIdentificator: string ="გად. იდენ.";
  public graph: string = "გრაფიკი";
  public term: string = "ვადა";
  public paid: string = "გადახდილი";
  public paidInTheTime: string = "ვადაში გადახდილი";
  public theRemainingTax: string = "დარჩენილი გადასახადი";
  public accruedFine: string = "დარიცხული ჯარიმა";
  public totalRemainingDebt: string = "სულ დარჩენილი ვალი";
  public totalFine: string = "სულ ჯარიმა";
  public accruedGrants: string = "დარიცხული გრანტები";
  public numberN: string = "ნომერი";
  public amountToBeAccrual: string = "დასარიცხი თანხა";
  public grant: string = "გრანტი";
  public description: string = "აღწერილობა";
  public grantDuration: string = "გრანტის ხანგრძლივობა";
  public theRemainingGrantForAccrual: string = "დარჩენილი ჩამოსარიცხი გრანტი";
  public chooseSpecialization: string = "აირჩიეთ სპეციალიზაცია";
  public curriculumDescription: string = "სასწავლო პროგრამის აღწერილობა";
  public academicRegistrationProcessIsClosed: string = "აკადემიური რეგისტრაციის პროცესი დახურულია!";
  public allowedAcademicRegistration: string = "დადგენილ ვადაში ადმინისტრაციული რეგისტრაციის გაუვლელობის გამო არ დაიშვებით აკადემიურ რეგისტრაციაზე!";
  public allocatedCreditsInSemester: string = "სულ სემესტრულად გამოყოფილი კრედიტები";
  public abandonedCreditsForChoose: string = "სულ ასარჩევად დარჩენილი კრედიტები";
  public selectFaculty: string = "აირჩიეთ ფაკულტეტი";
  public selectStudyProgram: string = "აირჩიეთ სასწავლო პროგრამა";
  public courseName: string = "კურსის დასახელება";
  public comment: string = "კომენტარი";
  public syllabus: string = "სილაბუსი";
  public selectCourse: string = "კურსის არჩევა";
  public select: string = "არჩევა";
  public studyMaterial: string = "სასწავლო მასალა";
  public electiveTrainingCourses: string = "ასარჩევი სასწავლო კურსები";
  public selectedStudyCourse: string = "არჩეული სასწავლო კურსები";
  public add: string = "დაემატა";
  public updated: string = "განახლდა";
  public successfullyDeleted: string = "წარმატებით წაიშალა";
  public anErrorOccurred: string = "მოხდა შეცდომა";
  public subjectSelect: string = "სასწავლო კურსის არჩევა";
  public lectureType: string = "სალექციო ტიპი";
  public group: string = "ჯგუფი";
  public totalPlace: string = "სულ ადგილი";
  public selected: string = "არჩეული";
  public allocatedPlace: string = "გამოყოფილი ადგილი";
  public theRemaining: string = "დარჩენილი";
  public fName: string ="სახელი";
  public sName: string ="გვარი";
  public pName: string = "მამის სახელი";
  public persNumber: string = "პირადი ნომერი";
  public birthDay: string = "დაბადების თარიღი";
  public sex: string = "სქესი";
  public studyLevel: string = "საფეხური";
  public specialization: string = "სპეციალიზაცია";
  public enterYear: string = "ჩარიცხვის წელი";
  public naecCode: string = "ნაეკის კოდი";
  public anketa: string = "ანკეტა";
  public contactInformation: string = "საკონტაქტო ინფორმაცია";
  public educationActivities: string = "განათლება/აქტივობები";
  public education: string = "განათლება";
  public conferences: string ="კონფერენციები";
  public publications: string ="პუბლიკაციები";
  public professionalDevelopment: string ="პროფესიული განვითარება";
  public practicalExperience: string ="პრაქტიკული გამოცდილება";
  public projects: string ="პროექტები";
  public transferSkills: string = "ტრანსფ. უნარები";
  public orders: string = "ბრძანებები";
  public executedCreditsBySemester: string ="შესრულებული კრედიტები სემესტრულად";
  public GPAsemester: string ="GPA სემესტრულად";
  public secondaryArithmeticSemester: string = "საშუალო არითმეტიკული სემესტრულად";

  public rememberMe: string = "დამიმახსოვრე";
  public resetPassword: string = "პაროლის აღდგენა";
  public login: string ="შესვლა";
  public pleaseLogin: string = "გთხოვთ, შეხვიდეთ თქვენს ანგარიშზე";
  public email: string = "ელ-ფოსტა";
  public sendCode: string ="კოდის გამოგზავნა";
  public confirmationCode: string = "დადასტურების კოდი";
  public language: string="ენა";
  public category: string = "კატეგორია";
  public contactType: string = "კონტაქტის ტიპი";
  public quality: string ="ხარისხი";
  public assignmentDate: string ="მინიჭების თარიღი";
  public diplomaNumber: string ="დიპლომის ნომერი";
  public releaseDate: string ="გაცემის თარიღი";
  public topic: string ="თემა";
  public field: string ="სფერო/დარგი";
  public qualification: string ="კვალიფიკაცია";
  public grantingFacility: string = "მიმნიჭებელი დაწესებულება";
  public documentCategory: string ="დოკუმენტის კატეგორია";
  public documentName: string = "დოკუმენტის დასახელება";
  public exam: string = "გამოცდა";
  public weekDay: string = "კვირის დღე";
  public downloadDocument: string = "დოკუმენტი ჩამოტვირთვა";
  public documentType: string = "დოკუმენტის ტიპი";
  public document: string = "დოკუმენტი";

  public conferenceName: string ="კონფერენციის სახელწოდება";
  public place: string = "ადგილი";
  public role: string = "როლი";
  public topicReport: string = "თემა/მოხსენება";
  public thetitleOfThePaper: string = "ნაშრომის სათაური";
  public publishingHouse: string = "გამომცემლობა";
  public nameOfThePublication: string = "გამოცემის დასახელება";
  public coAuthor: string = "თანაავტორი";
  public activity: string = "აქტივობა";
  public institution: string = "დაწესებულება";
  public fromTheDate: string = "თარიღიდან";
  public beforeTheDate: string = "თარიღამდე";
  public organization: string = "ორგანიზაცია";
  public positionP: string = "თანამდებობა";
  public function: string = "ფუნქცია";
  public nameOfProjects: string = "პროექტების სახელწოდება";
  public briefDescriptionOfTheProjects: string = "პროექტების მოკლე აღწერილობა";
  public placeOfImplementation: string = "განხორციელების ადგილი";
  public level: string = "დონე";
  public types: string = "ტიპები";
  public dataType: string ="მონაცემთა ტიპი";
  public data: string = "მონაცემი";
  public quiz: string = "კითხვარი";
  public detailEvalution: string = "დეტალური შეფასება";
  public LearnScheduler: string = "სასწავლო ცხრილი";
  public ExmaScheduler: string="საგამოცდო ცხრილი";
  public subjectForExamCorrespondece: string = "სასწავლო კურსი";
  public open: string = "გახსნა";
  public forFill: string="შესავსებია";
  public filled: string="შევსებულია";
  public pleasFill: string="შეავსეთ";
  public saveResult: string = "შედეგების შენახვა";
  public messageSendSeccuss: string = "შეტყობინება წარმატებით გაიგზავნა";
  public accessFailedOnSubject: string ="საგანზე დაშვებული არ ხართ!";

}

export class CaptionENG implements Caption {
  public userName: string = "User Name";
  public password: string = "Password";
  public subjectName: string = "Course";
  public subjectType: string = "Type";
  public card: string = "Student's card";
  public credit: string = "Credit";
  public yearName: string ="Academic year";
  public semName: string = "Semester";
  public yearSemesterName: string = "Academic year/Semester";
  public mainPage: string = "Home";
  public profile: string = "My Profile";
  public acadRegistration: string = "Course Registration";
  public deleteSelectedSubject: string = "Do you want to remove the selected course?";
  public studyProgram: string = "Study Program";
  public correspondece: string = "Messages";
  public informationBoard: string = "Deshboard";
  public studentFinanceMainForm: string = "Payments";
  public creditVolume: string = "Credit Volume";
  public doneCredits: string = "Awarded credits";
  public done: string = "Completed";
  public isNotDone: string = "Is not completed";
  public status: string = "status";
  public caption: string = "Caption";
  public recomendedSemester: string = "Recommended Semester";
  public structType: string = "Type";
  public schedule: string = "Calendar";
  public creditForDone: string = "Number of Credits";
  public leftCredits: string = "Credits to be Collected";
  public timeTable: string = "Calendar";
  public changePassword: string = "Change password";
  public switchProfile: string = "Switch profile";
  public logOut: string = "Logout";
  public semesterReport: string = "My Overall Progress";
  public news: string = "News Feed";
  public title: string = "Title";
  public date: string = "Date";
  public readMore: string = "Read more";
  public announcements: string = "Announcements";
  public trainingsConferences: string = "Upcoming Trainings/Conferences";
  public guide: string = "Guide";
  public fileSelect: string = "Choose File";
  public upload: string = "Upload";
  public currentPassword: string = "Current password";
  public newPassword: string = "New password";
  public repeatPassword: string = "Repeat password";
  public change: string = "Change";
  public close: string = "Close";
  public customerExchange: string = "Customer exchange";
  public documents: string = "Documents";
  public arithmeticAverage: string = "Arithmetic average";
  public lecturers: string = "Lecturer";
  public subjectStatus: string = "Subject status";
  public semester: string = "Semester";
  public mark: string = "Mark";
  public gradeSimbole: string = "Grade";
  public complete: string = "Passed";
  public failed: string = "Failed";
  public notDefined: string = "Not defined";
  public detailedEvaluation: string = "Grade Journal";
  public createNewMessage: string = "Create a Message";
  public givingGroupResponse: string = "Group Response";
  public read: string = 'Read';
  public sender: string = "Sender";
  public content: string = "Content";
  public faculty: string = "Faculty";
  public position: string = "Position";
  public importanceLevel: string = "Priority Levels";
  public datetime: string = "Date & Time";
  public incoming: string = "Inbox";
  public outgoing: string = "Outbox";
  public createMessage: string = "Create a Message";
  public readMessageAuthor: string = "Read the letter (author of the letter";
  public answer: string = "Answer";
  public messageType: string = "Message type";
  public answer2: string = "Answer";
  public file: string = "File";
  public delete: string = "Delete";
  public send: string = "Send";
  public financialData: string = "Financial data";
  public bills: string = "Fees";
  public paymentType: string = "Payment type";
  public payment: string = "Payment";
  public creditCost: string = "ECTS Cost";
  public discount: string = "Discount";
  public finePercentage: string = "Penalty";
  public sumPay: string = "Total Paid";
  public currency: string = "Currency";
  public grantMoney: string = "Grant Ammount";
  public remainingAmountToPaid: string = "Remaining amount to be paid";
  public totalDebt: string = "Total debt";
  public payments: string = "Payments";
  public operation: string = "Operation";
  public payer: string = "Payer";
  public amount: string = "Amount";
  public inMajorCurrency: string = "Main Currency";
  public ratio: string = "Ratio";
  public course: string = "Course";
  public payerIdentificator: string = "Payer Identi.";
  public graph: string = "Payment Individual Schedule";
  public term: string = "Term";
  public paid: string = "Paid";
  public paidInTheTime: string = "Paid on time";
  public theRemainingTax: string = "Remaining Amount";
  public accruedFine: string = "Accrued Penalty";
  public totalRemainingDebt: string = "Total remaining debt";
  public totalFine: string = "Total Penalty";
  public accruedGrants: string = "Accrued grants";
  public numberN: string = "number";
  public amountToBeAccrual: string = "Amount to be Accrued";
  public grant: string = "Grant";
  public description: string = "Description";
  public grantDuration: string = "Grant duration";
  public theRemainingGrantForAccrual: string = "Remaining Grant";
  public chooseSpecialization: string = "Choose a specialization";
  public curriculumDescription: string = "Curriculum description";
  public academicRegistrationProcessIsClosed: string = "The academic registration has finished!";
  public allowedAcademicRegistration: string = "You have missed the prescribed time limit for your administrative registration. For this reason, you are not permitted to go through the academic enrolment!";
  public allocatedCreditsInSemester: string = "allocated Credits in Semester";
  public abandonedCreditsForChoose: string = "Abandoned credits for choose";
  public selectFaculty: string = "Select faculty";
  public selectStudyProgram: string = "Select the study program";
  public courseName: string = "Course Name";
  public comment: string = "Comment";
  public syllabus: string = "Syllabus";
  public selectCourse: string = "Select course";
  public select: string = "Select";
  public studyMaterial: string = "Study material";
  public electiveTrainingCourses: string = "Study courses to be selected";
  public selectedStudyCourse: string = "Selected study courses";
  public add: string = "Add";
  public updated: string = "Updated";
  public successfullyDeleted: string = "Successfully deleted";
  public anErrorOccurred: string = "An error occurred";
  public subjectSelect: string = "Select study courses";
  public lectureType: string = "Lecture type";
  public group: string = "Group";
  public totalPlace: string = "Total Vacant places";
  public selected: string = "Selected";
  public allocatedPlace: string = "Allocated Vacant place";
  public theRemaining: string = "The remaining";
  public fName: string = "First name";
  public sName: string = "Last name";
  public pName: string = "Father name";
  public persNumber: string = "Personal number";
  public birthDay: string = "Date of birth";
  public sex: string = "Gender";
  public studyLevel: string = "Study level";
  public specialization: string = "Specialization";
  public enterYear: string = "Enrollment Year";
  public naecCode: string = "Naec code";
  public anketa: string = "Profile";
  public contactInformation: string = "contact information";
  public educationActivities: string = "Education / Activities";
  public education: string = "Education";
  public conferences: string = "Conferences";
  public publications: string = "Publications";
  public professionalDevelopment: string = "Professional Development";
  public practicalExperience: string = "Practical Experience";
  public projects: string = "Projects";
  public transferSkills: string = "Transfer skills";
  public orders: string = "Orders";
  public executedCreditsBySemester: string = "Executed credits by semester";
  public GPAsemester: string = "GPA semester";
  public secondaryArithmeticSemester: string = "Secondary arithmetic semester";

  public rememberMe: string = "Remember me";
  public resetPassword: string = "Reset password";
  public login: string = "Login";
  public pleaseLogin: string = "Please login to your account";
  public email: string = "e-mail";
  public sendCode: string = "Send code";
  public confirmationCode: string = "Confirmation code";
  public language: string = "Language";
  public category: string = "Category";
  public contactType: string = "Contact Type";
  public quality: string = "Degree";
  public assignmentDate: string = "Awarding Date";
  public diplomaNumber: string = "Diploma number";
  public releaseDate: string = "Date of Issue";
  public topic: string = "Thesis";
  public field: string = "Field";
  public qualification: string = "Qualification";
  public grantingFacility: string = "Granting facility";
  public documentCategory: string = "Document Category";
  public documentName: string = "Name of the document";
  public exam: string = "Exam";
  public weekDay: string = "Week Day";
  public downloadDocument: string = "Download the document";
  public documentType: string = "Document type";
  public document: string = "Document";

  public conferenceName: string = "Conference name";
  public place: string = "Place";
  public role: string = "Role";
  public topicReport: string = "Topic / report";
  public thetitleOfThePaper: string = "The title of the paper";
  public publishingHouse: string = "Publishing House";
  public nameOfThePublication: string = "Name of the publication";
  public coAuthor: string = "Co-author";
  public activity: string = "Activity";
  public institution: string = "Institution";
  public fromTheDate: string = "From the date";
  public beforeTheDate: string = "To the date";
  public organization: string = "Organization";
  public positionP: string = "Position";
  public function: string = "function";
  public nameOfProjects: string = "Name of projects";
  public briefDescriptionOfTheProjects: string = "A brief description of the projects";
  public placeOfImplementation: string = "Place of implementation";
  public level: string = "level";
  public types: string = "Types";
  public dataType: string = "Data type";
  public data: string = "Data";
  public quiz: string = "Quiz";
  public detailEvalution: string = "Detailed evaluation";
  public LearnScheduler: string = "Training Schedule";
  public ExmaScheduler: string = "Exam Schedule";
  public subjectForExamCorrespondece: string = "Study Course";
  public open: string = "Open";
  public forFill: string = "To be filled";
  public filled: string = "filled";
  public pleasFill: string = "fill";
  public saveResult: string = "Save Result";
  public messageSendSeccuss: string = "Message sent successfully";
  public accessFailedOnSubject: string = "You are not allowed on the subject!";

}

export const PortalLanguageCaption: Array<Caption> = [(new CaptionGE()), new CaptionENG()]
