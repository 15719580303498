export class CorrespondenceFilter {
  public faculty: number[];
  public studyLevelId: number[];
  public directionId: number[];
  public programId: number[];
  public subProgramId: string[];
  public studentStatusId: number[];
  public courseId: number[];
  public sName: string;
  public fName: string;
  public pn: string;
  public correspondenceTypeId: number;
  public correspondenceStatusId: number;
  public importent: boolean;
  public read: boolean;
  public correspondenceDirection: CorrespondenceDirection;
  public personStatus: PersonType;
  public take: number;
  public index: number;
}

export class CorrespondencStatement {
  public personId: number;
  public sName: string;
  public fName: string;
  public pn: string;
  public facultyCaption: string;
  public studyLevelCaption: string;
  public directionCaption: string;
  public staffStatus: string;
  public studentEducationStatus: string;
  public staffPosition: string;
  public coresspondenceDataId: number;
  public coresspondenceId: number;
  public stateId: number;
  public stateCaption: string;
  public importentStatusId: number;
  public importentStatusCaption: string;
  public fromPersonId: number;
  public correspondeceTypeId: number;
  public correspondeceTypeCaption: string;
  public correspondenceSubject: string;
  public correspondenceDescription: string;
  public createDate: Date;
  public asPersonalId: number;
  public asStudentId: number;
  public fromCourseId: number;
  public syllabusCaption: string;
  public readStatus: boolean;
}

export class CorrespondencAttachment
{

}

export class CorrespondenceData {
  public correspondeceTypeId: number;
  public correspondenceSubject: string;
  public correspondenceDescription: string;
  public importantId: number;
  public stateId: number;
  public fromCourseId: number;
  public courseName: string;
}
export class CorrespondenceDataWithAttachmentSend {
  public correspondenceData: CorrespondenceData;
  public correspondencAttachments: Array<CorrespondencAttachment>;
  public users: Array<CorrespondenceUsers>;
}
export class CorrespondenceUsers {
  asStudentId: number;
  asPersonalId: number;
  personeId: number;
  parentId: number;
}

export class CorrespondenceDataWithAttachmentGet {
  public correspondenceData: CorrespondencStatement;
  public correspondencAttachments: Array<CorrespondencAttachment>;
}
export enum CorrespondenceDirection {
  inComing = 1,
  outGoing = 2
}
export enum PersonType {
  student = 1,
  leturer = 2,
  adminStuff = 3
}

 export class CorrespondensCategoryGroup
    {
      count: number;
      correspondeceTypeId: number;
      caption: string;
    }
export class RetStatementList {
  statementData: Array<CorrespondencStatement>;
  count: number;
  categoryGroup: Array<CorrespondensCategoryGroup>;
};

export class CorrespondeceTypesData {
  public id: number;
  public caption: string;
  public senderStudent: boolean;
  public senderAdminUser: boolean;
  public senderLecturer: boolean;
  public receiveStudent: boolean;
  public receiveLecturer: boolean;
  public reseiveAdminUser: boolean;
  public studyCourseStudentSet: boolean;
  public studyCourseAdminSet: boolean;
}
