import { SelectionModel } from '@angular/cdk/collections';
import { FlatTreeControl } from '@angular/cdk/tree';
import { Location } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { ActivatedRoute, Router } from '@angular/router';
import { merge, of as observableOf } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Caption, PortalLanguageCaption } from '../../LanguageModel/Caption';
import { CorrespondenceDirection, CorrespondencStatement, CorrespondensCategoryGroup, PersonType, RetStatementList } from '../../Models/Correspondence';
import { UIfilter } from '../../Models/filters';
import { CreateViewLetterDocument } from './Dialogs/CreateViewLetterDocument';
import { CorrespondenceService } from './Service/CorrespondenceService';




@Component({
  selector: 'correspondenceMainForm',
  templateUrl: 'CorrespondenceMainForm.html',
  styleUrls: ['CorrespondenceMainForm.css'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class CorrespondenceMainForm {
  public statementList: RetStatementList;
  resultsLength = 0;
  categoryGroup: any;
  isLoadingResults = false;
  isRateLimitReached = false;
  selectedRow: any;
  selectedGroup: number;
  activeNode: any;
  flt: CorrespondenceDirection;
  @ViewChild(MatPaginator, { static:  true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  _filter: UIfilter;
 // @Output() onStudent = new EventEmitter<StudentsListData>();
  selection = new SelectionModel<CorrespondencStatement>(true, []);
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.entitiesDataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.entitiesDataSource.data.forEach(row => this.selection.select(row));
  }

  Filter(filter: UIfilter): void {
    this._filter = filter;
     //this.ShowStudents(filter);
  }

  displayedColumns: string[] = ['select', 'fromToPerson',
    //'pn',
    'facultyCaption', /*'studyLevelCaption', 'directionCaption',*/
    //'staffStatus', 'studentEducationStatus', 'staffPosition',
    'stateCaption', 'importentStatusCaption', 'correspondeceTypeCaption'
    ,'correspondenceSubject', 'correspondenceDescription', 'syllabusCaption','createDate'];
  entitiesDataSource: MatTableDataSource<CorrespondencStatement> = new MatTableDataSource();

  hasChild = (_: number, node: ExampleFlatNode) => node.expandable;
  private _transformer = (node: boardType, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.name,
      level: level,
      id: node.id,
      icon: node.icon,
      groupId: node.groupId,
      count: node.count,
      parentid:node.parentid
    };
  }

  treeControl = new FlatTreeControl<ExampleFlatNode>(
    node => node.level, node => node.expandable);

  treeFlattener = new MatTreeFlattener(
    this._transformer, node => node.level, node => node.expandable, node => node.children);

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
  public captions: Caption;
  public pIt: Array<boardType> = new Array<boardType>();

  constructor(public dialog: MatDialog,
    //private SharedService: SharedService,
    private route: ActivatedRoute,
    private location: Location,
    private router: Router, private cd: ChangeDetectorRef,
    private _snackBar: MatSnackBar,
    private CorrespondenceService: CorrespondenceService) {
    this.captions = PortalLanguageCaption[parseInt(localStorage.getItem("language")) - 1];

    
    this.pIt[0] = new boardType();
    this.pIt[1] = new boardType();
    //pIt[2] = new boardType();
    this.pIt[0].id = 1;
    this.pIt[0].name = this.captions.incoming;
    this.pIt[0].icon = "_cs_icon _cs_icon-list _cs_color-primary-6";
    //this.pIt[0].children = new Array<boardType>();
    this.pIt[1].id = 2;
    this.pIt[1].name = this.captions.outgoing;
    this.pIt[1].icon = "_cs_icon _cs_icon-list _cs_color-primary-6";
    //this.pIt[1].children = new Array<boardType>();
    //pIt[2].id = 3;
    //pIt[2].name = "განათლება/აქტივობები";
    //pIt[2].icon = "room";
    //pIt[2].children = new Array<boardType>();
    
    this.dataSource.data = this.pIt;

    this.ShowLetters(CorrespondenceDirection.inComing, { id: 1, expandable: true, icon: "", level: 1, name: "", parentid: null, count: 1, groupId: null });
  }
  status: boolean = false;
  clickEvent() {
    this.status = !this.status;
  }
  
  ngOnInit() {
    
    merge(//this.sort.sortChange,
      this.paginator.page)
      .pipe(
        // startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;

          return this.CorrespondenceService.GetIncomingOutgonigCorrespondence({
            correspondenceDirection: this.flt, programId: null, subProgramId: null,
            correspondenceStatusId: 1, correspondenceTypeId: this.selectedGroup, courseId: null, directionId: null, importent: null, studyLevelId: null, studentStatusId: null, personStatus: PersonType.student,
            read: null, pn: null, faculty: null, fName: null, sName: null, index: this.paginator.pageIndex, take: this.paginator.pageSize
          });
        }),
        map(data => {
          this.isLoadingResults = false;
          this.isRateLimitReached = false;
          //alternatively to response headers;
          this.resultsLength = data.count;
          this.categoryGroup = data.categoryGroup;
          return data.statementData;
        }),
        catchError(() => {
          this.isLoadingResults = false;
          this.isRateLimitReached = true;

          return observableOf([]);
        })
      ).subscribe(data => { this.entitiesDataSource.data = data; });
  }

  step = 0;

  //setStep(index: number) {
  //  this.step = index;
  //  this.selectedGroup = null;
  //  if (index == 0) {
  //    this.ShowLetters(CorrespondenceDirection.inComing)
  //  }
  //  else if (index == 1) {
  //    this.ShowLetters(CorrespondenceDirection.outGoing)
  //  }


  //}

  SelectedGroup(group: ExampleFlatNode) {
    this.selectedGroup = group.groupId;
    
    if (group.parentid == 1) {
        this.ShowLetters(CorrespondenceDirection.inComing, group)
      }
    else if (group.parentid == 2) {
        this.ShowLetters(CorrespondenceDirection.outGoing, group)
      }
   
   
    this.activeNode = group;
  }


  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }
  FilterNews(node: ExampleFlatNode) {
    
    if (node.groupId == null) {
      if (node.id == 1) {
        this.ShowLetters(CorrespondenceDirection.inComing, node)
      }
      else if (node.id == 2) {
        this.ShowLetters(CorrespondenceDirection.outGoing, node)
      }
    }
    else {
      this.SelectedGroup(node);
    }
  }

  FillSubGroups(groups: Array<CorrespondensCategoryGroup>, node: ExampleFlatNode) {
    if (node.groupId == null) {
      if (node.id == 1) {
        let d = new Array<boardType>();
        for (let i = 0; i < groups.length; i++) {
          d.push({ id: null, groupId: groups[i].correspondeceTypeId, name: groups[i].caption, count: groups[i].count, icon: '_cs_icon _cs_icon-list _cs_color-primary-6', parentid: 1, children:null });
        }
       
        this.pIt[0].children = d;
      }
      else if (node.id==2) {
        let p = new Array<boardType>();
        for (let i = 0; i < groups.length; i++) {
          p.push({ id: null, groupId: groups[i].correspondeceTypeId, name: groups[i].caption, count: groups[i].count, icon: '_cs_icon _cs_icon-list _cs_color-primary-6', parentid: 2, children: null });
        }
        
        this.pIt[1].children = p;
      }
      this.dataSource.data = this.pIt;
    
     // setInterval(e => { this.activeNode = node; }, 1000);
      
    }
  }

  ShowLetters(flt: CorrespondenceDirection, node: ExampleFlatNode) {

    if (flt) {
      //this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
      this.selection.clear();
      if (this.flt == null || this.flt != flt) {
        if (flt == 1 && (node.id == 1 || node.parentid==1)) {
          this.displayedColumns = ['select', 'fromToPerson',
            //'pn',
            'facultyCaption', /*'studyLevelCaption', 'directionCaption',*/
            //'staffStatus', 'studentEducationStatus', 'staffPosition',
            'stateCaption', 'importentStatusCaption', 'correspondeceTypeCaption'
            , 'correspondenceSubject', 'correspondenceDescription', 'syllabusCaption', 'createDate'];
        }
        else if (node.id == 2 || node.parentid == 2) {
          this.displayedColumns = ['select', 
            //'pn',
            //'facultyCaption', /*'studyLevelCaption', 'directionCaption',*/
            //'staffStatus', 'studentEducationStatus', 'staffPosition',
            'stateCaption', 'importentStatusCaption', 'correspondeceTypeCaption'
            , 'correspondenceSubject', 'correspondenceDescription', 'syllabusCaption', 'createDate'];
        }

        this.flt = flt;
      }
      this.CorrespondenceService.GetIncomingOutgonigCorrespondence({
        correspondenceDirection: flt,
        correspondenceStatusId: 1, programId: null, subProgramId: null, correspondenceTypeId: this.selectedGroup, courseId: null, directionId: null, importent: null, studyLevelId: null, studentStatusId: null, personStatus: PersonType.student,
          read: null, pn: null, faculty: null, fName: null, sName: null, index: 0, take: 20
      }).then(e => {
        this.entitiesDataSource.data = (e as RetStatementList).statementData; this.resultsLength = (e as RetStatementList).count,
        this.categoryGroup = (e as RetStatementList).categoryGroup;
        this.FillSubGroups(this.categoryGroup, node);
      });

    }
  }

  selectedCOrespondence: any;
  StudentSelect(student: any, i: any) {
    this.selectedRow = i;
    this.selectedCOrespondence = student;
    //this.onStudent.emit(student);
  }

  StudentSelectOpen(student: any, i: any) {
    this.selectedRow = i;
    this.selectedCOrespondence = student;
    this.ViewLetter();
  }


  CreateNewLetter(): void {
    let dialogRef = this.dialog.open(CreateViewLetterDocument, {
      panelClass: 'my-centered-dialog',
      height: '95%',
      width: '95%',
      disableClose: true,
      data: { formType: 1, recipientsList: null }
    });

    dialogRef.afterClosed().subscribe(result => {
     
      //this.animal = result;
    });

  }

  AnswerLetter(): void {
    let array: Array<CorrespondencStatement> = new Array<CorrespondencStatement>();
    if (this.selectedCOrespondence != null)
    array.push(this.selectedCOrespondence);
    if (array.length >0) {
      let dialogRef = this.dialog.open(CreateViewLetterDocument, {
        panelClass: 'my-centered-dialog',
        height: '95%',
        width: '95%',
        disableClose: true,
        data: { formType: 2, recipientsList: array }
      });

      dialogRef.afterClosed().subscribe(result => {
        
        //this.animal = result;
      });
    }
  }
  ViewLetter(): void {
    let array: Array<CorrespondencStatement> = new Array<CorrespondencStatement>();
    if (this.selectedCOrespondence!=null)
    array.push(this.selectedCOrespondence);
    if (array.length>0) {
      let dialogRef = this.dialog.open(CreateViewLetterDocument, {
        panelClass: 'my-centered-dialog',
        height: '95%',
        width: '95%',
        disableClose: true,
        data: { formType: 3, recipientsList: array }
      });

      dialogRef.afterClosed().subscribe(result => {
        
        //this.animal = result;
      });
    }
  }

}

export class boardType {
  id: number;
  name: string;
  parentid: number;
  icon: string;
  children?: boardType[];
  groupId?: number;
  count?: number;
}
interface ExampleFlatNode {
  expandable: boolean;
  name: string;
  level: number;
  icon: string;
  id: number;
  groupId?: number;
  count?: number;
  parentid: number;
}
