<div class="_cs_flex _cs_flex-a--center _cs_color-bg-gradient--primary-3 _cs_position-absolute _cs_position-left-px--0 _cs_position-up-px--0 _cs_size-w-percent--100 _cs_p-top--sm _cs_p-down--sm _cs_p-left--md _cs_p-right--md">
  <span class="_cs_label _cs_label-lg _cs_label-font-setting-case-on _cs_label-500 _cs_color-primary-5">{{captions.switchProfile}}</span>
</div>

<mat-dialog-content>
  <div *ngIf="studentProfileList">
    <mat-form-field class="pass _cs_size-w-percent--100">
      <mat-select placeholder={{captions.customerExchange}} [(ngModel)]="selectedStudent">
        <mat-option *ngFor="let ct of studentProfileList" [value]="ct.studId">
          {{ct.fakulty+"/"+ct.studyLevel+"/"+ct.studyProgram+"/"+ct.studyStatus}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end" class="_cs_col-xs-12 _cs_p-left--sm _cs_p-right--sm">
  <button class="_cs_button _cs_button-sm _cs_button-border--positive _cs_m-left--md" (click)="ChangeUserProfile()">
    <span class="_cs_label _cs_label-sm _cs_label-font-setting-case-on">{{captions.change}}</span>
  </button>
  <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->
  <button class="_cs_button _cs_button-sm _cs_button-border--positive _cs_m-left--md" [mat-dialog-close]="true">
    <span class="_cs_label _cs_label-sm _cs_label-font-setting-case-on">{{captions.close}}</span>
  </button>
</mat-dialog-actions>
