<div class="_cs_flex _cs_size-w-percent--100">
  <div class="_cs_size-w-percent--100 _cs_p-left--lg _cs_p-right--lg _cs_p-down--lg">
    <div [ngClass]="status ? 'sidebarbg--hidden' : 'sidebarbg--show'"
      class="_cs_size-h-percent--100 sidebarbg _cs_flex">
      <!-- sidebarbg__sidebar -->
      <div
        class="sidebarbg__sidebar  _cs_border-radius--sm _cs_color-bg--primary-5 _cs_shadow-primary-0-0--1 _cs_m-right--md">
        <div class="_cs_position-absolute _cs_position-left-percent--100 _cs_position-up-px--70 dropmenu">
          <div id="nav-icon" class="dropsidebar" (click)="clickEvent()">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>

        <mat-tree class="_cs_size-w-percent--100 _cs_size-h-percent--100 _cs_overflow-x--auto" [dataSource]="dataSource"
          [treeControl]="treeControl">
          <!-- This is the tree node template for leaf nodes -->
          <mat-tree-node class="" *matTreeNodeDef="let node" matTreeNodePadding>
            <!-- use a disabled button to provide padding for tree leaf -->
            <button mat-icon-button disabled class=""></button>

            <button
              class="_cs_size-w-percent--100 _cs_color-bg--primary-5 _cs_p-sm _cs_size-h-percent--100 _cs_position-relative _cs_flex _cs_activeitem-opacity-bg-primary-6-0--1 _cs_transition-0--3 _cs_border-rgba-down-primary-6-0--1"
              (click)="FilterNews(node);activeNode = node;" [ngClass]="{ '_cs_active': activeNode === node }">
              <!-- <span class="_cs_icon _cs_icon-room _cs_color-primary-5"></span> -->
              <!-- <span class="{{node.icon}}"></span> -->
              <span class="_cs_m-left--sm _cs_label _cs_label-sm _cs_label-font-setting-case-on _cs_color-primary-2 _cs_activeitem-color--primary-6 _cs_activeitem-color-bg--rgba-primary  _cs_m-top--xs _cs_m-down--xs _cs_label-a-start
">{{node.name}}</span>
            </button>
          </mat-tree-node>
          <!-- This is the tree node template for expandable nodes -->
          <mat-tree-node *matTreeNodeDef="let node;when: hasChild" matTreeNodePadding>
            <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'toggle ' + node.name">
              <mat-icon class="mat-icon-rtl-mirror">
                {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
              </mat-icon>
            </button>
            <button
              class="_cs_size-w-percent--100 _cs_color-bg--primary-5 _cs_p-sm _cs_size-h-percent--100 _cs_position-relative _cs_flex _cs_activeitem-opacity-bg-primary-6-0--1 _cs_transition-0--3 _cs_border-rgba-down-primary-6-0--1"
              (click)="FilterNews(node);activeNode = node;" [ngClass]="{ '_cs_active': activeNode === node }">
              <!-- <span class="{{node.icon}}"></span> -->
              <span class="_cs_m-left--sm _cs_label _cs_label-sm _cs_label-font-setting-case-on _cs_color-primary-2 _cs_activeitem-color--primary-6 _cs_activeitem-color-bg--rgba-primary  _cs_m-top--xs _cs_m-down--xs _cs_label-a-start
">{{node.name}}</span>
            </button>
          </mat-tree-node>
        </mat-tree>
      </div>
      <div class="sidebarbg__wrapper _cs_size-w-percent--100 _cs_flex _cs_flex-d--column _cs_p-left--md _cs_mat-tab">
        <div class="_cs_m-down--md _cs_m-top--xxl">
          <div
            class="_cs_p-xs _cs_shadow-primary-0-0--1 _cs_border-radius--sm _cs_border-down--primary-6 _cs_flex _cs_flex-a--center">
            <span
              class="_cs_icon _cs_icon-xl _cs_shadow-primary-6-0--2 _cs_border-radius--full _cs_border--primary-6 _cs_m-right--md">
              <span class="_cs_icon _cs_icon-md _cs_color-primary-6 _cs_icon-correspondence"></span>

            </span> <span
              class="_cs_label _cs_label-sm _cs_label-font-setting-case-on _cs_label-bold _cs_color-primary-6">{{captions.correspondece}}</span>
          </div>
        </div>
        <div class="_cs_size-w-percent--100">
          <div class="example-loading-shade" *ngIf="isLoadingResults || isRateLimitReached">
            <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
            <div class="example-rate-limit-reached" *ngIf="isRateLimitReached">
            </div>
          </div>
          <div
            class="_cs_shadow-primary-0-0--1 _cs_border-radius--xxl _cs_size-w-percet--100 _cs_p-md _cs_p-down--none _cs_flex _cs_flex-wrap _cs_flex-j--center _cs_m-down--xxl _cs_color-bg--primary-5">
            <div class="_cs_p-down--md  _cs_p-left--sm _cs_p-right--sm">
              <button
                class=" _cs_button _cs_button-border--positive _cs_size-w-percent--100 _cs_button-sm  _cs_m-down--none"
                (click)="CreateNewLetter()">
                <span class="_cs_label _cs_label-sm _cs_label-font-setting-case-on">{{captions.createNewMessage}} </span>
              </button>
            </div>
            <div class="_cs_p-down--md  _cs_p-left--sm _cs_p-right--sm">
              <button *ngIf="flt == 1"
                class=" _cs_button _cs_button-border--positive _cs_size-w-percent--100 _cs_button-sm  _cs_m-down--none"
                (click)="AnswerLetter()">
                <span class="_cs_label _cs_label-sm _cs_label-font-setting-case-on">{{captions.givingGroupResponse}}</span>
              </button>
            </div>

            <div class="_cs_p-down--md  _cs_p-left--sm _cs_p-right--sm">
              <button
                class=" _cs_button _cs_button-border--positive _cs_size-w-percent--100 _cs_button-sm  _cs_m-down--none"
                (click)="ViewLetter()">

                <span class="_cs_label _cs_label-sm _cs_label-font-setting-case-on">{{captions.read}}</span>

              </button>
            </div>
          </div>
          <div class="_cs_col-xs-12 _cs_table-container _cs_scrolled-table ">

            <table cdk-table [dataSource]="entitiesDataSource" class="table table-bordered table-hover">
              <ng-container cdkColumnDef="select">


                <th cdk-header-cell *cdkHeaderCellDef>
                  <!--<mat-checkbox (change)="$event ? masterToggle() : null"
                    [checked]="selection.hasValue() && isAllSelected()"
                    [indeterminate]="selection.hasValue() && !isAllSelected()">
                  </mat-checkbox>-->
                  #
                </th>
                <td cdk-cell *cdkCellDef="let row; let i=index;">
                  <!--<mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
                    [checked]="selection.isSelected(row)">
                  </mat-checkbox>-->
                  {{i+1}}
                </td>
              </ng-container>
              <!-- Position Column -->
              <ng-container cdkColumnDef="fromToPerson" >
                <th cdk-header-cell *cdkHeaderCellDef>{{captions.sender}}</th>
                <td cdk-cell *cdkCellDef="let element"> {{element.fName+" "+ element.sName}} </td>
              </ng-container>

              <!-- Weight Column -->
              <!--<ng-container cdkColumnDef="pn">
        <th cdk-header-cell *cdkHeaderCellDef> პირადი # </th>
        <td cdk-cell *cdkCellDef="let element"> {{element.pn}} </td>
      </ng-container>-->
              <ng-container cdkColumnDef="correspondenceSubject">
                <th cdk-header-cell *cdkHeaderCellDef>{{captions.title}} </th>
                <td cdk-cell *cdkCellDef="let element"> {{element.correspondenceSubject}} </td>
              </ng-container>
              <ng-container cdkColumnDef="correspondenceDescription">
                <th cdk-header-cell *cdkHeaderCellDef>{{captions.content}} </th>
                <td cdk-cell *cdkCellDef="let element"> {{element.correspondenceDescription}} </td>
              </ng-container>
              <!-- Symbol Column -->
              <ng-container cdkColumnDef="facultyCaption">
                <th cdk-header-cell *cdkHeaderCellDef>{{captions.faculty}} </th>
                <td cdk-cell *cdkCellDef="let element"> {{element.facultyCaption}} </td>
              </ng-container>
              <!--<ng-container cdkColumnDef="studyLevelCaption">
        <th cdk-header-cell *cdkHeaderCellDef> საფეხური </th>
        <td cdk-cell *cdkCellDef="let element"> {{element.studyLevelCaption}} </td>
      </ng-container>
      <ng-container cdkColumnDef="directionCaption">
        <th cdk-header-cell *cdkHeaderCellDef> მიმართულება </th>
        <td cdk-cell *cdkCellDef="let element"> {{element.directionCaption}} </td>
      </ng-container>-->
              <!--<ng-container cdkColumnDef="staffStatus">
        <th cdk-header-cell *cdkHeaderCellDef> თან. სტატუსი </th>
        <td cdk-cell *cdkCellDef="let element"> {{element.staffStatus}} </td>
      </ng-container>-->
              <!--<ng-container cdkColumnDef="studentEducationStatus">
        <th cdk-header-cell *cdkHeaderCellDef> სტუდ. სტატუსი </th>
        <td cdk-cell *cdkCellDef="let element"> {{element.studentEducationStatus}} </td>
      </ng-container>-->
              <ng-container cdkColumnDef="staffPosition">
                <th cdk-header-cell *cdkHeaderCellDef>{{captions.position}} </th>
                <td cdk-cell *cdkCellDef="let element"> {{element.staffPosition}} </td>
              </ng-container>
              <ng-container cdkColumnDef="stateCaption">
                <th cdk-header-cell *cdkHeaderCellDef> {{captions.status}} </th>
                <td cdk-cell *cdkCellDef="let element"> {{element.stateCaption}} </td>
              </ng-container>
              <ng-container cdkColumnDef="importentStatusCaption">
                <th cdk-header-cell *cdkHeaderCellDef>{{captions.importanceLevel}}</th>
                <td cdk-cell *cdkCellDef="let element"> {{element.importentStatusCaption}} </td>
              </ng-container>
              <ng-container cdkColumnDef="correspondeceTypeCaption">
                <th cdk-header-cell *cdkHeaderCellDef>{{captions.structType}} </th>
                <td cdk-cell *cdkCellDef="let element"> {{element.correspondeceTypeCaption}} </td>
              </ng-container>
              <ng-container cdkColumnDef="syllabusCaption">
                <th cdk-header-cell *cdkHeaderCellDef>{{captions.subjectName}} </th>
                <td cdk-cell *cdkCellDef="let element"> {{element.syllabusCaption}} </td>
              </ng-container>
              <!--<ng-container cdkColumnDef="readStatus">
                <th cdk-header-cell *cdkHeaderCellDef> წაკითხული </th>
                <td cdk-cell *cdkCellDef="let element"> {{element.readStatus}} </td>
              </ng-container>-->
              <ng-container cdkColumnDef="createDate">
                <th cdk-header-cell *cdkHeaderCellDef>{{captions.datetime}}</th>
                <td cdk-cell *cdkCellDef="let element"> {{element.createDate | date:'yyyy-MM-dd HH:mm'}} </td>
              </ng-container>
              <tr cdk-header-row *cdkHeaderRowDef="displayedColumns"></tr>
              <tr cdk-row *cdkRowDef="let row; columns: displayedColumns;  let i = index"
                (click)="StudentSelect(row,i)"  [class.RedStatus]="!row.readStatus" [class.active]="i == selectedRow" (dblclick)="StudentSelectOpen(row,i)"></tr>
            </table>
          </div>

        </div>
        <mat-paginator class="_cs_m-top--auto _cs_shadow-primary-0-0--1 _cs_border-radius--sm mat-paginator"
          [pageSizeOptions]="[20, 50, 100]" showFirstLastButtons #paginator [length]="resultsLength">
        </mat-paginator>
      </div>
    </div>
  </div>
</div>
