<div class="_cs_flex _cs_size-w-percent--100">
  <div class="_cs_size-w-percent--100 _cs_p-left--lg _cs_p-right--lg _cs_p-down--lg">
    <div class="_cs_size-h-percent--100 sidebarbg _cs_flex">
      <div class="sidebarbg__wrapper _cs_p-left--none-i _cs_size-w-percent--100 _cs_flex _cs_flex-d--column">
        <div class="_cs_m-down--md _cs_m-top--xxl">
          <div class="_cs_p-xs _cs_shadow-primary-0-0--1 _cs_border-radius--sm _cs_border-down--primary-6 _cs_flex _cs_flex-a--center">
            <span class="_cs_icon _cs_icon-xl _cs_shadow-primary-6-0--2 _cs_border-radius--full _cs_border--primary-6 _cs_m-right--md">
              <span class="_cs_icon _cs_icon-md _cs_color-primary-6 _cs_icon-curriculum"></span>
            </span> <span class="_cs_label _cs_label-sm _cs_label-font-setting-case-on _cs_label-bold
                _cs_color-primary-6">{{captions.schedule}}</span>
          </div>
        </div>
        <div class="_cs_flex" *ngIf="captions">
          <div>
            <div>
              <span>
                <ejs-checkbox #ownerOneObj cssClass="learnS" [label]="captions.LearnScheduler" value="1" [checked]="true" (change)="onChange()">
                </ejs-checkbox>
              </span>
              <span class="paddingExLeft">
                <ejs-checkbox #ownerTwoObj cssClass="examS"  [label]="captions.ExmaScheduler" value="2" [checked]="true" (change)="onChange()">
                </ejs-checkbox>
              </span>
            </div>
            <div >
              <ejs-schedule #schedule height='750px'
                            [locale]="language==1?'en-GB':'en'"
                            width="100%" startHour="09:00" [(selectedDate)]="selectedDate" [eventSettings]="eventSettings" queryCellInfo="colorChange" (eventRendered)="onEventRendered($event)" dateFormat="yyyy-dd-MM" [currentView]="currentView">
                <ng-template #timeScaleMajorSlotTemplate let-data>
                  <div class="majorTime">{{getMajorTime(data.date)}}</div>
                </ng-template>
                <e-resources>
                  <e-resource field='OwnerId' title='Owners' [dataSource]='resourceDataSource' [allowMultiple]='allowMultiple'
                              name='Owners' textField='OwnerText' idField='OwnerId' colorField='Color'>
                  </e-resource>
                </e-resources>
                <e-views>
                  <e-view option="Week"></e-view>
                  <e-view option="Month"></e-view>
                  <e-view option="Agenda"></e-view>
                </e-views>
                <!--<ng-template #timeScaleMinorSlotTemplate let-data>[timeScale]="timeScale"
      <div class="minorTime">{{getMinorTime(data.date)}}</div>
    </ng-template>-->
              </ejs-schedule>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
