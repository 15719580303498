import { PersonDocument, PersonOrders, PersonContactData, PersonEditionAnketData, PerConferenceP, PerEducataionP, PerPracticalExperienceP, PerProfesionGrowthP, PerProjectsP, PerPublicationsP } from "./Profile";
import { OrderFiles } from "./Order";

export interface StudName {
        id: number;
        fName: string;
        sName: string;
        pName: string;
        language: string;
        languageId: number;
        
    }

export class StudentStudyPrograms {
  public id: number;
  public studyProgramId: number;
  public studyProgramName: string;
  public  programType: string;
  public  createDate: Date;
    }




export class StudAnketaData {
  public studId: number;
  public personId: number;
  public persNumber: string;
  public sexId: number;
  public sexCaption: string;
  public aspNetUserId: string;
  public fakulty: string;
  public facultyId: number;
  public StudGroupId: number;
  public StudGroup: string;
  public studyLevel: string;
  public studyLevelId: number;
  public enterYear: string;
  public naecReiting: number;
  public birthDay: Date;
  public semester: string;
  public studyStatus: string;
  public studyStatusID: number;
  public avgMark: number;
  public gpa: number;
  public sumCredits: number;
  public studentName: StudName[];
  public studentPrograms: StudentStudyPrograms[];
  public personContactData: PersonContactData[]
  public personOrders: PersonOrders[];
  public files: Array<PersonDocument>;
  public editionAnketData: Array<PersonEditionAnketData>;
  public perConference: Array<PerConferenceP>;
  public perEducataion: Array<PerEducataionP>;
  public perPracticalExperience: Array<PerPracticalExperienceP>;
  public perProfesionGrowth: Array<PerProfesionGrowthP>;
  public perProjects: Array<PerProjectsP>;
  public perPublications: Array<PerPublicationsP>;
}
