export class QuizResult {
  studentID: number;
  quizResultData: Array<QuizResultData>;
}

export class QuizResultData {
  answeId: number;
  answerText: string;
  subAnswersSelected?: number;
  updateQuizAnswerId?: number;
  removeQuizAnswerId?: number;
  answerSelected?: boolean;
  entrollHistoryId: number;
}


export class QuizParameters {
  public id: number;
  public studId: number;
  public entrollHistoryId: number;
}
