export class StudentPaymentSession {
  public id: number | null;
  public programPaymentId: number | null;
  public otherPaymentConfigId: number | null;
  public studentId: number;
  public personId: number | null;
  public studyYear: string | null;
  public studySemester: string | null;
  public paymentTypeID: number;
  public paymentType: string | null;
  public paymentStatus: string | null;
  public paymentStatusId: number;
  public payment: number | null;
  public credit: number | null;
  public creditPayment: number | null;
  public discount: number | null;
  public currencyCaption: string | null;
  public currencyId: number;
  public sumPay: number | null;
  public finGranSum: number | null;
  public finGranPaydSym: number | null;
  public studySessionId: number;
  public leftPayment: number;
  public paymentPercentage: number | null;
}


export class StudentPaymentResult {
  public paymentList: Array<StudentPaymentSession>;
  public debt: number;
    }


export class StudentAddPaymentSession {
  public id: number;
  public studySessionId: number;
  public paymentStatusId: number;
  public paymentId: number;
  public discount: number;
}
export class StudPayment {
  public id: number;
  public studId: number;
  public payment: number;
  public statusId: number;
  public statusCaption: string;
  public sessionDuration: number;
  public credit: number;
  public paymentTypeId: number;
  public paymentTypeCaption: string;
  public durationTypeId: number;
  public durationTypeCaption: string;
  public currency: number;
  public currencyCaption: string;
}
export class StudFinPays {
  public id: number;
  public sessionPaymentsId: number;
  public payDirectionId: number;
  public payDirectionCaption: string;
  public payerTypeId: number;
  public payerTypeCaption: string;
  public debtDistributionId: number;
  public payDateTime: Date;
  public payerIdentificationDatum: string;
  public payMoney: number;
  public currency: number;
  public mainCurrency: number;
  public currencyCaption: string;
  public endDateDebtDistribution: Date;
  public inMainCurrency: number;
  public mainCurrencyCaption: string;
  public rate: number;
  public currencyRateCaption: string;
  public forOwnPay: number;
}
export class StudentFinGrant {
  public id: number;
  public studentId: number;
  public grantTypeCaption: string;
  public paymentType: string;
  public paymentDurationType: string;
  public grantID: number;
  public sessionDuration: number;
  public sessionDurationRemaining: number;
  public sessionTransaction: number;
  //public payment: number | null;
  public grantNumber: string;
  public grantDate: Date;
  public currencyCaption: string;
}

export class StudentFinGrantAnk {
  public id: number;
  public studentId: number;
  public grantTypeCaption: string;
  public paymentType: string;
  public paymentDurationType: string;
  public grantID: number;
  public sessionDuration: number;
  public sessionDurationRemaining: number;
  public sessionTransaction: number;
  public payment: number | null;
  public grantNumber: string;
  public grantDate: Date;
  public currencyCaption: string;
  public percentage: number | null;
}

export class StudentFinDebtDistribution {
  public id: number;
  public sessionPaymentId: number;
  //public num: number;
  public payMoney: number;
  //public startDate: Date;
  public endDate: Date;
  public statusCaption: string;
  public statusId: number;
  public sumPayInDebtDistribution: number;
  public sumPay: number;
  public leftMoneyToPay: number;
  public fine: number;
}

export class StudentFinDebtDistributionResult {
  public studentFinDebtDistributionList: Array<StudentFinDebtDistribution>
  public leftPaydMony: number;
  public sumFine: number;
}

export class StudentsGrantsList {
  public id: number;
  public grantNumber: string;
  public grantDate: Date;
  public grantDescription: string;
  public grantTypeId: number;
  public grantTypeCaption: string;
  public currencyCaption: string;
  public currency: number;
}

export class StudentSessionGrantList {
  public id: number;
  public studentGrantId: number;
  public sessionPaymentId: number;
  public grantNumber: string;
  public grantDate: Date;
  public sessionTransaction: number;
  public granTypeCaption: string;
}

export class OtherPaymentsConfig {
  public id: number | null;
  public payment: number | null;
  public paymentTypeId: number;
  public statusId: number;
  public studyLevelId: number | null;
  public fakultyId: number | null;
  public currencyId: number;
  public paymentPercentage: number | null;
  public fakultyCaption: string | null;
  public studyLevelCaption: string | null;
  public currencyName: string | null;
  public paymentText: string | null;
  public statusCaption: string | null;
}


export class FinanceFilter {
  public facultyId: number | null;
  public studyLevelId: number | null;
  public finStatus: number | null;
  public paymentTypeId: number | null;
  public currencyId: number | null;
  public durationTypeId: number | null;
  public payerId: number | null;
  public directionId: number | null;
}

export class StudentCurrentPayment {
  public paymentId: number;
  public semesterPayment: number;
  public creditPayment: number;
  public currencyId: number;
  public currencyCaption: string;
}


export class OtherPaymentFilter {
  public studentId: number;
  public paymentTypeId: number;
}
