import { Component, Inject,  ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

import { MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { SelectionModel } from '@angular/cdk/collections';
import { FormGroup, UntypedFormBuilder, FormControl } from '@angular/forms';
import { UIfilter } from '../../../Models/filters';
import { StudentsListData, RetStudList } from '../../../Models/StudentsList';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';



@Component({
  selector: 'PersoneListDialog',
  templateUrl: 'PersoneListDialog.html',
  styleUrls: ['PersoneListDialog.css']//,
  // providers: [ChecklistDatabase]
})



export class PersoneListDialog {
  

  public studentsList: RetStudList;
  resultsLength = 0;
  isLoadingResults = false;
  isRateLimitReached = false;
  selectedRow: any;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  displayedColumns: string[] = ['select','fName', 'sName', 'persNumber', 'fakulty'];
  entitiesDataSource: MatTableDataSource<StudentsListData> = new MatTableDataSource();
  selection = new SelectionModel<StudentsListData>(true, []);
  private _filter: UIfilter;
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.entitiesDataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.entitiesDataSource.data.forEach(row => this.selection.select(row));
  }


  constructor(public dialog: MatDialog,
    public dialogRef: MatDialogRef<PersoneListDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private activatedRoute: ActivatedRoute,
    //private SharedService: SharedService,
    private route: ActivatedRoute,
    private location: Location,
    //private StudListService: StudListService,
    private router: Router, private fb: UntypedFormBuilder) {
  }
  ngOnInit() {
    this.entitiesDataSource.paginator = this.paginator;
    //merge(//this.sort.sortChange,
    //  this.paginator.page)
    //  .pipe(
    //    //startWith({}),
    //    switchMap(() => {
    //      this.isLoadingResults = true;
    //      return this.StudListService.StudentsList(this._filter.FilterPersone,
    //        this.paginator.pageSize, this.paginator.pageIndex);
    //    }),
    //    map(data => {
    //      // Flip flag to show that loading has finished.
    //      this.isLoadingResults = false;
    //      this.isRateLimitReached = false;
    //      this.resultsLength = data.count;

    //      return data.studentData;
    //    }),
    //    catchError(() => {
    //      this.isLoadingResults = false;
    //      // Catch if the GitHub API has reached its rate limit. Return empty data.
    //      this.isRateLimitReached = true;
    //      return observableOf([]);
    //    })
    //  ).subscribe(data => this.entitiesDataSource.data = data);


  }

  Filter(filter: UIfilter) {
    console.log(filter);
    this._filter = filter;
    if (filter) {
      //this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);


     // this.StudListService.StudentsList(filter.FilterPersone, 0, 0).then(e => { this.import { MatDialog } from '@angular/material';.data = (e as RetStudList).studentData; this.resultsLength = (e as RetStudList).count });
    }
  }

  RecipientsData(): void {
   // this.data = this.selection;
    this.dialogRef.close(this.selection);
  }
}
