import { Component, Inject, EventEmitter, Injectable, Pipe, PipeTransform, ViewChild } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormBuilder } from '@angular/forms';
import { UserService } from '../_services/user.service';
import { StudentsListData } from '../../Models/StudentsList';
import { User } from '../_models/user';
import { Caption, PortalLanguageCaption } from '../../LanguageModel/Caption';

@Component({
  selector: 'ChangeStudentProfile',
  templateUrl: './ChangeStudentProfile.html',
  styleUrls: ['./ChangeStudentProfile.css']
})
/** ChangePassword component*/
export class ChangeStudentProfile {
/** ChangePassword ctor */

  selectedStudent: number;
  studentProfileList: Array<StudentsListData>;
  public captions: Caption;

  constructor(public dialog: MatDialog,
    public dialogRef: MatDialogRef<ChangeStudentProfile>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private activatedRoute: ActivatedRoute,
    //private SharedService: SharedService,
    private route: ActivatedRoute,
    private location: Location,
    private userService: UserService,
    private router: Router, private fb: UntypedFormBuilder) {
    this.captions = PortalLanguageCaption[parseInt(localStorage.getItem("language")) - 1];
    let ise = JSON.parse(localStorage.getItem('currentUser')) as User;
    this.selectedStudent = ise.student.studentId;
    this.userService.GetStudentListOnPerson(ise.student.personId).then(e => {
      this.studentProfileList = e;
      
    });
  }

  ChangeUserProfile(): void {
    this.userService.ChangeDefaultStudent(this.selectedStudent).then(e => {

      let ise = JSON.parse(localStorage.getItem('currentUser')) as User;
      ise.student = e;
      localStorage.setItem('currentUser', JSON.stringify(ise));
      localStorage.setItem('language', ise.student.studyLanguageId.toString());
    });

    this.dialogRef.close();
  }
}
