<div
  class="_cs_flex _cs_flex-a--center _cs_color-bg-gradient--primary-3 _cs_position-absolute _cs_position-left-px--0 _cs_position-up-px--0 _cs_size-w-percent--100 _cs_p-top--sm _cs_p-down--sm _cs_p-left--md _cs_p-right--md">
  <span class="_cs_label _cs_label-lg _cs_label-font-setting-case-on _cs_label-500 _cs_color-primary-5">{{captions.detailEvalution+": "+data.subjectName}}</span>
  <button
    class="_cs_m-left--auto _cs_icon _cs_icon-lg _cs_border-radius--full _cs_shadow-primary-0-0--3 _cs_color-bg--primary-5"
    [mat-dialog-close]="true">
    <span class="_cs_icon _cs_icon-xs _cs_icon-cross _cs_color-primary-2"></span>
  </button>
</div>
<mat-dialog-content>

  <div class="_cs_size-w-percent--100 _cs_p-left--sm _cs_p-right--sm">
    <section class="example-section">
      <mat-radio-group [(ngModel)]="labelPosition" (change)="getGroupsOnSelected($event.value)" class="Radio-margin">
        <mat-radio-button [value]="examEvalution">{{examEvalution.lectureCaption}}</mat-radio-button>
        <mat-radio-button *ngFor="let ls of lecutreTypeList" [value]="ls">{{ls.lectureCaption}}</mat-radio-button>
      </mat-radio-group>
    </section>

      <div class="col-md-5">
      <table class="table table-bordered" *ngIf="studentEvaluationGenerator">
        <thead>
          <tr>
            <th>#</th>
            <th>კომპონენტი</th>
            <th>გამოთ. ტიპი</th>
            <th>შეფ. შეტ.</th>
            <th>შეფასება</th>
            <th>მაქს. შეტ.</th>
            <th>კომპ. რაოდ</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let s of syllabusComponents; let iih=index">
            <td>{{(iih+1)}}</td>
            <td>{{s.componentName}}</td>
            <td>{{s.calculationCaption}}</td>
            <td>{{s.evalutionTypeCaption}}</td>
            <td>{{s.maxMark}}</td>
            <td>{{s.maxEnterMark}}</td>
            <td>{{s.maxCompCountOnWeeks}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <div class="_cs_size-w-percent--100 _cs_p-left--sm _cs_p-right--sm" *ngIf="studentEvaluationGenerator">

    <table class="special-talbe table table-hover table-bordered">
      <thead>
       
        <tr *ngIf="studentEvaluationGenerator.evaluationHeader.length>0&&studentEvaluationGenerator.evaluationHeader[0].weekId">

          <th colspan="1">

          </th>
          <ng-container *ngFor="let hd of studentEvaluationGenerator.evaluationHeader; let is=index;">
            <ng-container *ngIf="hd.weekId!=(studentEvaluationGenerator.evaluationHeader.length>is+1? studentEvaluationGenerator.evaluationHeader[is+1].weekId:-1)">
              <th [attr.colspan]="WeekIdentyCount(hd.weekId)" class="cellTextOrCon">
                {{hd.weekNameGEO}}
              </th>
            </ng-container>
          </ng-container>

        </tr>
        <tr class="special-talbe__whiteitem">
          <th colspan="1">

          </th>
          <ng-container *ngFor="let hd2 of studentEvaluationGenerator.evaluationHeader;let ic=index;">
            <ng-container *ngIf="hd2.componentOnSyllabusId!=(studentEvaluationGenerator.evaluationHeader.length>ic+1? studentEvaluationGenerator.evaluationHeader[ic+1].componentOnSyllabusId:-1)||(hd2.componentOnSyllabusId==studentEvaluationGenerator.evaluationHeader[ic+1].componentOnSyllabusId&&hd2.weekId!=studentEvaluationGenerator.evaluationHeader[ic+1].weekId)">
              <th [attr.colspan]="ComponentIdentyCount(hd2.componentOnSyllabusId,hd2.weekId)" class="cellTextOrCon">
                <span class="tdtextOrientation component"> {{hd2.componentName}}</span>
              </th>
            </ng-container>
          </ng-container>
        </tr>
        <tr class="special-talbe__whiteitem" *ngIf="studentEvaluationGenerator.evaluationHeader.length>0&&studentEvaluationGenerator.evaluationHeader[0].weekDayId">
          <th colspan="1"></th>
          <ng-container *ngFor="let hd3 of studentEvaluationGenerator.evaluationHeader;let iwd=index;">
            <ng-container *ngIf="hd3.weekDayId!=(studentEvaluationGenerator.evaluationHeader.length>iwd+1? studentEvaluationGenerator.evaluationHeader[iwd+1].weekDayId:-1)||(hd3.weekDayId==studentEvaluationGenerator.evaluationHeader[iwd+1].weekDayId&&(hd3.componentOnSyllabusId!=studentEvaluationGenerator.evaluationHeader[iwd+1].componentOnSyllabusId||hd3.weekId!=studentEvaluationGenerator.evaluationHeader[iwd+1].weekId))">
              <th [attr.colspan]="weekDayIdIdentyCount(hd3.weekDayId,hd3.weekId,hd3.componentOnSyllabusId)" class="cellTextOrCon">
                <span class="tdtextOrientation"> {{hd3.dayName}}</span>
              </th>
            </ng-container>
          </ng-container>
        </tr>
        <tr>
          <!--<th>#</th>
          <th>გვარი</th>
          <th>სახელი</th>
          <th>პირადი ნომერი</th>-->
          <th>ჯამი</th>
          <ng-container *ngFor="let hd3 of studentEvaluationGenerator.evaluationHeader; ">

            <th [ngClass]="{'cellTextOrCon':true,'datetimeC':hd3.startDateTime!=null}">
              <span class="tdtextOrientation"> {{hd3.startTime==null?hd3.startDateTime: hd3.startTime.substring(0, 5)}}</span>
            </th>
          </ng-container>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let m of studentEvaluationGenerator.studentListOnLecture;let i=index;">
          <!--<td>{{(i+1)+((p-1)*20)}}</td>
          <td>{{m.sN}}</td>
          <td>{{m.fN}}</td>
          <td>{{m.pn}}</td>-->
          <td>
            {{m.sumMarks}}
          </td>
          <td *ngFor="let mark of m.eM; let im=index" class="td">
             {{studentEvaluationGenerator.evaluationHeader[im].evalutionTypeId==2?(mark==1?'+':''):mark}}
          </td>
        </tr>
      </tbody>
   
    </table>
  </div>
</mat-dialog-content>
