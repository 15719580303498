import { Component } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { QuizService } from './Service/QuizService';
import { Validators, FormBuilder } from '@angular/forms';
import { MatRadioButton, MatRadioChange } from '@angular/material/radio';
import { QuizResult, QuizResultData } from './Models/QuizRetResult'
import { Caption, PortalLanguageCaption } from '../../LanguageModel/Caption';
import { HttpClient } from '@angular/common/http';
import { AlertOne } from '../../Components/Dialogs/AlertDialogs/AlertOne';


@Component({
  selector: 'quiz',
  templateUrl: 'Quiz.html',
  styleUrls: ['Quiz.css']
})

export class Quiz {

  public quizList: any;
  public quizQuestions: any;
  public studID: any;
  public captions: Caption;

  constructor(public dialog: MatDialog,
    private QuizService: QuizService,
    private activatedRoute: ActivatedRoute, private route: ActivatedRoute,
    private location: Location,
    private router: Router,
    private http: HttpClient) {
    this.captions = PortalLanguageCaption[parseInt(localStorage.getItem("language")) - 1];


    let dialogRef = this.dialog.open(AlertOne, {
      panelClass: 'my-centered-dialog',
      height: '95%',
      width: '95%',
      disableClose: true,
      data: { languageId: parseInt(localStorage.getItem("language"))}
    });
    //this.studID = this.route.snapshot.paramMap.get('studID');
    this.route.queryParams.subscribe(params => {


      //alert(this.studID);

      QuizService.QuizList().then(e => { this.quizList = e; });
    })
    //alert(this.studID);

  }
  entrolHistoryId: number = null;
  LoadQuestiond(data: any): void {

    this.QuizService.Questions({ id: data.quizId, studId: this.studID, entrollHistoryId: data.enrollHistoryId }).then(e => { this.quizQuestions = e });
    this.entrolHistoryId = data.enrollHistoryId;
  }

  QuizValidator(): boolean {

    let result = true;
    let message = "";
    if (this.quizQuestions != null) {
      for (let i = 0; i < this.quizQuestions.length; i++) {
        let countSelect = 0;
        for (let k = 0; k < this.quizQuestions[i].answers.length; k++) {
          if (this.quizQuestions[i].answers[k].answerSelected) {
            countSelect++;
          }
        }

        if (this.quizQuestions[i].selectMinAnswers != null) {
          if (this.quizQuestions[i].selectMinAnswers > countSelect) {
            result = false;
            message = message + this.quizQuestions[i].caption + "; აღნიშნული კითხვიდან უნდა აირჩიოთ კიდევ " + (this.quizQuestions[i].selectMinAnswers - countSelect) + " პასუხი; \n"
          }
        }

        if (this.quizQuestions[i].selectMaxAnswers != null) {
          if (this.quizQuestions[i].selectMaxAnswers < countSelect) {
            result = false;
            message = message + this.quizQuestions[i].caption + "; აღნიშნული კითხვიდან ზედმეტად არჩეული გაქვთ " + (countSelect - this.quizQuestions[i].selectMaxAnswers) + " პასუხი; \n"
          }
        }

      }
    }
    if (result == false) {
      alert(message);
    }
    return result;
  }


  SaveQuizResult(): void {
    if (this.QuizValidator()) {
      let qr = new QuizResult();
      qr.studentID = this.studID;
      qr.quizResultData = new Array<QuizResultData>();
      if (this.quizQuestions != null) {
        for (let i = 0; i < this.quizQuestions.length; i++) {
          for (let k = 0; k < this.quizQuestions[i].answers.length; k++) {

            if (this.quizQuestions[i].answers[k].answerSelected == false && this.quizQuestions[i].answers[k].quizAnswerId != null) {
              this.quizQuestions[i].answers[k].removeQuizAnswerId = this.quizQuestions[i].answers[k].quizAnswerId;
              let pr = new QuizResultData();
              pr.answeId = this.quizQuestions[i].answers[k].id;
              pr.answerText = this.quizQuestions[i].answers[k].answerText;
              pr.subAnswersSelected = this.quizQuestions[i].answers[k].subAnswersSelected;
              pr.removeQuizAnswerId = this.quizQuestions[i].answers[k].quizAnswerId;
              pr.updateQuizAnswerId = this.quizQuestions[i].answers[k].quizAnswerId;
              pr.entrollHistoryId = this.entrolHistoryId;
              pr.answerSelected = false;
              qr.quizResultData.push(pr);
            }
            else if (this.quizQuestions[i].answers[k].answerSelected == true && this.quizQuestions[i].answers[k].quizAnswerId != null) {
              this.quizQuestions[i].answers[k].removeQuizAnswerId = this.quizQuestions[i].answers[k].quizAnswerId;
              let pr = new QuizResultData();
              pr.answeId = this.quizQuestions[i].answers[k].id;
              pr.answerText = this.quizQuestions[i].answers[k].answerText;
              pr.subAnswersSelected = this.quizQuestions[i].answers[k].subAnswersSelected;
              pr.updateQuizAnswerId = this.quizQuestions[i].answers[k].quizAnswerId;
              pr.entrollHistoryId = this.entrolHistoryId;
              pr.answerSelected = true;
              qr.quizResultData.push(pr);
            }
            else
              if (this.quizQuestions[i].answers[k].answerSelected == true && this.quizQuestions[i].answers[k].quizAnswerId == null) {

                let pr = new QuizResultData();
                pr.answeId = this.quizQuestions[i].answers[k].id;
                pr.answerText = this.quizQuestions[i].answers[k].answerText;
                pr.subAnswersSelected = this.quizQuestions[i].answers[k].subAnswersSelected;
                pr.entrollHistoryId = this.entrolHistoryId;
                pr.answerSelected = true;
                qr.quizResultData.push(pr);
              }


          }
        }
      }
      this.QuizService.InsertQuizResult(qr).then(e => {

        if (e) {
          alert(this.captions.done);
          this.QuizService.QuizList().then(e1 => { this.quizList = e1; });
        }
      });
      this.quizQuestions = null;

    }
    // alert(qr.quizResultData.length);
  }


  onChange(mrChange: MatRadioChange, al: any, qv: any) {
    let mrButton: MatRadioButton = mrChange.source;
    for (let i = 0; i < al.answers.length; i++) {
      if (al.answers[i] == qv) {
        al.answers[i].answerSelected = true;
      }
      else {
        al.answers[i].answerSelected = false;
      }
    }
  }
  onChangeText(e, al: any, qv: any) {
    let changedT = e.target.value;
    if (changedT.length < 3) {
      qv.answerSelected = false;
    }
    else {
      qv.answerSelected = true;
    }
  }
  onChangeSub(mrChange: MatRadioChange, an: any, qv: any) {
    let mrButton: MatRadioButton = mrChange.source;
    an.answerSelected = mrButton.checked;
    an.subAnswersSelected = mrChange.value;
  }

}
