<b mat-dialog-title>ადრესატები</b>
<mat-dialog-content>

  <!--<searchFilterSSCC (onFilter)="Filter($event)" [config]="searchConfig">

  </searchFilterSSCC>-->
  <div class="example-loading-shade"
       *ngIf="isLoadingResults || isRateLimitReached">
    <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
    <div class="example-rate-limit-reached" *ngIf="isRateLimitReached">
      GitHub's API rate limit has been reached. It will be reset in one minute.
    </div>
  </div>
  <table cdk-table [dataSource]="entitiesDataSource" class="table table-bordered table-hover">
    <ng-container cdkColumnDef="select">
      <th cdk-header-cell *cdkHeaderCellDef>
        <mat-checkbox (change)="$event ? masterToggle() : null"
                      [checked]="selection.hasValue() && isAllSelected()"
                      [indeterminate]="selection.hasValue() && !isAllSelected()">
        </mat-checkbox>
      </th>
      <td cdk-cell *cdkCellDef="let row">
        <mat-checkbox (click)="$event.stopPropagation()"
                      (change)="$event ? selection.toggle(row) : null"
                      [checked]="selection.isSelected(row)">
        </mat-checkbox>
      </td>
    </ng-container>
    <!-- Position Column -->
    <ng-container cdkColumnDef="fName">
      <th cdk-header-cell *cdkHeaderCellDef> სახელი </th>
      <td cdk-cell *cdkCellDef="let element"> {{element.fName}} </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container cdkColumnDef="sName">
      <th cdk-header-cell *cdkHeaderCellDef> გვარი </th>
      <td cdk-cell *cdkCellDef="let element"> {{element.sName}} </td>
    </ng-container>

    <!-- Weight Column -->
    <ng-container cdkColumnDef="persNumber">
      <th cdk-header-cell *cdkHeaderCellDef> პირადი # </th>
      <td cdk-cell *cdkCellDef="let element"> {{element.persNumber}} </td>
    </ng-container>

    <!-- Symbol Column -->
    <ng-container cdkColumnDef="fakulty">
      <th cdk-header-cell *cdkHeaderCellDef> ფაკულტეტი </th>
      <td cdk-cell *cdkCellDef="let element"> {{element.fakulty}} </td>
    </ng-container>

    <tr cdk-header-row *cdkHeaderRowDef="displayedColumns"></tr>
    <tr cdk-row *cdkRowDef="let row; columns: displayedColumns;  let i = index" (dblclick)="StudentSelect(row,i)" [ngClass]="{'highlight': selectedRow == i}"></tr>
  </table>
  <!--<mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons #paginator [length]="resultsLength"></mat-paginator>-->
  <mat-paginator class="mat-paginator  _cs_shadow-primary-0-0--1 _cs_border-radius--sm mat-paginator" [pageSizeOptions]="[20, 50, 100]" showFirstLastButtons #paginator [length]="resultsLength"></mat-paginator>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button (click)=" RecipientsData()">გაგზავნა</button>
  <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->
  <button mat-button [mat-dialog-close]="true">გაუქმება</button>
</mat-dialog-actions>
