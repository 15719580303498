import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';


@Injectable()
export class CourseService {

  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  private headers2 = new HttpHeaders({ 'Content-Type': 'multipart/form-data' });
  private heroesUrl = '/';
  constructor(private http: HttpClient) { }

  GetAllLectureTypeListOnCourse(courseId:number): Promise<any> {

    return this.http.post("/api/Course/GetAllLectureTypeListOnCourse", courseId, { headers: this.headers })
      .toPromise()
      .then(response => response)
      .catch(this.handleError);
  }

  private handleError(error: any): Promise<any> {
    console.error('An error occurred', error); // for demo purposes only
    return Promise.reject(error.message || error);
  }
}
