import { CourseSelectData, LecturesForSelect } from "./CourseSelect";

export class StudentProfileData {
  public id: number;
  public courseId: number | null;
  public subjectId: number | null;
  public studentId: number | null;
  public sessionId: number | null;
  public semesterNumber: number | null;
  public lecturerList: Array<StudentProfileLectureList>;
  public subjectName: string;
  public credit: number;
  public yearName: string;
  public semName: string;
  public yearSemName: string;
  public enrollStatus: string;
  public subjectFrom: string;
  public sumMark: number;
  public subjectType: string;
  public enrollStatusId: number;
  public archiveStatusId: number;
  public gpa: number;
  public gradeSimbole: string;
  public subjectStatus: string;
}

interface StudentProfileLectureList {
  sName: string | null;
  fName: string | null;
  persNumber: string | null;
  personId: number;
  groupId: number;
  groupTypeCaption: string | null;
  groupTypeId: number;
}

export class StudentSelectedCourseGroup {
  public id: number;
  public lectureId: number;
  public lectureTypeId: number;
  public lectureCaption: string | null;
}

export class CoursesForSelect {
  public syllabusId: number;
  public courseId: number;
  public syllabusCaption: string;
  public subjectTypeCaption: string;
  public subjectStatus: string;
  public credit: number;
  public enrollEnableOnSyllabus: boolean;
  public enrollEnabledOnCourse: boolean;
  public preconditionComment: string;
  public syllabusList: Array<SyllabusForSubjectView>;
}
export class RetStudentsCeurseForSelect {
  public count: number;
  public result: Array<CoursesForSelect>;
}

export class StudSearchSubjectsParams {
  public faculty: number;
  public searchCourse: string;
  public studyProgram: number;
  public studentId: number;
  public index: number;
  public take: number;
}

export class SyllabusForSubjectView {
  public syllabusCaption: string;
  public fileId: string;
}
export class StudentCurrentSessionSelectedSubjects {
  public enrollId: number;
  public syllabusId: number;
  public subjectTypeCaption: string;
  public syllabusCaption: string;
  public syllabusList: Array<SyllabusForSubjectView>;
  public crdit: number;
  public courseId: number;
  public lectureList: Array<StudentProfileLectureList>;
}


export class StudentCurrentSessionSelectedSubjectsTimeTable {
  public enrollId: number;
  public syllabusId: number;
  public subjectTypeCaption: string;
  public syllabusCaption: string;
  public crdit: number;
  public timeTable: Array<StudentTimeTableOnLecture>;
}
export class StudentTimeTableOnLecture {
  public timetableId: number;
  public lectureId: number;
  public enrollId: number;
  public lectureTypeName: string;
  public syllabusName: string;
  public classRoomsId: number;
  public fullName: string;
  public dayName: string;
  public weekId: number;
  public startTime: string;
  public endTime: string;
  public date: string;
  public timeEquals: boolean;
  public remoteRoomDescription: string;
  public remoteRoomType: string;
}


export class Group {
  level = 0;
  parent: Group;
  expanded = true;
  totalCounts = 0;
  get visible(): boolean {
    return !this.parent || (this.parent.visible && this.parent.expanded);
  }

}

export class AddSubjectToStudentParameter {
  public studentProfileData: StudentProfileData;
  public operation: number;
  public studentId: number;
}


export class PostStudentLectureGroupFullDataParams {
  public studId: number;
  public courseId: number;
  public enrollId: number;
  public typeId: number;
}

