import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { StudentSelectedCourseGroup } from '../../Models/StudentProfile';
import { StudentEvaluationGenerator, FilterGroups } from '../../Models/Graids';



@Injectable()
export class GraideService {

  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  private headers2 = new HttpHeaders({ 'Content-Type': 'multipart/form-data' });
  private heroesUrl = '/';
  constructor(private http: HttpClient) { }


  
  GetStudentEvaluationListOnLecture(parameters: FilterGroups): Promise<StudentEvaluationGenerator> {
   
    return this.http.post("/api/StudentEvaluation/GetStudentEvaluationListOnLecture", parameters, { headers: this.headers })
      .toPromise()
      .then(response => response)
      .catch(this.handleError);
  }



  GetStudentSelectedGruops(enrollId: number): Promise<Array<StudentSelectedCourseGroup>> {
    
    return this.http.post("/api/StudentEvaluation/GetStudentSelectedGruops", enrollId, { headers: this.headers })
      .toPromise()
      .then(response => response)
      .catch(this.handleError);
  }


  private handleError(error: any): Promise<any> {
    console.error('An error occurred', error); // for demo purposes only
    return Promise.reject(error.message || error);
  }
}
