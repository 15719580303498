<!--{{ShowMenu()}}-->

<header class="_cs_size-w-percent--100 _cs_flex" *ngIf="showContent&&this.captions">

  <!--<a class="nav-link" href="/auth/logout"><i class="icon-off"></i></a>-->
  <nav class='header-nav _cs_size-w-percent--100  _cs_position-relative _cs_color-bg--primary-5 _cs_shadow-primary-0-0--2 navbar _cs_size-h-px--70
    navbar-expand-sm
    navbar-toggleable-sm'>
    <div class="_cs_size-w-percent--100 _cs_flex _cs_flex-a--center nav-wrap">
      <div class=" _cs_size-h-percent--100">
        <a class="_cs_logo _cs_flex _cs_flex-a--start _cs_p-sm _cs_color-bg--primary-6 _cs_border-radius--sm _cs_shadow-primary-0-0--3"
          [routerLink]='["/"]'>
          <img src="./logo-white.png" width="100" alt="" class="_cs_flex">
          <!-- Logo Text -->
          <!--<span class="text-uppercase font-weight-bold">EEU</span>-->
        </a>
      </div>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target=".navbar-collapse"
        aria-label="Toggle navigation" [attr.aria-expanded]="isExpanded" (click)="toggle()">
        <div id="nav-icon" class="dropsidebar">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </button>
      <div class="navbar-collapse collapse " [ngClass]='{"show": isExpanded}'>
        <ul class="navbar-nav flex-grow">
          <a [routerLink]='["/"]'
             class="_cs_hover  _cs_transition-0--2 _cs_p-left--xs _cs_p-right--xs _cs_p-down--sm _cs_p-top--sm _cs_m-right--xs  _cs_border-radius--lg _cs_hoveritem-border--primary-6 _cs_flex _cs_flex-a--center   _cs_border-transparent"
             [routerLinkActive]='["_cs_active"]' [routerLinkActiveOptions]='{ exact: true }'>
            <span class="_cs_color-primary-1 _cs_label _cs_label-md _cs_label-font-setting-case-on _cs_hover  _cs_transition-0--2  _cs_activeitem-color--primary-6 _cs_hoveritem-color--primary-6">
              <mat-icon aria-hidden="false" aria-label="Example home icon">home</mat-icon>
              <!--{{captions.mainPage}}-->
            </span>
          </a>
          <a [routerLink]='["/profile"]'
             class="_cs_hover  _cs_transition-0--2 _cs_p-left--xs _cs_p-right--xs _cs_p-down--sm _cs_p-top--sm _cs_m-right--xs  _cs_border-radius--lg _cs_hoveritem-border--primary-6 _cs_flex _cs_flex-a--center _cs_activeitem-shadow-primary-6-custom-0--5 _cs_activeitem-color-bg--primary-5 _cs_hoveritem-color-bg--primary-5 _cs_hoveritem-shadow-primary-6-custom-0--5  _cs_border-transparent"
             [routerLinkActive]='["_cs_active"]'>
            <span class="_cs_color-primary-1 _cs_label _cs_label-md _cs_label-font-setting-case-on _cs_hover  _cs_transition-0--2  _cs_activeitem-color--primary-6 _cs_hoveritem-color--primary-6">{{captions.profile}}</span>
          </a>
          <a [routerLink]='["/card"]'
             class="_cs_hover  _cs_transition-0--2 _cs_p-left--xs _cs_p-right--xs _cs_p-down--sm _cs_p-top--sm _cs_m-right--xs  _cs_border-radius--lg _cs_hoveritem-border--primary-6 _cs_flex _cs_flex-a--center _cs_activeitem-shadow-primary-6-custom-0--5 _cs_activeitem-color-bg--primary-5 _cs_hoveritem-color-bg--primary-5 _cs_hoveritem-shadow-primary-6-custom-0--5  _cs_border-transparent"
             [routerLinkActive]='["_cs_active"]'>
            <span class="_cs_color-primary-1 _cs_label _cs_label-md _cs_label-font-setting-case-on _cs_hover  _cs_transition-0--2  _cs_activeitem-color--primary-6 _cs_hoveritem-color--primary-6">{{captions.card}}</span>
          </a>
          <a [routerLink]='["/CurrentSemesterSubjectsForSelect"]'
             class="_cs_hover  _cs_transition-0--2 _cs_p-left--xs _cs_p-right--xs _cs_p-down--sm _cs_p-top--sm _cs_m-right--xs  _cs_border-radius--lg _cs_hoveritem-border--primary-6 _cs_flex _cs_flex-a--center _cs_activeitem-shadow-primary-6-custom-0--5 _cs_activeitem-color-bg--primary-5 _cs_hoveritem-color-bg--primary-5 _cs_hoveritem-shadow-primary-6-custom-0--5  _cs_border-transparent"
             [routerLinkActive]='["_cs_active"]'>
            <span class="_cs_color-primary-1 _cs_label _cs_label-md _cs_label-font-setting-case-on _cs_hover  _cs_transition-0--2  _cs_activeitem-color--primary-6 _cs_hoveritem-color--primary-6">{{captions.acadRegistration}}</span>
          </a>
          <a [routerLink]='["/AudExamSchedule"]'
             class="_cs_hover  _cs_transition-0--2 _cs_p-left--xs _cs_p-right--xs _cs_p-down--sm _cs_p-top--sm _cs_m-right--xs  _cs_border-radius--lg _cs_hoveritem-border--primary-6 _cs_flex _cs_flex-a--center _cs_activeitem-shadow-primary-6-custom-0--5 _cs_activeitem-color-bg--primary-5 _cs_hoveritem-color-bg--primary-5 _cs_hoveritem-shadow-primary-6-custom-0--5  _cs_border-transparent"
             [routerLinkActive]='["_cs_active"]'>
            <span class="_cs_color-primary-1 _cs_label _cs_label-md _cs_label-font-setting-case-on _cs_hover  _cs_transition-0--2  _cs_activeitem-color--primary-6 _cs_hoveritem-color--primary-6">{{captions.timeTable}}</span>
          </a>
          <a [routerLink]='["/StudyProgram"]'
             class="_cs_hover  _cs_transition-0--2 _cs_p-left--xs _cs_p-right--xs _cs_p-down--sm _cs_p-top--sm _cs_m-right--xs  _cs_border-radius--lg _cs_hoveritem-border--primary-6 _cs_flex _cs_flex-a--center _cs_activeitem-shadow-primary-6-custom-0--5 _cs_activeitem-color-bg--primary-5 _cs_hoveritem-color-bg--primary-5 _cs_hoveritem-shadow-primary-6-custom-0--5  _cs_border-transparent"
             [routerLinkActive]='["_cs_active"]'>
            <span class="_cs_color-primary-1 _cs_label _cs_label-md _cs_label-font-setting-case-on _cs_hover  _cs_transition-0--2  _cs_activeitem-color--primary-6 _cs_hoveritem-color--primary-6">{{captions.studyProgram}}</span>
          </a>
          <a [routerLink]='["/StudentFinanceMainForm"]'
             class="_cs_hover  _cs_transition-0--2 _cs_p-left--xs _cs_p-right--xs _cs_p-down--sm _cs_p-top--sm _cs_m-right--xs  _cs_border-radius--lg _cs_hoveritem-border--primary-6 _cs_flex _cs_flex-a--center _cs_activeitem-shadow-primary-6-custom-0--5 _cs_activeitem-color-bg--primary-5 _cs_hoveritem-color-bg--primary-5 _cs_hoveritem-shadow-primary-6-custom-0--5  _cs_border-transparent"
             [routerLinkActive]='["_cs_active"]'>
            <span class="_cs_color-primary-1 _cs_label _cs_label-md _cs_label-font-setting-case-on _cs_hover  _cs_transition-0--2  _cs_activeitem-color--primary-6 _cs_hoveritem-color--primary-6">{{captions.studentFinanceMainForm}}</span>
          </a>
          <a [routerLink]='["/correspondece"]'
             class="_cs_hover  _cs_transition-0--2 _cs_p-left--xs _cs_p-right--xs _cs_p-down--sm _cs_p-top--sm _cs_m-right--xs  _cs_border-radius--lg _cs_hoveritem-border--primary-6 _cs_flex _cs_flex-a--center _cs_activeitem-shadow-primary-6-custom-0--5 _cs_activeitem-color-bg--primary-5 _cs_hoveritem-color-bg--primary-5 _cs_hoveritem-shadow-primary-6-custom-0--5  _cs_border-transparent"
             [routerLinkActive]='["_cs_active"]'>
            <span class="_cs_color-primary-1 _cs_label _cs_label-md _cs_label-font-setting-case-on _cs_hover  _cs_transition-0--2  _cs_activeitem-color--primary-6 _cs_hoveritem-color--primary-6">{{captions.correspondece}}</span>
          </a>
        </ul>

      </div>
      <div class="_cs_m-left--auto _cs_flex _cs_flex-a--center nav-notif">
        <div *ngIf="correspondence">
          <button class="_cs_p-none-i _cs_size-w-min-auto--i _cs_p-left--xs _cs_p-right--xs" mat-button
                  [matMenuTriggerFor]="notifmenu ">
            <div class="_cs_flex">
              <i class="_cs_cursor-pointer _cs_icon _cs_icon-xl _cs_position-relative _cs_border-primary-6 _cs_m-left--xs _cs_m-right--xs _cs_color-primary-6 _cs_border-radius--full  _cs_shadow-primary-0-0--1">
                <!-- <div class="blob red"></div> -->
                <mat-icon matBadge={{correspondence.length}} matBadgeColor="warn" class="mat-badge-small" matBadgeSize="small">
                  mail_outline
                </mat-icon>
              </i>
            </div>
          </button>
          <mat-menu #notifmenu="matMenu">
            <button mat-menu-item *ngFor="let k of correspondence; let i=index;">
              <span class="_cs_label _cs_label-bold _cs_label-sm _cs_color-primary-2">{{k.correspondeceTypeCaption}}</span>
            </button>
          </mat-menu>
        </div>
        <div>
          <!--<button class="_cs_p-none-i _cs_size-w-min-auto--i _cs_p-left--xs _cs_p-right--xs" mat-button
                  [matMenuTriggerFor]="ontifmenu2 ">
            <div class="_cs_flex">
              <i class="_cs_cursor-pointer _cs_icon _cs_icon-xl _cs_position-relative _cs_border-primary-6 _cs_m-left--xs _cs_m-right--xs _cs_color-primary-6 _cs_border-radius--full  _cs_shadow-primary-0-0--1">-->
                <!-- <div class="blob red"></div> -->
                <!--<mat-icon matBadge="15" matBadgeColor="warn" class="mat-badge-small" matBadgeSize="small">
                  notifications_none
                </mat-icon>
              </i>
            </div>
          </button>-->
          <!--<mat-menu #ontifmenu2="matMenu">
            <button mat-menu-item>
              <span class="_cs_label _cs_label-bold _cs_label-sm _cs_color-primary-2">Notification</span>
            </button>
            <button mat-menu-item>
              <span class="_cs_label _cs_label-bold _cs_label-sm _cs_color-primary-2">Notification</span>
            </button>
            <button mat-menu-item>
              <span class="_cs_label _cs_label-bold _cs_label-sm _cs_color-primary-2">Notification</span>
            </button>
            <button mat-menu-item>
              <span class="_cs_label _cs_label-bold _cs_label-sm _cs_color-primary-2">Notification</span>
            </button>
          </mat-menu>-->
        </div>


        <!--notification /.-->
        <!--user-->

        <div class="_cs_flex _cs_flex-a--center nav-usr">
          <button mat-button [matMenuTriggerFor]="menu ">
            <div class="_cs_flex _cs_flex-a--center">
              <i
                class="_cs_icon _cs_icon-xxl _cs_border-radius--full _cs_color-bg--primary-3 _cs_shadow-primary-0-0--1 _cs_border-primary-5 _cs_overflow-hidden _cs_m-right--sm">
                <!--<img src="./avatar.jpg" class="_cs_size-w-percent--100" alt="">-->
                <ProfileImage *ngIf="profileImageFileStream" [fileId]="BehavRet(profileImageFileStream)"
                  [imageClass]="'_cs_size-w-percent--100'">

                </ProfileImage>
              </i>

              <!--<span class="_cs_label _cs_label-bold _cs_label-sm _cs_color-primary-6
                           _cs_label-font-setting-case-on">{{ userName}}</span>-->
              <i class="" class="material-icons _cs_color-primary-6">
                keyboard_arrow_down
              </i>
            </div>
          </button>
          <mat-menu #menu="matMenu">
            <div mat-menu-item class="_cs_label _cs_label-bold _cs_label-sm _cs_color-primary-6 _cs_label-font-setting-case-on">
              {{ userName}}
            </div>
            <button mat-menu-item (click)="ChangePassword()">
              <span class="_cs_label _cs_label-bold _cs_label-sm _cs_color-primary-2">{{captions.changePassword}}</span>
            </button>
            <button mat-menu-item>
              <span class="_cs_label _cs_label-bold _cs_label-sm _cs_color-primary-2" (click)="ChangeProfle()">{{captions.switchProfile}}</span>
            </button>
            <button mat-menu-item (click)="LogOut()">
              <span class="_cs_label _cs_label-bold _cs_label-sm _cs_color-primary-2">{{captions.logOut}}</span>
            </button>
          </mat-menu>
        </div>
        <!--user-->
      </div>
    </div>
  </nav>
</header>
