import { StudName } from "./StudAnketa";
import { OrderFiles } from "./Order";

export class ProfilePermission {
  public modulePermission: Array<ModulePermission>;
  public dataPermission: Array<DataPermission>;
}
export class ModulePermission {
  public rolesOnModuleId: number;
  public readData: boolean;
  public writeData: boolean;
  public appModulesId: number;
  public appModulesCaption: string;
  public appModuleTypeCaption: string;
}
export class DataPermission {
  public id: number;
  public facultyId: number;
  public studyLevelId: number;
  public directionId: number;
  public readData: boolean;
  public writeData: boolean;
  public facultyHeader: string;
  public studyLevelHeader: string;
  public programsDirectionsHeader: string;
}
export class Workers {
  public personalId: number;
  public workerStatus: string;
  public persCategory: string;
  public workerStatusId: number;
  public persCategoryId: number;
  public fakulty: string;
  public fakultyId: number;
  public affiliated: boolean;
}
export class PersonData {
  public personNumber: string;
  public birthDay: Date;
  public aspNetUserId: string;
  public sexId: number;
  public sex: string;
  public personId: number;
  public languageId: number;
  public languageCaption: string;
  public workers: Array<Workers>;
  public profilePermission: ProfilePermission;
  public nameList: Array<StudName>;
  public contactData: Array<PersonContactData>;
  public orders: Array<PersonOrders>;
  public files: Array<PersonDocument>;
  public editionAnketData: Array<PersonEditionAnketData>;
  public perConference: Array<PerConferenceP>;
  public perEducataion: Array<PerEducataionP>;
  public perPracticalExperience: Array<PerPracticalExperienceP>;
  public perProfesionGrowth: Array<PerProfesionGrowthP>;
  public perProjects: Array<PerProjectsP>;
  public perPublications: Array<PerPublicationsP>;
}

export class PersonDocument {
  public id: number;
  public personId: number;
  public studentId: number;
  public personalId: number;
  public fileFor: string;
  public fileForId: number;
  public fileStream_id: string;
  public fileName: string;
  public documentDescription: string;
}

export class PersonListData {
  public personId: number;
  public sName: string;
  public fName: string;
  public pn: string;
  public facultyCaption: string;
  public studyLevelCaption: string;
  public directionCaption: string;
  public staffStatus: string;
  public studentEducationStatus: string;
  public studentStatusId: number;
  public staffPosition: string;
  public staffPositionId: number;
  public asPersonalId: number;
  public asStudentId: number;
}
export class PersonOnDataContent {
  public personId: number;
  public contentId: number;
  public sName: string;
  public fName: string;
  public pn: string;
  public facultyCaption: string;
  public studyLevelCaption: string;
  public staffStatus: string;
  public studentEducationStatus: string;
  public staffPosition: string;
  public createDate: Date;
  public personalId: number;
  public studentId: number;
}

export class PersonOnDataContentWithDataCount {
  public personOnDataContentList: Array<PersonOnDataContent>;
  public count: number;
}


export interface PersonOrders {
  id: number;
  parentCategoryId: number;
  orderSubject: string;
  orderDescription: string;
  orderCategoryName: string;
  orderDate: Date;
  orderNumber: string;
  fileList: Array<OrderFiles>;

}

export interface PersonContactData {
  id: number;
  language: string;
  languageId: number;
  categoryName: string;
  categoryId: number;
  description: string;
}

export class UserPerson {
  public aspNetUserId: string;
  public personalId: number;
  public personId: number;
  public fname: string;
  public sname: string;
  public piradiNom: string;
  public persCategory: string;
  public workerStatus: string;
  public facultyDepartment: string;
  public birthDay: Date;
  public fileId: string;
  public mobile: string;
  public email: string;
  public affiliated: boolean;
}


export class ContactTypeGroupCategory {
  public id: number;
  public caption: string;
  public disabled: boolean;
  public contactTypeCategories: Array<ContactTypeCategory>;
}
export class ContactTypeCategory {
  public id: number;
  public caption: string;
  public disabled: boolean;
  public parentId: number;
}


export class PersonEditionAnketData {
  public id: number;
  public personId: number;
  public dataTypeId: number;
  public dataEnterTypeId: number;
  public dataTypeCaption: string;
  public elementCaption: string;
  public elementId: number;
  public boolAnswer: boolean;
  public description: string;
  public evaluateId: number;
  public evaluateCaption: string;
  public evaluateEnabled: boolean;
}


export class PerConferenceP {
  public id: number;
  public personId: number;
  public languageCaption: string;
  public languageId: number;
  public conferenceName: string;
  public placeName: string;
  public startTime: Date;
  public role: string;
  public theme: string;
  public comment: string;
}
export class PerEducataionP {
  public id: number;
  public personId: number;
  public languageId: number;
  public languageCaption: string;
  public studyLevel: number;
  public studyLevelCaption: string;
  public assignDate: Date;
  public documentNumber: string;
  public givingDate: Date;
  public theme: string;
  public sphere: string;
  public classification: string;
  public receiverOrganisation: string;
  public comment: string;
}
export class PerPracticalExperienceP {
  public id: number;
  public personId: number;
  public languageId: number;
  public languageCaption: string;
  public organisation: string;
  public positionName: string;
  public functionsName: string;
  public startTime: Date;
  public endTime: Date;
  public comment: string;
}
export class PerProfesionGrowthP {
  public id: number;
  public personId: number;
  public languageId: number;
  public languageCaption: string;
  public activity: string;
  public organisation: string;
  public startTime: Date;
  public endTime: Date;
  public comment: string;
}
export class PerProjectsP {
  public id: number;
  public personId: number;
  public languageId: number;
  public languageCaption: string;
  public projectName: string;
  public projectshortDescription: string;
  public implementationPlace: string;
  public startTime: Date;
  public endTime: Date;
  public comment: string;
}
export class PerPublicationsP {
  public id: number;
  public personId: number;
  public languageId: number;
  public languageCaption: string;
  public workName: string;
  public publishingHouse: string;
  public publicationName: string;
  public typeName: string;
  public coAuthor: string;
  public publicDate: Date;
  public comment: string;
}


export class AdminUserData {
  public personId: number;
  public interfaceLanguage: number;
  public sName: string;
  public fName: string;
  public userRoles: Array<UserRole>;
  public userRolesOnData: Array<UserRolesOnData>;
}
export class UserRole {
  public moduleId: number;
  public readData: boolean;
  public writeData: boolean;
}
export class UserRolesOnData {
  public studyLevelId: number;
  public facultyId: number;
  public directionId: number;
  public readData: boolean;
  public writeData: boolean;
}

export class RetProfileList {
  public personData: Array<UserPerson>
  public count: number;
}

export class PasswordChange {
  public personId: number;
  public newPassword: string | null;
  public oldPassword: string | null;
  public userType: number;
}


export class ResetPasswordData {
  public persNumber: string;
  public email: string;
  public onTimeCode: string;
  public newPassword: string;
}

export class ChangePasswordData {
  public currentPassword: string;
  public newPassword: string;
}
