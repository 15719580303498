import { FlatTreeControl } from '@angular/cdk/tree';
import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { Caption, PortalLanguageCaption } from '../../LanguageModel/Caption';
import { FileUploadReturn } from '../../Models/file';
import { EditCell } from '../../Models/FIltering';
import { FilterPersone, FilterSubjectSylabusCourseEduPrograms, UIfilter } from '../../Models/filters';
import { Language } from '../../Models/language';
import { ContactTypeGroupCategory, PerConferenceP, PerEducataionP, PerPracticalExperienceP, PerProfesionGrowthP, PerProjectsP, PerPublicationsP, PersonEditionAnketData } from '../../Models/Profile';
import { ReturnResultValue } from '../../Models/ResultReturnParameters';
import { StudAnketaData, StudentStudyPrograms } from '../../Models/StudAnketa';
import { ProfileService } from '../Service/ProfileService';
import { SharedService } from '../Service/SharedService';




@Component({
  selector: 'profile',
  templateUrl: 'Profile.html',
  styleUrls: ['Profile.css']
})



export class Profile implements OnInit {

  profileItemNumber: number = 1;
  activeNode: any;
  hasChild = (_: number, node: ExampleFlatNode) => node.expandable;
  private _transformer = (node: ProfileItem, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.name,
      level: level,
      id: node.id,
      icon: node.icon,
      haveChildren: node.children != null && node.children.length>0 ? true : false
    };
  }

  treeControl = new FlatTreeControl<ExampleFlatNode>(
    node => node.level, node => node.expandable);

  treeFlattener = new MatTreeFlattener(
    this._transformer, node => node.level, node => node.expandable, node => node.children);

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  studAnketaData: StudAnketaData;
  contactTypeGroupCategorie: Array<ContactTypeGroupCategory>
  nameRowindex: number;
  nameRowId: number;
  languageList: any;
  languageListFiltered: any;
  fileForList: any;
  selectedFileFor: any;
  sexList: any;
  editionDataTypeList: any;
  editionDataElementList: any;
  selectedEnterType: any;
  evaluationTypes: any;
  studylevelList: any;
  facultyList: any;
  studyProgramList: any;
  editCell: EditCell = { rowId: 0, cellValue: "" };
  languageId: number=0;
  public captions: Caption;
  constructor(
    private activatedRoute: ActivatedRoute,
    private SharedService: SharedService,
    //private http: HttpClient, @Inject('BASE_URL') private baseUrl: string,
    private ProfileService: ProfileService,
    private route: ActivatedRoute,
    //private SharedService: SharedService,
    private location: Location,
    private router: Router, private _snackBar: MatSnackBar) {
 

  }
  status: boolean = false;
  clickEvent() {
    this.status = !this.status;
  }
  ngOnInit() {


    this.ProfileService.StudentAnkData().then(e => { this.studAnketaData = e as StudAnketaData; this.GetPhoto(); });

    this.captions = PortalLanguageCaption[parseInt(localStorage.getItem("language")) - 1];
    this.languageId = parseInt(localStorage.getItem("language"));
    let pIt: Array<ProfileItem> = new Array<ProfileItem>(6);
    pIt[0] = new ProfileItem();
    pIt[1] = new ProfileItem();
    pIt[2] = new ProfileItem();
    //pIt[3] = new ProfileItem();
    pIt[4] = new ProfileItem();
    pIt[5] = new ProfileItem();

    pIt[0].id = 1;
    pIt[0].name = this.captions.anketa;
    pIt[0].icon = "_cs_icon-menu-card";
    pIt[1].id = 2;
    pIt[1].name = this.captions.contactInformation;
    pIt[1].icon = "_cs_icon-menu-contact";
    pIt[2].id = 3;
    pIt[2].name = this.captions.educationActivities;
    pIt[2].icon = "_cs_icon-menu-education-activities";
    pIt[2].children = new Array<ProfileItem>(7);
    pIt[2].children[0] = new ProfileItem();
    pIt[2].children[1] = new ProfileItem();
    pIt[2].children[2] = new ProfileItem();
    pIt[2].children[3] = new ProfileItem();
    pIt[2].children[4] = new ProfileItem();
    pIt[2].children[5] = new ProfileItem();
    pIt[2].children[6] = new ProfileItem();

    pIt[2].children[0].id = 31;
    pIt[2].children[0].name = this.captions.education;
    pIt[2].children[0].icon = "room";
    pIt[2].children[1].id = 32;
    pIt[2].children[1].name = this.captions.conferences;
    pIt[2].children[1].icon = "room";
    pIt[2].children[2].id = 33;
    pIt[2].children[2].name = this.captions.publications;
    pIt[2].children[2].icon = "room";
    pIt[2].children[3].id = 34;
    pIt[2].children[3].name = this.captions.professionalDevelopment;
    pIt[2].children[3].icon = "room";
    pIt[2].children[4].id = 35;
    pIt[2].children[4].name = this.captions.practicalExperience;
    pIt[2].children[4].icon = "room";
    pIt[2].children[5].id = 36;
    pIt[2].children[5].name = this.captions.projects;
    pIt[2].children[5].icon = "room";
    pIt[2].children[6].id = 37;
    pIt[2].children[6].name = this.captions.transferSkills;
    pIt[2].children[6].icon = "room";
    //pIt[3].id = 4;
    //pIt[3].name = "სასწავლო პროგრამები";
    //pIt[3].icon = "_cs_icon-menu-training-programs";
    pIt[4].id = 5;
    pIt[4].name = this.captions.orders;
    pIt[4].icon = "_cs_icon-menu-order";
    pIt[5].id = 6;
    pIt[5].name = this.captions.documents;
    pIt[5].icon = "_cs_icon-menu-order";
    this.dataSource.data = pIt;
    //  this.SharedService.GetFakultyList().then(e => { this.facultyList = e; });


    this.SharedService.GetLanguage().then(e => { this.languageList = e });
    this.SharedService.GetFileFor().then(e => { this.fileForList = e });
    this.SharedService.GetContactCategories().then(e => { this.contactTypeGroupCategorie = e as Array<ContactTypeGroupCategory> });
    //this.SharedService.GetSex().then(e => {
    //  this.sexList = e;
    //});
    this.SharedService.GetEditionDataTypes().then(e => { this.editionDataTypeList = e });
    this.SharedService.GetStudyLevelList().then(e => { this.studylevelList = e });
  }



  setClickedNameRow(i: any, id: any): void {
    this.nameRowindex = i;
    this.nameRowId = id;
  }



  FilterLanguages(language: Language[], item: any, current: any): void {
    let ar = new Array<Language>();
    for (let i = 0; i < language.length; i++) {
      let aris = true;
      for (let it = 0; it < item.length; it++) {
        if (item[it].languageId == language[i].id) {
          aris = false;
        }
      }

      if (aris || language[i].id == current.languageId) {
        ar.push(language[i]);
      }

    }
    this.languageListFiltered = ar;
  }

  toggleCaption(id: EditCell, current: any) {
    this.editCell = id;

    if (id.cellValue == "studentNameLaguage") {

      this.FilterLanguages(this.languageList, this.studAnketaData.studentName, current);
    }
    if (id.cellValue == "studyProgramName") {
      let fl = new UIfilter();
      fl.FilterPersone = new FilterPersone();
      fl.FilterSubjectSylabusCourseEduPrograms = new FilterSubjectSylabusCourseEduPrograms();
      fl.FilterSubjectSylabusCourseEduPrograms.facultyId = this.studAnketaData.facultyId;
      fl.FilterSubjectSylabusCourseEduPrograms.studyLevelId = this.studAnketaData.studyLevelId;
    //  this.StudyProgramsService.GetStudyProgramsList(fl).then(e => { this.studyProgramList = e });
    }

  }

  LoadEditionDataElementList(id: number) {
    this.SharedService.GetEditionDataList(id).then(e => { this.editionDataElementList = e });
    this.SharedService.GetEditionAnketDataEvaluationTypes(id).then(e => { this.evaluationTypes = e });
  }

  RemoveCRaw(dataListName: string, row: any): void {
    if (confirm("გინდათ წაშალოთ ჩანაწერი!")) {
      if (dataListName == "studentName") {
        var index = this.studAnketaData.studentName.indexOf(row);
        this.studAnketaData.studentName.splice(index, 1);
      } else if (dataListName == "contact") {
        var index = this.studAnketaData.personContactData.indexOf(row);
        this.studAnketaData.personContactData.splice(index, 1);
      } else if (dataListName == "documentFile") {
        var index = this.studAnketaData.files.indexOf(row);
        this.studAnketaData.files.splice(index, 1);
        this.SaveStudentData();
      } else if (dataListName == "anket2") {
        var index = this.studAnketaData.editionAnketData.indexOf(row);
        this.studAnketaData.editionAnketData.splice(index, 1);
      } else if (dataListName == 'perEducataion') {
        var index = this.studAnketaData.perEducataion.indexOf(row);
        this.studAnketaData.perEducataion.splice(index, 1);
      } else if (dataListName == 'perConference') {
        var index = this.studAnketaData.perConference.indexOf(row);
        this.studAnketaData.perConference.splice(index, 1);
      }
      else if (dataListName == 'perPracticalExperience') {
        var index = this.studAnketaData.perPracticalExperience.indexOf(row);
        this.studAnketaData.perPracticalExperience.splice(index, 1);
      }
      else if (dataListName == 'perPublications') {
        var index = this.studAnketaData.perPublications.indexOf(row);
        this.studAnketaData.perPublications.splice(index, 1);
      }
      else if (dataListName == 'perProfesionGrowth') {
        var index = this.studAnketaData.perProfesionGrowth.indexOf(row);
        this.studAnketaData.perProfesionGrowth.splice(index, 1);
      }
      else if (dataListName == 'perProjects') {
        var index = this.studAnketaData.perProjects.indexOf(row);
        this.studAnketaData.perProjects.splice(index, 1);
      } else if (dataListName == 'Program') {
        var index = this.studAnketaData.studentPrograms.indexOf(row);
        this.studAnketaData.studentPrograms.splice(index, 1);
      }
    }

  }
  addCRow(dataListName: string): void {
    if (dataListName == "lecturers") {
      //this.syllabusData.lecturers.push({ personId: 0, sName: "", fName: "" } as Lecturer);
    }
    else if (dataListName == "studentName") {
      if (this.studAnketaData.studentName.length < this.languageList.length) {

        this.studAnketaData.studentName.push({ languageId: null, id: null, sName: null, fName: null, language: null, pName: null });
      }
    } else if (dataListName == "contact") {
      this.studAnketaData.personContactData.push({ languageId: null, language: "", categoryId: null, categoryName: "", description: "", id: null });
    } else if (dataListName == "perEducataion") {
      let v = new PerEducataionP();
      this.studAnketaData.perEducataion.push(v);
    } else if (dataListName == 'perConference') {
      let v = new PerConferenceP();
      this.studAnketaData.perConference.push(v);
    } else if (dataListName == 'perPracticalExperience') {
      let v = new PerPracticalExperienceP();
      this.studAnketaData.perPracticalExperience.push(v);
    }
    else if (dataListName == 'perPublications') {
      let v = new PerPublicationsP();
      this.studAnketaData.perPublications.push(v);
    }
    else if (dataListName == 'perProfesionGrowth') {
      let v = new PerProfesionGrowthP();
      this.studAnketaData.perProfesionGrowth.push(v);
    }
    else if (dataListName == 'perProjects') {
      let v = new PerProjectsP();
      this.studAnketaData.perProjects.push(v);
    }
    else if (dataListName == "Program") {
      let v = new StudentStudyPrograms();
      this.studAnketaData.studentPrograms.push(v);
    }



  }

  addAnk2Data(enableEvalution: boolean) {

    let pr = new PersonEditionAnketData();
    pr.evaluateEnabled = enableEvalution;
    this.studAnketaData.editionAnketData.push(pr);

  }
  
  FileUploaded(file: FileUploadReturn) {
    this.studAnketaData.files.push({
      fileStream_id: file.fileId, id: null,
      fileForId: this.selectedFileFor.id, fileFor: this.selectedFileFor.caption, personalId: null, personId: null, studentId: null, fileName: file.fileName, documentDescription:""
    });
    this.SaveStudentData();
    //alert(fileId);
  }
  FileUploadedProfileImage(file: FileUploadReturn) {
    this.studAnketaData.files.push({
      fileStream_id: file.fileId, id: null,
      fileForId: 1, fileFor: "AnketPhoto", personalId: null, personId: null, studentId: null, fileName: file.fileName, documentDescription:""
    });
    this.SaveStudentData();
  }


  SaveStudentData(): void {
    this.ProfileService.UpdateStudentAnkByStudentData(this.studAnketaData).then(st => {
      if ((st as ReturnResultValue).seccuss) {
        this.ProfileService.StudentAnkData().then(e => {
        this.studAnketaData = e as StudAnketaData; this.GetPhoto();
          this._snackBar.open("ანკეტური მონაცემები", "მონაცემები განახლდა!", {
            duration: 2000,
          });
        });
      }
      else {
        this._snackBar.open("ანკეტური მონაცემები", "მოხდა შეცდომა!", {
          duration: 2000,
        });
      }

    });
  }
  DownloadDocument(fileId: string) {
    this.SharedService.downloadFile(fileId);
  }

  BehavRet(): BehaviorSubject<string> {
    return new BehaviorSubject(this.selectedPhoto);
  }

  selectedPhoto: string;
  GetPhoto(): void {
    for (let i = 0; i < this.studAnketaData.files.length; i++) {
      if (this.studAnketaData.files[i].fileForId == 1) {
        //alert(this.userAnketaData.files[i].fileStream_id);
        this.selectedPhoto = this.studAnketaData.files[i].fileStream_id;
      }
    }
  }

  FilterItem(node: ExampleFlatNode) {
    console.log(node);
    if (!node.haveChildren) {
      this.profileItemNumber = node.id;
    }
  }
}


export class ProfileItem {
  public id: number;
  public name: string;
  public icon: string;
  public children?: ProfileItem[];
}

interface ExampleFlatNode {
  expandable: boolean;
  name: string;
  level: number;
  id: number;
  icon: string;
  haveChildren: boolean
}
