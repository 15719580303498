import { Component, Inject,  ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

import { MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { FormGroup, UntypedFormBuilder, FormControl } from '@angular/forms';



@Component({
  selector: 'AlertOne',
  templateUrl: 'AlertOne.html',
  styleUrls: ['AlertOne.css']//,
  // providers: [ChecklistDatabase]
})



export class AlertOne {
  

  

  constructor(public dialog: MatDialog,
    public dialogRef: MatDialogRef<AlertOne>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private activatedRoute: ActivatedRoute,
    //private SharedService: SharedService,
    private route: ActivatedRoute,
    private location: Location,
    //private StudListService: StudListService,
    private router: Router, private fb: UntypedFormBuilder) {
  }
  ngOnInit() {
   


  }

  
}
