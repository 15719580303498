<div class="_cs_flex _cs_size-w-percent--100">
  <div class="_cs_size-w-percent--100 _cs_p-left--lg _cs_p-right--lg _cs_p-down--lg">
    <div class="_cs_size-h-percent--100 sidebarbg _cs_flex">
      <div class="sidebarbg__wrapper _cs_p-left--none-i _cs_size-w-percent--100 _cs_flex _cs_flex-d--column">
        <div class="_cs_m-down--md _cs_m-top--xxl">
          <div
            class="_cs_p-xs _cs_shadow-primary-0-0--1 _cs_border-radius--sm _cs_border-down--primary-6 _cs_flex _cs_flex-a--center">
            <span
              class="_cs_icon _cs_icon-xl _cs_shadow-primary-6-0--2 _cs_border-radius--full _cs_border--primary-6 _cs_m-right--md">
              <span class="_cs_icon _cs_icon-md _cs_color-primary-6 _cs_icon-curriculum"></span>
            </span> <span class="_cs_label _cs_label-sm _cs_label-font-setting-case-on _cs_label-bold
                _cs_color-primary-6">{{captions.studyProgram}}</span>
          </div>
        </div>
        <div *ngIf="data" class="">
          <div class="_cs_flex _cs_flex-j--center cs_size-w-precent--100 _cs_border-radius--xxl _cs_shadow-primary-0-0--1
          _cs_color-bg--primary-5 _cs_m-down--lg _cs_p-left--md _cs_p-right--md">
            <div class="_cs_flex _cs_mobile-flex-d _cs_mobile-m-down-d _cs_flex-a--center _cs_col-xs-12 ">
              <mat-form-field class="_cs_size-w-percent--100 _cs_p-md _cs_p-down--none" *ngIf="progarmSpecializationList">
                <mat-select id="program" placeholder={{captions.chooseSpecialization}} [(ngModel)]="selectedSpecialization">
                  <mat-option *ngFor="let ls of progarmSpecializationList" [value]="ls.id">{{ls.caption}}</mat-option>
                </mat-select>
              </mat-form-field>
              <div class="">
                <button class="_cs_button _cs_button-border--positive _cs_button-sm _cs_m-left--sm"
                        (click)="SelectSpecialization()">
                  <span class="_cs_label _cs_label-sm _cs_label-font-setting-case-on _cs_label-500 _cs_label-t-u _cs_color-primary-6">{{captions.select}}</span>
                </button>
              </div>
            </div>
          </div>
          <div class="_cs_p-xs _cs_shadow-primary-0-0--1 _cs_border-radius--sm _cs_border-down--primary-6 _cs_flex _cs_flex-a--center _cs_m-down--lg">
            <span class="_cs_icon _cs_icon-xl _cs_shadow-primary-6-0--2 _cs_border-radius--full _cs_border--primary-6 _cs_m-right--md">
              <span class="_cs_icon _cs_icon-md _cs_color-primary-6 _cs_icon-curriculum-description"></span>
            </span>
            <span class="_cs_label _cs_label-md _cs_label-u _cs_label-font-setting-case-on _cs_color-primary-6">
              {{captions.curriculumDescription}}

            </span>

            <!--<div class="_cs_m-left--auto">
      <button class="_cs_button _cs_button-border--positive _cs_button-sm" (click)="collapseAll()">
        <i class="_cs_m-right--sm" class="material-icons _cs_color-primary-6">
          keyboard_arrow_up
        </i>
        <span class="_cs_label _cs_label-sm _cs_color-primary-6">
          Collapse
          All
        </span>
      </button>
      <button class="_cs_button _cs_button-border--positive _cs_button-sm _cs_m-left--sm" (click)="expandAll()">
        <i class="_cs_m-right--sm" class="material-icons _cs_color-primary-6">
          keyboard_arrow_down
        </i>
        <span class="_cs_label _cs_label-sm _cs_color-primary-6">Expand All</span>
      </button>

    </div>-->
          </div>
          <!--<db-angular-tree-grid class="_cs_size-w-percent--100 _cs_child-table" #angularGrid [data]="data"
                        [configs]="configs">
  </db-angular-tree-grid>-->
         
          <ejs-treegrid [frozenColumns]='1' [allowPaging]='true' [pageSettings]='pageSettings' id="TreeGridControl"
                        [dataSource]="data" [treeColumnIndex]="treeColumnIndex" idMapping="id"
                        parentIdMapping="parentId" enableCollapseAll="true" gridLines="Both" [allowResizing]="true">
            <e-columns>
              <e-column field='caption' [headerText]='captions.caption' width='450'></e-column>
              <e-column field='status' [headerText]='captions.status'></e-column>
              <e-column field='structType' [headerText]='captions.structType'></e-column>
              <e-column field='recomendedSemester' [headerText]='captions.recomendedSemester'></e-column>
              <e-column field='studySemester' [headerText]='captions.semName'></e-column>
              <e-column field='minDoneCredits' [headerText]='captions.creditForDone'></e-column>
              <e-column field='doneCreditsByStudent' [headerText]='captions.leftCredits'>
                <ng-template #template let-data>
                  {{data.done==true?0: data.minDoneCredits- data.doneCreditsByStudent}}
                  <!--<img *ngIf="!data.done" src="/bullet_ball_red (2).png" title="ჩასაბარებელი" />-->
                </ng-template>
              </e-column>
              <e-column width='70'>
                <ng-template #template let-data>
                  <img *ngIf="data.done==true" src="/bullet_ball_green (3).png" title={{captions.done}} />
                  <!--<img *ngIf="!data.done" src="/bullet_ball_red (2).png" title="ჩასაბარებელი" />-->
                </ng-template>
              </e-column>

            </e-columns>
          </ejs-treegrid>

        </div>


      </div>
    </div>
  </div>
</div>
