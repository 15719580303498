import { SelectionModel } from '@angular/cdk/collections';
import { Location } from '@angular/common';
import { Component, Inject, ViewChild } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { PersoneListDialog } from '../../../Components/Dialogs/PersonListDialog/PersoneListDialog';
import { Caption, PortalLanguageCaption } from '../../../LanguageModel/Caption';
import { CorrespondeceTypesData, CorrespondencAttachment, CorrespondenceDataWithAttachmentSend, CorrespondenceUsers, CorrespondencStatement } from '../../../Models/Correspondence';
import { FileUploadReturn } from '../../../Models/file';
import { StudentCurrentSessionSelectedSubjects } from '../../../Models/StudentProfile';
import { StudentsListData } from '../../../Models/StudentsList';
import { RegistrationService } from '../../Service/RegistrationService';
import { SharedService } from '../../Service/SharedService';
import { CorrespondenceService } from '../Service/CorrespondenceService';




@Component({
  selector: 'CreateViewLetterDocument',
  templateUrl: 'CreateViewLetterDocument.html',
  styleUrls: ['CreateViewLetterDocument.css']//,
 // providers: [HtmlEditorService, ToolbarService, QuickToolbarService, ImageService, LinkService, CountService]
})



export class CreateViewLetterDocument  {

  correspondenceType: Array<CorrespondeceTypesData>;
  importantStatus: any;
  corState: any;
  selectedCoreType: CorrespondeceTypesData;
  selectedImportentStatus: any;
  selectedCorState: any;
  letterContent: any = "";
  answerletterContent: any = "";
  subjectText: any;
  selectedCourseId: number;
  correspondencStatementList: Array<CorrespondencStatement>;
  correspondencAttachments: Array<CorrespondencAttachment>;
  studentCurrentSessionSelectedSubjects: Array<StudentCurrentSessionSelectedSubjects>;
  public captions: Caption;
  constructor(public dialog: MatDialog,
    public dialogRef: MatDialogRef<CreateViewLetterDocument>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private activatedRoute: ActivatedRoute,
    private SharedService: SharedService,
    private CorrespondenceService: CorrespondenceService,
    private StudListService: RegistrationService,
    private route: ActivatedRoute,
    private location: Location,
    private router: Router, private fb: UntypedFormBuilder) {
    this.captions = PortalLanguageCaption[parseInt(localStorage.getItem("language")) - 1];
  }
 
  selection = new SelectionModel<StudentsListData>(true, []);

  public onReady(editor) {
    editor.ui.getEditableElement().parentElement.insertBefore(
      editor.ui.view.toolbar.element,
      editor.ui.getEditableElement()
    );
  }

  displayedColumns: string[] = ['fName', 'sName', 'persNumber', 'fakulty'];
  dataSource = new MatTableDataSource<StudentsListData>(this.selection.selected);

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;



  LoadCurrentSemesterSubject(corType: any): void {
    console.log(corType);
    if (this.studentCurrentSessionSelectedSubjects == null && corType.studyCourseStudentSet == true) {
      this.StudListService.GetStudentCurrentSessionSelectedCourses().then(e => { this.studentCurrentSessionSelectedSubjects = e as Array<StudentCurrentSessionSelectedSubjects>; });
    }
  }


  ngOnInit() {
    this.dataSource.paginator = this.paginator;
    this.correspondencAttachments = new Array<CorrespondencAttachment>();
    if (this.data.formType == 1 || this.data.formType==2) {
      this.CorrespondenceService.GetCorrespondeceImportantStatus().then(e => { this.importantStatus = e; });
    }
    if (this.data.formType == 1) {
      this.CorrespondenceService.GetCorrespondeceTypes().then(e => { this.correspondenceType = e; });
      
    }
    else if (this.data.formType == 2 || this.data.formType == 3) {
      if (this.data.formType == 2) {
        this.CorrespondenceService.GetCorState().then(e => { this.corState = e; });
      }
      this.correspondencStatementList = this.data.recipientsList as Array<CorrespondencStatement>;
      if (this.correspondencStatementList.length > 1) {


      } else if (this.correspondencStatementList.length == 1) {
        this.CorrespondenceService.GetCorrespondence(this.correspondencStatementList[0].coresspondenceId).then(e => {
          if (this.data.formType == 2) {
            this.subjectText = "Re:" + e.correspondenceData.correspondenceSubject;
            this.letterContent = e.correspondenceData.correspondenceDescription;
            this.selectedCorState = e.correspondenceData.stateId;
            this.correspondencAttachments = e.correspondencAttachments;
          } else if (this.data.formType == 3) {
            this.subjectText = e.correspondenceData.correspondenceSubject;
            this.letterContent = e.correspondenceData.correspondenceDescription;
            this.selectedCorState = e.correspondenceData.stateId;
            this.correspondencAttachments = e.correspondencAttachments;
          }

        });
      }
      else {
        
      }
    }
  }

  AddRecipients(): void {
    let dialogRef = this.dialog.open(PersoneListDialog, {
      panelClass: 'my-centered-dialog',
      height: '80%',
      width: '80%',
      disableClose: true,
      data: null
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      this.selection = result;
      this.dataSource.data=this.selection.selected;
      
    });
  }

  allSend = true;
  SendLetter(): void {
    if (this.allSend) {
      this.allSend = false;
      if (this.data.formType == 1 || this.data.formType == 2) {
        let c = new CorrespondenceDataWithAttachmentSend();
        if (this.data.formType == 1) {
          c.correspondenceData = {
            correspondeceTypeId: this.selectedCoreType.id, importantId: this.selectedImportentStatus,
            stateId: 0, correspondenceSubject: this.subjectText,
            correspondenceDescription: this.letterContent, fromCourseId: this.selectedCourseId, courseName:""
          };
        } else if (this.data.formType == 2) {
          if (this.correspondencStatementList.length == 1) {
            c.correspondenceData = {
              correspondeceTypeId: this.correspondencStatementList[0].correspondeceTypeId, importantId: this.selectedImportentStatus,
              stateId: 0, correspondenceSubject: this.subjectText,
              correspondenceDescription: this.answerletterContent, fromCourseId: this.selectedCourseId, courseName: ""
            };
          } else if (this.correspondencStatementList.length > 1) {
            c.correspondenceData = {
              correspondeceTypeId: this.correspondencStatementList[0].correspondeceTypeId, importantId: this.selectedImportentStatus,
              stateId: 0, correspondenceSubject: this.subjectText,
              correspondenceDescription: this.answerletterContent, fromCourseId:  this.selectedCourseId, courseName: ""
            };
          }
        }
        c.users = new Array<CorrespondenceUsers>();
        c.correspondencAttachments = this.correspondencAttachments;
        if (this.data.formType == 1) {
          for (let i = 0; i < this.selection.selected.length; i++) {
            c.users.push({ asStudentId: this.selection.selected[i].studId, personeId: this.selection.selected[i].personId, asPersonalId: null, parentId: null });
          }
        }
        else if (this.data.formType == 2) {
          for (let i = 0; i < this.correspondencStatementList.length; i++) {
            c.users.push({ asStudentId: this.correspondencStatementList[i].asStudentId, personeId: this.correspondencStatementList[i].personId, asPersonalId: this.correspondencStatementList[i].asPersonalId, parentId: this.correspondencStatementList[i].coresspondenceId });
          }
        }
        this.CorrespondenceService.SendCorrespondence(c).then(e => {
          if (e.seccuss) {
            alert(this.captions.messageSendSeccuss);
          }
          else {
            alert("მოხდა შეცდომა!");
          }
          this.dialogRef.close();
        });
      }
    }
  }


  DownloadDocument(fileId: string) {
    this.SharedService.downloadFile(fileId);
  }

  FileUploaded(file: FileUploadReturn) {
    this.correspondencAttachments.push({ fileId: file.fileId, corespondenceId: null, fileName: file.fileName });
    //alert(fileId);
  }
  RemoveFile(f: CorrespondencAttachment) {
    var index = this.correspondencAttachments.indexOf(f);
    this.correspondencAttachments.splice(index, 1);
  }


}

