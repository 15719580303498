import { Component, Inject, OnInit, ChangeDetectionStrategy, EventEmitter, Output } from '@angular/core';


import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
//import { Observable } from 'rxjs/Observable';
//import { Subject } from 'rxjs/Subject';
import { HttpClient } from '@angular/common/http';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { FileUploadReturn } from '../../Models/file';
import { SharedService } from '../../Student/Service/SharedService';
import { Caption, PortalLanguageCaption } from '../../LanguageModel/Caption';



@Component({
  selector: 'FileUpload',
  templateUrl: 'FileUpload.html',
  styleUrls: ['FileUpload.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class FileUpload implements OnInit {
  form: UntypedFormGroup;
  error: string;
  userId: number = 1;
  uploadResponse = { status: '', message: '', filePath: '' ,fileId:''};
  @Output() onFileUpload = new EventEmitter<FileUploadReturn>();
  public captions: Caption;
  constructor(
    private activatedRoute: ActivatedRoute,
    //private http: HttpClient, @Inject('BASE_URL') private baseUrl: string,
    private route: ActivatedRoute,
    private SharedService: SharedService,
    private location: Location,
    private router: Router, private formBuilder: UntypedFormBuilder) {
    this.captions = PortalLanguageCaption[parseInt(localStorage.getItem("language")) - 1];

  }
  ngOnInit() {
    this.form = this.formBuilder.group({
      avatar: ['']
    });
  }

  onFileChange(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.form.get('avatar').setValue(file);
    }
  }

  onSubmit() {
    
    const formData = new FormData();
    formData.append('file', this.form.get('avatar').value);

    this.SharedService.upload(formData, this.userId).subscribe(
      (res) => { this.uploadResponse = res; if (res.fileId != null) this.onFileUpload.emit({ fileId: res.fileId, fileName: this.form.get('avatar').value.name }); },
      (err) => this.error = err
    );
  }


}
