import { animate, state, style, transition, trigger } from '@angular/animations';
import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AggregateService, FreezeService, GridComponent, GroupService, ResizeService, SortService } from '@syncfusion/ej2-angular-grids';
import { Caption, PortalLanguageCaption } from '../../LanguageModel/Caption';
import { StudentProfileData } from '../../Models/StudentProfile';
import { StudentsListData } from '../../Models/StudentsList';
import { LanguageService } from '../../Service/LanguageService';
import { CardService } from '../Service/CardService';
import { EditIndStudentProfileData } from './SubDialogs/EditIndStudentProfileData';
import { StudentStudyMaterials } from './SubDialogs/StudentStudyMaterials/StudentStudyMaterials';



@Component({
  selector: 'card',
  templateUrl: 'Card.html',
  styleUrls: ['Card.css'],
  providers: [AggregateService, GroupService, FreezeService, ResizeService, SortService],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0', display: 'none' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})



export class Card implements OnInit {
  public studentsSubjects: StudentProfileData[];
  public student: StudentsListData;
  public captions: Caption;


  columsToDisplay = ['#', 'subjectName', 'lecturerList', 'subjectType', 'subjectStatus',
    'credit', 'yearName', 'semName', 'semesterNumber',
    'enrollStatus', 'sumMark', 'gpa', 'gradeSimbole', 'detile','studyMaterial']; 
  constructor(public dialog: MatDialog,
    private activatedRoute: ActivatedRoute, private route: ActivatedRoute,
    private location: Location,
    private router: Router,
    public CardService: CardService,
    public LanguageService: LanguageService,
    private http: HttpClient) {
    this.captions = PortalLanguageCaption[parseInt(localStorage.getItem("language"))-1];
    
    

  }
  ngOnInit() {
    
      this.CardService.StudentProfile().then(e => {
        this.studentsSubjects = e.studentProfile;
        this.student = e.student;
        //e.studentProfile.forEach((item, index) => {
        //  item.id = index + 1;
        //});
      });
  }

  public data: Object[];
  public pageOption: Object = { pageCount: 5 ,pageSize:30};
  public groupSettings: { [x: string]: Object } = {
    showDropArea: false, columns: ['yearSemName'//, 'semName'
    ]
  };
  public refresh: Boolean;
  @ViewChild('grid', { static:false })
  public grid: GridComponent;

  
  dataBound() {
    if (this.refresh) {
      this.grid.groupColumn('yearSemName');
     // this.grid.groupColumn('semName');
      this.refresh = false;
    }
  }
  load() {
    this.refresh = (<any>this.grid).refreshing;
  }



  //public GetHeaderCaption(name: string): string {
  //  for (let i = 0; i < this.columsToDisplayCaption.length; i++) {
  //    if (this.columsToDisplayCaption[i][0] == name) {
  //      console.log(name);
  //      return this.columsToDisplayCaption[i][1];
  //    }
  //  }
  //  return "";
  //}
  showDetails(element: StudentProfileData): void {
    if (element.courseId != null || element.courseId > 0) {
      let dialogRef = this.dialog.open(EditIndStudentProfileData, {
        panelClass: 'my-centered-dialog',
        height: '95%',
        width: '95%',
        disableClose: true,
        data: element
      });

      dialogRef.afterClosed().subscribe(result => {
       
        //this.animal = result;
      });
    }
  }

  showStudyMaterial(element: StudentProfileData) {
    if (element.courseId != null || element.courseId > 0) {
      let dialogRef = this.dialog.open(StudentStudyMaterials, {
        panelClass: 'my-centered-dialog',
        height: '95%',
        width: '95%',
        disableClose: true,
        data: element
      });

      dialogRef.afterClosed().subscribe(result => {

        //this.animal = result;
      });
    }
  }
}


export class Group {
  level = 0;
  parent: Group;
  expanded = true;
  totalCounts = 0;
  get visible(): boolean {
    return !this.parent || (this.parent.visible && this.parent.expanded);
  }
}
