import { Component, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { FlatTreeControl } from '@angular/cdk/tree';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { SelectionModel } from '@angular/cdk/collections';
import { of as observableOf, Observable, BehaviorSubject, throwError, empty, merge } from 'rxjs';
import { UntypedFormBuilder} from '@angular/forms';
import { catchError, map, startWith, switchMap, debounceTime, tap, finalize } from 'rxjs/operators';
import { RegistrationProp } from '../../../Models/StudentsList';
import { UserService } from '../../../Identity/_services';
import { StudSearchSubjectsParams, RetStudentsCeurseForSelect, CoursesForSelect, StudentCurrentSessionSelectedSubjects, StudentTimeTableOnLecture } from '../../../Models/StudentProfile';
import { CurrentSemesterSelectSubject } from '../CurrentSemesterSelectSubject/CurrentSemesterSelectSubject';
import { RegistrationService } from '../../Service/RegistrationService';
import { SharedService } from '../../Service/SharedService';
import { LanguageService } from '../../../Service/LanguageService';
import { Caption, PortalLanguageCaption } from '../../../LanguageModel/Caption';
import { StudentStudyMaterials } from '../../Card/SubDialogs/StudentStudyMaterials/StudentStudyMaterials';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatSnackBar } from '@angular/material/snack-bar';



@Component({
  selector: 'CurrentSemesterSubjectsForSelect',
  templateUrl: 'CurrentSemesterSubjectsForSelect.html',
  styleUrls: ['CurrentSemesterSubjectsForSelect.css']
})



export class CurrentSemesterSubjectsForSelect {

 
  selectedItemNumber: number = 1;
  activeNode: any;
  registrationStatus: Array<RegistrationProp>;
  resultsLength = 0;
  isLoadingResults = false;
  isRateLimitReached = false;
  selectedRow: any;
  availableCredits: number;
  enabledCredits: number;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  displayedColumns: string[] = ['#', 'syllabusCaption', 'subjectTypeCaption', 'preconditionComment','syllabus', 'credit','select'];
  entitiesDataSource: MatTableDataSource<CoursesForSelect> = new MatTableDataSource();
  selection = new SelectionModel<CoursesForSelect>(true, []);
  hasChild = (_: number, node: ExampleFlatNode) => node.expandable;
  private _transformer = (node: ProfileItem, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.name,
      level: level,
      id: node.id,
      icon: node.icon,
      haveChildren: node.children != null && node.children.length > 0 ? true : false
    };
  }

  treeControl = new FlatTreeControl<ExampleFlatNode>(
    node => node.level, node => node.expandable);

  treeFlattener = new MatTreeFlattener(
    this._transformer, node => node.level, node => node.expandable, node => node.children);

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.entitiesDataSource.data.length;
    return numSelected === numRows; }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.entitiesDataSource.data.forEach(row => this.selection.select(row));
  }
  fakultyList: any;
  facultySelected: any;
  studylevel: any;
  progarmList: any;
  selectedProgram: any;
  courseName: any;
  weekDayList: any;
  studentCurrentSessionSelectedSubjects: Array<StudentCurrentSessionSelectedSubjects>;
  studentCurrentSessionSelectedSubjectsTimeTable: Array<StudentTimeTableOnLecture>;
  public captions: Caption;
  public registrationEnabled: boolean = false;
  constructor(public dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private route: ActivatedRoute,
    private location: Location,
    private StudListService: RegistrationService,
    private UserService: UserService,
    private SharedService: SharedService,
    private router: Router, private fb: UntypedFormBuilder,
    public LanguageService: LanguageService, private _snackBar: MatSnackBar) {
    this.captions = PortalLanguageCaption[parseInt(localStorage.getItem("language")) - 1];
    let pIt: Array<ProfileItem> = new Array<ProfileItem>(6);
    pIt[0] = new ProfileItem();
    pIt[1] = new ProfileItem();

    pIt[0].id = 1;
    pIt[0].name = this.captions.electiveTrainingCourses;
    pIt[0].icon = "_cs_icon-book";
    pIt[1].id = 2;
    pIt[1].name = this.captions.selectedStudyCourse;
    pIt[1].icon = "_cs_icon-teacher";

    this.dataSource.data = pIt;

    let ise = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('currentUser')));
    this.facultySelected = ise.getValue().student.facultyId;
    this.studylevel = ise.getValue().student.studyLevelId;
    this.fakultyCahnged(this.facultySelected);
    this.selectedProgram = ise.getValue().student.mainStudyProgramId;
    this.StudListService.EnabledRegistrationStatus().then(e => {
    this.registrationStatus = e;
      this.registrationEnabled = true;
      for (let i = 0; i < this.registrationStatus.length; i++) {
        if (!this.registrationStatus[i].status) {
          this.registrationEnabled = false;
        }
      }

    })

   
    this.loadRegistrationData();
   
  }

 

  loadRegistrationData() {
    this.StudListService.GetStudentCurrentSessionSelectedCourses().then(e => { this.studentCurrentSessionSelectedSubjects = e as Array<StudentCurrentSessionSelectedSubjects>; });
   
      this.StudListService.AvailableCreditsForSelect().then(e => { this.availableCredits = e; })
      this.StudListService.EnabledGreditForSubjectSelect().then(e => { this.enabledCredits = e; })

     
  }

 
  private _filter: StudSearchSubjectsParams;
  status: boolean = false;
  clickEvent() {
    this.status = !this.status;
  }

  ngOnInit() {
    this.SharedService.GetFakultyList().then(e => { this.fakultyList = e; });
    this.fakultyCahnged(this.facultySelected);
   
    merge(//this.sort.sortChange,
      this.paginator.page)
      .pipe(
        //startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          this._filter.index = this.paginator.pageIndex;
          this._filter.take = this.paginator.pageSize;
          this._filter.studentId = null;
          this._filter.faculty = this.facultySelected;
          this._filter.studyProgram = this.selectedProgram;
          return this.StudListService.GetStudyCourseListForStudentByCurrentSession(this._filter);
        }),
        map(data => {
          // Flip flag to show that loading has finished.
          this.isLoadingResults = false;
          this.isRateLimitReached = false;
          this.resultsLength = data.count;

          return data.result;
        }),
        catchError(() => {
          this.isLoadingResults = false;
          // Catch if the GitHub API has reached its rate limit. Return empty data.
          this.isRateLimitReached = true;
          return observableOf([]);
        })
      ).subscribe(data => this.entitiesDataSource.data = data);

  }
  fakultyCahnged(value: any) {
    this.SharedService.GetStudyProgramList({ facultyId: this.facultySelected, studyLevelId: this.studylevel, directionId: null, studyProgarmId: null }).then(e => { this.progarmList = e });
  }
  Filter() {
    var filter = new StudSearchSubjectsParams();
    this._filter = filter;
    if (filter) {
      
      this._filter.studentId = null;
      this._filter.index = 0;
      this._filter.take = 20;
      this._filter.faculty = this.facultySelected;
      this._filter.studyProgram = this.selectedProgram;
      this._filter.searchCourse = this.courseName;
      this.StudListService.GetStudyCourseListForStudentByCurrentSession(filter).then(e => { this.entitiesDataSource.data = (e as RetStudentsCeurseForSelect).result; this.resultsLength = (e as RetStudentsCeurseForSelect).count });
    }
  }

  ShowCourseGroupsForSelect(courseId: number): void {
    let dialogRef = this.dialog.open(CurrentSemesterSelectSubject, {
      panelClass: 'my-centered-dialog',
      height: '95%',
      width: '95%',
      disableClose: true,
      data: { studId: null, courseId: courseId, operation: 1 }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != null && result.value == 1) {
        this._snackBar.open(this.captions.subjectName, this.captions.add, {
          duration: 2000,
        });
        this.loadRegistrationData();
      }
    });
  }
  EditSelectedCourseGroups(enrollId: number) {
    let dialogRef = this.dialog.open(CurrentSemesterSelectSubject, {
      panelClass: 'my-centered-dialog',
      height: '95%',
      width: '95%',
      disableClose: true,
      data: { studId: null, enrollId: enrollId, courseId:null,operation:2 }
    });

    dialogRef.afterClosed().subscribe(result => {
      
      if (result != null && result.value == 1) {
        this._snackBar.open(this.captions.subjectName, this.captions.updated, {
          duration: 2000,
        });
        this.loadRegistrationData();
      }
    });
  }

  DeleteEnrollSubject(enrollId: number, row: any) {
    if (confirm(this.captions.deleteSelectedSubject)) {
      this.StudListService.DeleteStudentSelectedCourse( enrollId).then(e => {

        if (e.seccuss) {
          let index = this.studentCurrentSessionSelectedSubjects.indexOf(row);
          this.studentCurrentSessionSelectedSubjects.splice(index, 1);
          this.loadRegistrationData();
          this._snackBar.open(this.captions.subjectName, this.captions.successfullyDeleted, {
            duration: 2000,
          });
        } else {
          this._snackBar.open(this.captions.subjectName, this.captions.anErrorOccurred, {
            duration: 2000,
          });
        }
      });
    }
  }

  
  FilterItem(node: ExampleFlatNode) {
    console.log(node);
    if (!node.haveChildren) {
      this.selectedItemNumber = node.id;
    }
  }

  showStudyMaterial(element: StudentCurrentSessionSelectedSubjects) {
      let dialogRef = this.dialog.open(StudentStudyMaterials, {
        panelClass: 'my-centered-dialog',
        height: '95%',
        width: '95%',
        disableClose: true,
        data: { id: element.enrollId, subjectName:element.syllabusCaption}
      });

      dialogRef.afterClosed().subscribe(result => {

      });
    
  }
  DownloadDocument(fileId: string) {
    this.SharedService.downloadFile(fileId);
  }

}
export class ProfileItem {
  public id: number;
  public name: string;
  public icon: string;
  public children?: ProfileItem[];
}

interface ExampleFlatNode {
  expandable: boolean;
  name: string;
  level: number;
  id: number;
  icon: string;
  haveChildren: boolean
}
