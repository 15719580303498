import { Component, ViewChild, AfterViewInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog} from '@angular/material/dialog';
import { SelectionModel } from '@angular/cdk/collections';
import { merge, Observable, of as observableOf, BehaviorSubject} from 'rxjs';
import { startWith, switchMap, map, catchError } from 'rxjs/operators';
import { FinanceService } from './Service/FinanceService';
import { StudentPaymentSession, StudFinPays, StudentFinDebtDistribution, StudPayment, StudentFinGrant, StudentSessionGrantList, StudentsGrantsList, StudentPaymentResult, StudentFinDebtDistributionResult } from './Models/finance';
import { UIfilter, FilterSubjectSylabusCourseEduPrograms } from '../Models/filters';
import { Caption, PortalLanguageCaption } from '../LanguageModel/Caption';


@Component({
  selector: 'app-FinanceMainForm',
  templateUrl: 'financeMainForm.html',
  styleUrls: ['financeMainForm.css']
})
export class FinanceMainForm implements AfterViewInit{

  
 
  resultsLength = 0;
  isLoadingResults = false;
  isRateLimitReached = false;
  selectedRow: any;

  selectedPaymentRow: number;
  //StudentPaymentSessions: Array<StudentPaymentSession>;
  studentPaymentResult: StudentPaymentResult;
  //StudentDebtDistributions: Array<StudentFinDebtDistribution>
  studentFinDebtDistributionResult: StudentFinDebtDistributionResult;
  StudentPays: Array<StudFinPays>;
  StudentPayments: Array<StudPayment>;
  StudentsGrants: Array<StudentFinGrant>;
  StudentSessionGrantLists: Array<StudentSessionGrantList>
  selectedStudPaymentRow: any;
  displayedColumns: string[] = ['fName', 'sName', 'persNumber', 'fakulty'];

  currency: any;
  durationTypes: any;
  grantType: any;
  payDirection: any;
  payerType: any;
  paymentTypeList: any;
  finStatus: any;
  studentsGrantsList: Array<StudentsGrantsList>;
  /** Whether the number of selected elements matches the total number of rows. */
  
  selectedPaymentSession: StudentPaymentSession;
  selectedStudPayment: StudPayment;


  public captions: Caption;
  constructor(public dialog: MatDialog,
    private FinanceService: FinanceService,
    private route: ActivatedRoute,
    private location: Location,
    private router: Router) {
    this.captions = PortalLanguageCaption[parseInt(localStorage.getItem("language")) - 1];

  }
  ngOnInit() {
    this.StudentSelected();
  }

  ngAfterViewInit() {
    //this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
    
  }

  StudentSelect(dt: any, i: any) {
    this.selectedRow = i;
  }

  StudentSelected() {
   // this.FinanceService.GetStudentPayments().then(e => { this.StudentPayments = e; });
    //this.StudentsGrants = null;
    //this.StudentPaymentSessions = null;
    //this.StudentPays = null;
    //this.StudentDebtDistributions = null;
    //this.selectedPaymentRow = null;
    //this.selectedStudPaymentRow = null;
    //this.StudentSessionGrantLists = null;
    //this.selectedPaymentSession = null;
    //this.selectedStudPayment = null;
    let filter = new UIfilter();
    filter.FilterSubjectSylabusCourseEduPrograms = new FilterSubjectSylabusCourseEduPrograms();
    this.selectedPaymentSession = null;
    this.FinanceService.GetStudentGrants().then(e => { this.StudentsGrants = e; });
    this.FinanceService.GetStudentPaymentSessions(filter).then(e => {
    this.studentPaymentResult = e;

      let length = e.paymentList.length;
      if (length > 0) {
        this.setPaymentClickedRow(length-1, e.paymentList[length - 1]);
      }
    });
    this.StudentPays = null;
    this.studentFinDebtDistributionResult = null;
    this.StudentSessionGrantLists = null;

  }
  setPaymentClickedRow(i: number, v: StudentPaymentSession) {
    this.selectedPaymentRow = i;
    if (v.id!=null && this.selectedPaymentSession != v) {
      this.selectedPaymentSession = v;
      this.FinanceService.GetStudentPaysOnSemesterPayment(v.id).then(e => { this.StudentPays = e; });
      this.FinanceService.GetStudentDebtDistributions(v.id).then(e => { this.studentFinDebtDistributionResult = e; });
      this.FinanceService.GetStudPaySessionGrantList(v.id).then(e => { this.StudentSessionGrantLists = e; });
    }
  }
  //setStudPaymentClickedRow(i: number, v: StudPayment) {
  //  if (v.id != null && this.selectedStudPayment != v) {
      
  //  }
  //}
  
}


