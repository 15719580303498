import { Component, Inject } from '@angular/core';
import { SharedService } from '../../../Service/SharedService';
import { StudyMaterialService } from '../../../Service/StudyMaterialService';
import { StudyMaterial } from '../../../../Models/StudyMaterial';
import { StudentProfileData, StudentSelectedCourseGroup } from '../../../../Models/StudentProfile';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GraideService } from '../../../Service/GraideService';
import { Caption, PortalLanguageCaption } from '../../../../LanguageModel/Caption';

@Component({
    selector: 'StudentStudyMaterials',
    templateUrl: './StudentStudyMaterials.html',
    styleUrls: ['./StudentStudyMaterials.css']
})
/** StudentStudyMaterials component*/
export class StudentStudyMaterials {
  materialList: Array<StudyMaterial> = new Array<StudyMaterial>();
  lecutreTypeList: Array<StudentSelectedCourseGroup>;
  public captions: Caption;
  examEvalution = { id: null, lectureId: null, lectureTypeId: -1, lectureCaption: "" };

  labelPosition: StudentSelectedCourseGroup;
    /** StudentStudyMaterials ctor */
  constructor(public dialogRef: MatDialogRef<StudentStudyMaterials>,
    @Inject(MAT_DIALOG_DATA) public data: StudentProfileData,
    private sharedService: SharedService,
    private graideService: GraideService,
    private studyMaterialService: StudyMaterialService) {
    this.captions = PortalLanguageCaption[parseInt(localStorage.getItem("language")) - 1];
    this.examEvalution = { id: null, lectureId: null, lectureTypeId: -1, lectureCaption: this.captions.exam };
    this.graideService.GetStudentSelectedGruops(data.id).then(e => {
      this.lecutreTypeList = e;

      if (this.lecutreTypeList.length > 0) {
        this.getGroupsOnSelected(this.lecutreTypeList[this.lecutreTypeList.length - 1])
        this.labelPosition = this.lecutreTypeList[this.lecutreTypeList.length - 1];
      }

    });

    
  }


  getGroupsOnSelected(lectureType: StudentSelectedCourseGroup) {
    if (lectureType.lectureTypeId == -1) {
      lectureType.lectureId = this.lecutreTypeList[0].lectureId;
    }
    this.Filter(lectureType.lectureId)
  }


  DownloadDocument(fileId: string) {
    this.sharedService.downloadFile(fileId);
  }

  Filter(groupId: number) {
    this.studyMaterialService.GetStudyMaterialByGroups(groupId).then(e => {
      this.materialList = e;
    });
  }

}
