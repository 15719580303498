<div class="_cs_flex _cs_size-w-percent--100">
  <div class="_cs_size-w-percent--100 _cs_p-left--lg _cs_p-right--lg _cs_p-down--lg">
    <div class="_cs_size-h-percent--100 sidebarbg _cs_flex">
      <!-- sidebarbg__sidebar -->
      <div class="sidebarbg__wrapper _cs_p-left--none-i _cs_size-w-percent--100 _cs_flex _cs_flex-d--column">
        <div class="_cs_m-top--xxl">
          <div
            class="_cs_p-xs _cs_shadow-primary-0-0--1 _cs_border-radius--sm _cs_border-down--primary-6 _cs_flex _cs_flex-a--center">
            <span
              class="_cs_icon _cs_icon-xl _cs_shadow-primary-6-0--2 _cs_border-radius--full _cs_border--primary-6 _cs_m-right--md">
              <span class="_cs_icon _cs_icon-md _cs_color-primary-6 _cs_icon-student-card"></span>

            </span> <span
              class="_cs_label _cs_label-sm _cs_label-font-setting-case-on _cs_label-bold _cs_color-primary-6">{{captions.card}}</span>
          </div>
        </div>


        <div class="_cs_flex" *ngIf="studentsSubjects">
          <div class="_cs_flex  _cs_size-w-percent--100 _cs_m-top--lg">
            <div class=" _cs_flex _cs_flex-wrap _cs_size-w-percent--100 _cs_flex _cs_flex-j--center" *ngIf="student">
              <div class="_cs_col-xs-12 _cs_col-sm-4 _cs_col-md-3 _cs_p-left--sm _cs_p-right--sm">
                <div
                  class="_cs_flex _cs_flex-d--column _cs_p-sm  _cs_shadow-primary-0-0--1   _cs_color-bg--primary-5 _cs_border-radius--xxl _cs_m-down--lg">
                  <span class="_cs_label _cs_label-md _cs_color-primary-2 _cs_label-500 _cs_label-font-setting-case-on  _cs_flex-j--center">
                    {{captions.arithmeticAverage}}
                  </span>
                  <span
                    class="_cs_label _cs_label-xl _cs_label-a--center _cs_label-600 _cs_m-top--xxs _cs_flex-j--center">{{student.avgMark}}</span>
                </div>
              </div>
              <div class="_cs_col-xs-12 _cs_col-sm-4 _cs_col-md-3 _cs_p-left--sm _cs_p-right--sm">
                <div
                  class="_cs_flex _cs_flex-d--column _cs_p-sm  _cs_shadow-primary-0-0--1   _cs_color-bg--primary-5 _cs_border-radius--xxl _cs_m-down--lg">
                  <span class="_cs_label _cs_label-md _cs_color-primary-2 _cs_label-500 _cs_label-font-setting-case-on  _cs_flex-j--center">
                    {{captions.doneCredits}}
                  </span>
                  <span
                    class="_cs_label _cs_label-xl _cs_label-a--center _cs_label-600 _cs_m-top--xxs _cs_flex-j--center">{{student.sumCredits}}</span>
                </div>
              </div>
              <div class="_cs_col-xs-12 _cs_col-sm-4 _cs_col-md-3 _cs_p-left--sm _cs_p-right--sm">
                <div
                  class="_cs_flex _cs_flex-d--column _cs_p-sm  _cs_shadow-primary-0-0--1   _cs_color-bg--primary-5 _cs_border-radius--xxl _cs_m-down--lg">
                  <span
                    class="_cs_label _cs_label-md _cs_color-primary-2 _cs_label-500 _cs_label-font-setting-case-on  _cs_flex-j--center">
                    GPA
                  </span>
                  <span class="_cs_label _cs_label-xl _cs_label-a--center _cs_label-600 _cs_m-top--xxs _cs_flex-j--center">{{student.gpaDanarti}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="_cs_size-w-percent--100  _cs_flex _cs_flex-d--column" *ngIf="captions">

          <div class="control-section">
            <ejs-grid #grid height='500' allowTextWrap='true' gridLines="Both" allowSorting="true"
              [dataSource]="studentsSubjects" [allowResizing]="true" [allowPaging]="true" [allowGrouping]="true"
              [pageSettings]='pageOption' [groupSettings]="groupSettings" (dataBound)='dataBound()' (load)='load()'>
              <e-columns>
                <e-column field='subjectName' headerText={{captions.subjectName}} width='350'></e-column>
                <e-column headerText={{captions.lecturers}} width='200'>
                  <ng-template #template let-data>
                    <div *ngFor="let sl of data.lecturerList; let k=index;">
                      {{sl.fName+" "+sl.sName}}
                    </div>
                  </ng-template>
                </e-column>
                <!--<e-column field='subjectType' headerText={{captions.subjectType}}  width='150'></e-column>-->
                <e-column field='subjectStatus' headerText={{captions.subjectStatus}} width='150'></e-column>
                <e-column field='credit' headerText={{captions.credit}} width='110'></e-column>
                <e-column field='yearSemName' headerText={{captions.yearSemesterName}}></e-column>
                <!--<e-column field='semName' headerText={{captions.semName}}></e-column>-->
                <e-column field='semesterNumber' headerText={{captions.semester}} width='115'></e-column>
                <!--<e-column field='enrollStatus' headerText='სტატუსი' width='110'></e-column>-->



                <e-column field='sumMark' headerText={{captions.mark}} width='90'></e-column>
                <e-column field='gradeSimbole' headerText={{captions.gradeSimbole}} width='110'></e-column>
                <e-column width='70'>
                  <ng-template #template let-data>
                    <img *ngIf="data.enrollStatusId==1" src="/bullet_ball_green (3).png" title={{captions.complete}} />
                    <img *ngIf="data.enrollStatusId==2" src="/bullet_ball_red (2).png" title={{captions.failed}} />
                    <img *ngIf="data.enrollStatusId==3" src="/bullet_ball_yellow (2).png" title={{captions.notDefined}} />
                  </ng-template>
                </e-column>
                <e-column field='gpa' headerText='GPA' width='75'></e-column>
                <e-column  width='70'>
                  <ng-template #template let-data>
                    <button *ngIf="data.courseId" (click)="showDetails(data)" class="btnDocumentOpen" title={{captions.notDefined}}>
                    </button>
                  </ng-template>
                </e-column>
                <!--<e-column  width='70'>
                  <ng-template #template let-data>
                    <button *ngIf="data.courseId" (click)="showStudyMaterial(data)" class="btnDocumentOpen" title="სასწავლო მასალა">
                      
                    </button>
                  </ng-template>
                </e-column>-->
              </e-columns>
              <!--<e-aggregates>
                <e-aggregate>
                  <e-columns>
                    <e-column type="Sum" field="credit">
                      <ng-template #groupFooterTemplate let-data>Total units: {{data.Sum}}</ng-template>
                    </e-column>
                    <e-column type="TrueCount" field="subjectName">
                      <ng-template #groupFooterTemplate let-data>Discontinued: {{data.TrueCount}}</ng-template>
                    </e-column>
                  </e-columns>
                </e-aggregate>
                <e-aggregate>
                  <e-columns>
                    <e-column type="Max" field="credit">
                      <ng-template #groupCaptionTemplate let-data>Maximum: {{data.Max}}</ng-template>
                    </e-column>
                  </e-columns>
                </e-aggregate>
              </e-aggregates>-->
            </ejs-grid>
          </div>
          <!--<table cdk-table [dataSource]="studentsSubjects"
                 class="table table-bordered table-striped table-responsive-sm">
            <ng-container matColumnDef="#">
              <th mat-header-cell *matHeaderCellDef> #</th>
              <td mat-cell *matCellDef="let element; let i = index;"> {{i+1}} </td>
            </ng-container>
            <ng-container matColumnDef="subjectName">
              <th mat-header-cell *matHeaderCellDef> {{captions.subjectName}} </th>
              <td mat-cell *matCellDef="let element"> {{element.subjectName}} </td>
            </ng-container>
            <ng-container matColumnDef="lecturerList">
              <th mat-header-cell *matHeaderCellDef> პედაგოგ(ებ)ი </th>
              <td mat-cell *matCellDef="let element">
                <div *ngFor="let sl of element.lecturerList; let k=index;">
                  {{(k+1)+". "+sl.groupTypeCaption+": "+sl.fName+" "+sl.sName}}
                </div>
              </td>
            </ng-container>
           
            <ng-container matColumnDef="subjectType">
              <th mat-header-cell *matHeaderCellDef> {{captions.subjectType}} </th>
              <td mat-cell *matCellDef="let element"> {{element.subjectType}} </td>
            </ng-container>
            <ng-container matColumnDef="subjectStatus">
              <th mat-header-cell *matHeaderCellDef> საგნის სტატუსი </th>
              <td mat-cell *matCellDef="let element"> {{element.subjectStatus}} </td>
            </ng-container>
           
            <ng-container matColumnDef="credit">
              <th mat-header-cell *matHeaderCellDef> {{captions.credit}} </th>
              <td mat-cell *matCellDef="let element"> {{element.credit}} </td>
            </ng-container>
           
            <ng-container matColumnDef="yearName">
              <th mat-header-cell *matHeaderCellDef> {{captions.yearName}} </th>
              <td mat-cell *matCellDef="let element"> {{element.yearName}} </td>
            </ng-container>
            <ng-container matColumnDef="semName">
              <th mat-header-cell *matHeaderCellDef> {{captions.semName}} </th>
              <td mat-cell *matCellDef="let element"> {{element.semName}} </td>
            </ng-container>
            <ng-container matColumnDef="semesterNumber">
              <th mat-header-cell *matHeaderCellDef> სემესტრი </th>
              <td mat-cell *matCellDef="let element"> {{element.semesterNumber}} </td>
            </ng-container>
            <ng-container matColumnDef="enrollStatus">
              <th mat-header-cell *matHeaderCellDef> enrollStatus </th>
              <td mat-cell *matCellDef="let element"> {{element.enrollStatus}} </td>
            </ng-container>
            <ng-container matColumnDef="sumMark">
              <th mat-header-cell *matHeaderCellDef> sumMark </th>
              <td mat-cell *matCellDef="let element"> {{element.sumMark}} </td>
            </ng-container>
            <ng-container matColumnDef="gpa">
              <th mat-header-cell *matHeaderCellDef> gpa </th>
              <td mat-cell *matCellDef="let element"> {{element.gpa}} </td>
            </ng-container>
            <ng-container matColumnDef="gradeSimbole">
              <th mat-header-cell *matHeaderCellDef> gradeSimbole </th>
              <td mat-cell *matCellDef="let element"> {{element.gradeSimbole}} </td>
            </ng-container>
            <ng-container matColumnDef="detile">
              <th mat-header-cell *matHeaderCellDef> დეტალური </th>
              <td mat-cell *matCellDef="let element">
                <button class="_cs_button _cs_button-sm _cs_button-border--positive _cs_size-w-percent--100"
                        (click)="showDetails(element)">
                  <span class="_cs_label _cs_label-sm _cs_label-500 _cs_label-font-setting-case-on _cs_label-t-u">გახსნა</span>
                </button>
              </td>
            </ng-container>
            <ng-container matColumnDef="studyMaterial">
              <th mat-header-cell *matHeaderCellDef> სასწავლო მასალა </th>
              <td mat-cell *matCellDef="let element">
                <button class="_cs_button _cs_button-sm _cs_button-border--positive _cs_size-w-percent--100"
                        (click)="showStudyMaterial(element)">
                  <span class="_cs_label _cs_label-sm _cs_label-500 _cs_label-font-setting-case-on _cs_label-t-u">გახსნა</span>
                </button>
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="columsToDisplay"></tr>
            <tr mat-row *matRowDef="let row; columns: columsToDisplay;"></tr>
          </table>-->
        </div>


      </div>
    </div>
  </div>
  </div>
