import { Location } from '@angular/common';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
//import { User } from '../../Models/Users';
import { FilterService, FreezeService, ReorderService, SortService } from '@syncfusion/ej2-angular-treegrid';
//import { AngularTreeGridComponent } from 'angular-tree-grid';
import { User } from '../../Identity/_models/user';
import { Caption, PortalLanguageCaption } from '../../LanguageModel/Caption';
// import { User } from '../../identity/_models';
//import { Node, Options } from 'ng-material-treetable';
import { StudyProgramStructure } from '../../Models/ProgramStructure';
import { LanguageService } from '../../Service/LanguageService';
import { CardService } from '../Service/CardService';
import { StudyProgramService } from '../Service/StudyProgramService';


@Component({
  selector: 'StudyProgram',
  templateUrl: 'StudyProgram.html',
  styleUrls: ['StudyProgram.css'],
  providers: [FilterService, SortService, ReorderService, FreezeService],
  encapsulation: ViewEncapsulation.None
})



export class StudyProgram implements OnInit {
  public captions: Caption;
  configs: any;
  donStatus: boolean=false;
  progarmSpecializationList: Array<StudyProgramStructure>;
  selectedSpecialization: string;
  public columns: any;
  public treeColumnIndex = 0;
  public pageSettings: Object;
  constructor(
    private activatedRoute: ActivatedRoute, private route: ActivatedRoute,
    private location: Location,
    private router: Router,
    public CardService: CardService,
    public LanguageService: LanguageService,
    public StudyProgramService: StudyProgramService) {
    this.captions = PortalLanguageCaption[parseInt(localStorage.getItem("language")) - 1];
    this.pageSettings = { pageSize: 25 };

    //this.columns = [
     
    //  {
    //  field: 'caption',
    //    headerText: this.captions.caption,
    //    width:450
    ////  editType: ej.TreeGrid.EditingType.String
    //},
    //{
    //  field: 'status',
    //  headerText: this.captions.status,
    // // editType: ej.TreeGrid.EditingType.String
    //},
    //{
    //  field: 'structType',
    //  headerText: this.captions.structType,
    ////  editType: ej.TreeGrid.EditingType.String
    //},
    //{
    //  field: 'done',
    //  headerText: this.captions.done,
    ////  editType: ej.TreeGrid.EditingType.String
    //},
    //{
    //  field: 'doneCreditsByStudent',
    //  headerText: this.captions.doneCredits,
    ////  editType: ej.TreeGrid.EditingType.Numeric
    //},
    //{
    //  field: 'creditVolume',
    //  headerText: this.captions.creditVolume,
    ////  editType: ej.TreeGrid.EditingType.Numeric
    //},
    //{
    //  field: 'recomendedSemester',
    //  headerText: this.captions.recomendedSemester,
    // // editType: ej.TreeGrid.EditingType.Numeric
    //},
    //{
    //  field: 'studySemester',
    //  headerText: this.captions.semName,
    // // editType: ej.TreeGrid.EditingType.Numeric
    //}
    //];


    }

    //@ViewChild('angularGrid', { static: false }) angularGrid: AngularTreeGridComponent;
    data: StudyProgramStructure[];
    //expandAll() {
    //    this.angularGrid.expandAll();
    //}
    //collapseAll() {
    //    this.angularGrid.collapseAll();
    //}

 

  ngOnInit() {

        this.StudyProgramService.GetProgramStructure((JSON.parse(localStorage.getItem('currentUser')) as any).student.mainStudyProgramId).then(e => {
          this.data = e;
        });
      this.LoadSubProgram((JSON.parse(localStorage.getItem('currentUser')) as any).student.mainStudyProgramId);
  
     }
    

  LoadSubProgram(programId: number): void {
    this.StudyProgramService.GetSubProgramList(programId).then(e => {
    this.progarmSpecializationList = e;
    this.selectedSpecialization = (JSON.parse(localStorage.getItem('currentUser')) as User).student.specializationId;
      
    });
  }


  SelectSpecialization(): void {
    this.StudyProgramService.UpdateStudentSpecialization(this.selectedSpecialization).then(e => {

      if (e.seccuss) {
        (JSON.parse(localStorage.getItem('currentUser')) as User).student.specializationId = this.selectedSpecialization;
        alert("სპეციალიზაცია დაფიქსირებულია!");
      }
      else {
        alert("მოხდა შეცდომა!");
      }

    });
  }




}
