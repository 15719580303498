import { Component, ViewChild, EventEmitter, Input, Output, AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, SimpleChanges } from '@angular/core';
import { Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

import { SelectionModel } from '@angular/cdk/collections';
import { merge, Observable, of as observableOf } from 'rxjs';
import { startWith, switchMap, map, catchError } from 'rxjs/operators';
import { RetStudList, StudentsListData } from '../../Models/StudentsList';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-StatementFilter',
  templateUrl: 'StatementFilter.html',
  styleUrls: ['StatementFilter.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StatementFilter {

  public studentsList: RetStudList;
  resultsLength = 0;
  isLoadingResults = false;
  isRateLimitReached = false;
  selectedRow: any;
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  //@Input() filter: UIfilter;
  @Output() onStudent = new EventEmitter<StudentsListData>();
  //private _filter: UIfilter;

  //get filter(): UIfilter {
  //  // transform value for display
  //  return this._filter;
  //}

  //@Input()
  //set filter(filter: UIfilter) {
  //  this._filter = filter;
  //  this.ShowStudents(this.filter);
  //}

  displayedColumns: string[] = ['fName', 'sName', 'persNumber', 'fakulty'];
  entitiesDataSource: MatTableDataSource<StudentsListData> = new MatTableDataSource();


  /** Whether the number of selected elements matches the total number of rows. */


  constructor(private cd: ChangeDetectorRef,
    private route: ActivatedRoute,
    private location: Location,
    private router: Router) {


  }
  ngOnInit() {
    //  merge(//this.sort.sortChange,
    //    this.paginator.page)
    //    .pipe(
    //      // startWith({}),
    //      switchMap(() => {
    //        this.isLoadingResults = true;

    //        return this.StudListService.StudentsList(this._filter.FilterPersone,
    //          this.paginator.pageSize, this.paginator.pageIndex);
    //      }),
    //      map(data => {
    //        this.isLoadingResults = false;
    //        this.isRateLimitReached = false;
    //        //alternatively to response headers;
    //        this.resultsLength = data.count;

    //        return data.studentData;
    //      }),
    //      catchError(() => {
    //        this.isLoadingResults = false;
    //        this.isRateLimitReached = true;

    //        return observableOf([]);
    //      })
    //    ).subscribe(data => { this.entitiesDataSource.data = data; });
    //}
    //ngOnChanges(changes: SimpleChanges) {
    //  this.ShowStudents(this.filter);

    //}
    //alter variant of Changes
    //@Input() set categoryId(value: string) {

    //  this._categoryId = value;
    //  this.doSomething(this._categoryId);

    //}

    //get categoryId(): string {

    //  return this._categoryId;

    //}
    //ShowStudents(flt: UIfilter) {

    //  if (flt) {
    //    //this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);


    //    this.StudListService.StudentsList(flt.FilterPersone, 5, 0).then(e => { this.entitiesDataSource.data = (e as RetStudList).studentData; this.resultsLength = (e as RetStudList).count });
    //  }
    //}


    //StudentSelect(student: any, i: any) {
    //  this.selectedRow = i;
    //  this.onStudent.emit(student);
    //}
    //refresh() {
    //  //this.cd.detectChanges();
    //}
  }

}
