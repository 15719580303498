export class PostSelectedGroups {
    public syllabusId: number;
  public studentId: number;
  public operation: number;
  public enrollId: number;
    public groups: number[];
}

export class PostStudentLectureGroupParams {
  public studId: number;
  public courseId: number;
}

export class PostStudentEnrollHistory {
  public studId: number;
  public enrollId: number;
 }
export class SessionActivationPost {
  sessionId: number;
  active: number;
}
