import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';
//import { map } from 'rxjs/operators';
import { map, tap } from 'rxjs/operators';
//import 'rxjs/add/operator/toPromise';
import { Observable } from 'rxjs';
import { StudentPaymentSession, StudentFinGrant, StudFinPays, StudentFinDebtDistribution, StudPayment, StudentSessionGrantList, StudentsGrantsList, StudentPaymentResult, StudentFinDebtDistributionResult } from '../Models/finance';
import { UIfilter } from '../../Models/filters';


@Injectable()
export class FinanceService {

  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  private headers2 = new HttpHeaders({ 'Content-Type': 'multipart/form-data' });
  private heroesUrl = '/';
  constructor(private http: HttpClient) { }

 

  GetStudentPaymentSessions(fl: UIfilter): Promise<StudentPaymentResult> {

    return this.http.post("/api/StudentFinance/GetStudentPaymentSessions", fl, { headers: this.headers })
      .toPromise()
      .then(response => response)
      .catch(this.handleError);
  }
  

  GetStudentGrants(): Promise<Array<StudentFinGrant>> {

    
    return this.http.post("/api/StudentFinance/GetStudentGrants", null, { headers: this.headers })
      .toPromise()
      .then(response => response)
      .catch(this.handleError);
  }

  GetStudentPaysOnSemesterPayment(finPaymentId: number): Promise<Array<StudFinPays>> {

    return this.http.post("/api/StudentFinance/GetStudentPaysOnSemesterPayment", finPaymentId, { headers: this.headers })
      .toPromise()
      .then(response => response)
      .catch(this.handleError);
  }

  GetStudentDebtDistributions(finPaymentId: number): Promise<StudentFinDebtDistributionResult> {

    return this.http.post("/api/StudentFinance/GetStudentDebtDistributions", finPaymentId, { headers: this.headers })
      .toPromise()
      .then(response => response)
      .catch(this.handleError);
  }

  GetStudPaySessionGrantList(sessionPaymentId: number): Promise<Array<StudentSessionGrantList>> {

    return this.http.post("/api/StudentFinance/GetStudPaySessionGrantList", sessionPaymentId, { headers: this.headers })
      .toPromise()
      .then(response => response)
      .catch(this.handleError);
  }
  GetGrantsList(): Promise<Array<StudentsGrantsList>> {

    return this.http.post("/api/StudentFinance/GetGrantsList", null, { headers: this.headers })
      .toPromise()
      .then(response => response)
      .catch(this.handleError);
  }

  

  private handleError(error: any): Promise<any> {
    console.error('An error occurred', error); // for demo purposes only
    return Promise.reject(error.message || error);
  }
}
