import { Component } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
// import { User } from '../identity/_models';
import { Caption, PortalLanguageCaption } from '../LanguageModel/Caption';
import { LanguageService } from '../Service/LanguageService';
import { User } from '../Identity/_models/user';
import { SharedService } from '../Student/Service/SharedService';
import { Router } from '@angular/router';
import { AuthenticationService } from '../Identity/_services/authentication.service';
import { MatDialog } from '@angular/material/dialog';
import { ChangePassword } from '../Identity/ChangePassword/ChangePassword';
import { ChangeStudentProfile } from '../Identity/ChangeStudentProfile/ChangeStudentProfile';
import { ShearedDataService } from '../Student/Service/ShearedData';
import { CorrespondenceService } from '../Student/Correspondence/Service/CorrespondenceService';
import { CorrespondenceDataWithAttachmentGet, CorrespondencStatement } from '../Models/Correspondence';
import { QuizService } from '../Student/Quiz/Service/QuizService';


@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.css']
})
export class NavMenuComponent {
  public captions: Caption;
  public userName: string
  public profileImageFileStream: string;
  public showContent: boolean = false;
  subscription: Subscription;
  constructor(public dialog: MatDialog,
    private router: Router,
    public LanguageService: LanguageService,
    private shearedDataService: ShearedDataService,
    private authenticationService: AuthenticationService,
    private correspondenceService: CorrespondenceService,
    private sharedService: SharedService,
    private quizService: QuizService) {
    this.subscription = this.shearedDataService.data
      .subscribe(mode => {
        if (mode != null) {
          this.UserProfileLoad();
          this.showContent = true;
          this.LoadCorrespondenceData();
          this.LoadQuiz();
        }
        else {
          this.showContent = false;
        }
      });
  }

  correspondence: Array<CorrespondencStatement>;

  LoadCorrespondenceData() {
    this.correspondenceService.UnReadCorrespondence().then(e => {
      this.correspondence = e;

    });
  }

  LoadQuiz() {
    this.quizService.QuizList().then(e => {
      if (e.length > 0 && e.find(k => k.complete==false)!=null) {
        this.router.navigateByUrl('/quiz');
      }
    });
  }

  isExpanded = false;

  collapse() {
    this.isExpanded = false;

  }
  ngAfterContentInit(): void {

   
    

  }

  ShowMenu(): any {
    
    if (this.router.url.startsWith('/login')) {
      this.showContent=false;
    }
    else {
      
      this.UserProfileLoad();
    }
   
  }
  toggle() {
    this.isExpanded = !this.isExpanded;
  }
  BehavRet(image: string): BehaviorSubject<string> {
    return new BehaviorSubject(image);
  }
  UserProfileLoad() {

    let ise = JSON.parse(localStorage.getItem('currentUser')) as User;
    if (ise != null && this.userName != ise.username) {
      this.userName = ise.username;
      this.profileImageFileStream = ise.student.profileImageFileStream;
      if (localStorage.getItem("language") != null) {
        this.captions = PortalLanguageCaption[parseInt(localStorage.getItem("language")) - 1];
       // this.showContent = true;
      }
    }
    //else {
    //  this.showContent = false;
    //}

  }

  LogOut() {
    this.authenticationService.logout().then(e => {
      if (e) {
        //this.router.navigate(['/']);
        this.router.navigateByUrl('/login')
      }
    });
  }
  ChangePassword() {
    let dialogRef = this.dialog.open(ChangePassword, {
      panelClass: 'my-centered-dialog',
      height: '300px',
      width: '450px',
      disableClose: true,
      data: { formType: 1, recipientsList: null }
    });

    dialogRef.afterClosed().subscribe(result => {

      //this.animal = result;
    });
  }
  ChangeProfle() {
    let dialogRef = this.dialog.open(ChangeStudentProfile, {
      panelClass: 'my-centered-dialog',
      height: '200px',
      width: '800px',
      disableClose: true,
      data: { formType: 1, recipientsList: null }
    });

    dialogRef.afterClosed().subscribe(result => {

      //this.animal = result;
    });
  }
}
