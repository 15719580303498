import { Component, OnInit, Inject, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
//import { StudentProfileData, StudentSelectedCourseGroup } from '../../../../Models/StudentProfile';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { StudentProfileData, StudentSelectedCourseGroup } from '../../../Models/StudentProfile';
import { GradeFunctions } from '../../../Functions/graidFunctions';
import { GraideService } from '../../Service/GraideService';
import { StudentEvaluationGenerator, SaveEvaluationMarks, EvaluationHeader, StudentListOnLecture } from '../../../Models/Graids';
import { Caption, PortalLanguageCaption } from '../../../LanguageModel/Caption';
//import { GradeFunctions } from '../../../../Functions/graidFunctions';

@Component({
    selector: 'EditIndStudentProfileData',
    templateUrl: './EditIndStudentProfileData.html',
  styleUrls: ['./EditIndStudentProfileData.css']
})
/** EditIndStudentProfileData component*/
export class EditIndStudentProfileData {
  public captions: Caption;
  studentEvaluationGenerator: StudentEvaluationGenerator
  changedMarks = new Array<SaveEvaluationMarks>();
  syllabusComponents = new Array<EvaluationHeader>();
  lecutreTypeList: Array<StudentSelectedCourseGroup>;
  examEvalution = { id: null, lectureId: null, lectureTypeId: -1, lectureCaption: "გამოცდები" };
  //theHtmlString: string;
  p: number = 1;
  labelPosition: StudentSelectedCourseGroup;
    /** EditIndStudentProfileData ctor */
  constructor(public dialogRef: MatDialogRef<EditIndStudentProfileData>,
    @Inject(MAT_DIALOG_DATA) public data: StudentProfileData,
    private activatedRoute: ActivatedRoute,
    private GraideService: GraideService,
    private route: ActivatedRoute,
    private location: Location,
    private router: Router,
    private cdr: ChangeDetectorRef) {
    this.captions = PortalLanguageCaption[parseInt(localStorage.getItem("language")) - 1];
    this.GraideService.GetStudentSelectedGruops(data.id).then(e => {
      this.lecutreTypeList = e;
      if (this.lecutreTypeList.length > 0) {
        this.getGroupsOnSelected(this.lecutreTypeList[this.lecutreTypeList.length - 1])
        this.labelPosition = this.lecutreTypeList[this.lecutreTypeList.length - 1];
      }

    });
  }
  getGroupsOnSelected(lectureType: StudentSelectedCourseGroup) {
    if (lectureType.lectureTypeId == -1) {
      lectureType.lectureId = this.lecutreTypeList[0].lectureId;
    }
    this.Filter(lectureType.lectureId, lectureType.lectureTypeId)
  }

  WeekIdentyCount(weekId: number): number {
    let i = 0;
    for (let k = 0; k < this.studentEvaluationGenerator.evaluationHeader.length; k++) {
      if (this.studentEvaluationGenerator.evaluationHeader[k].weekId == weekId) {
        i++;
      }
    }
    return i;
  }

  ComponentIdentyCount(componentId: number, weekId: number): number {
    let i = 0;
    for (let k = 0; k < this.studentEvaluationGenerator.evaluationHeader.length; k++) {
      if (this.studentEvaluationGenerator.evaluationHeader[k].componentOnSyllabusId == componentId &&
        this.studentEvaluationGenerator.evaluationHeader[k].weekId == weekId) {
        i++;
      }
    }
    return i;
  }
  weekDayIdIdentyCount(weekDayId: number, weekId: number, componentId: number): number {
    let i = 0;
    for (let k = 0; k < this.studentEvaluationGenerator.evaluationHeader.length; k++) {
      if (this.studentEvaluationGenerator.evaluationHeader[k].weekDayId == weekDayId && this.studentEvaluationGenerator.evaluationHeader[k].weekId == weekId && this.studentEvaluationGenerator.evaluationHeader[k].componentOnSyllabusId == componentId) {
        i++;
      }
    }
    return i;
  }

  counter(num: number): Array<number> {
    let v = new Array<number>();
    for (let i = 1; i <= num; i++) {
      v.push(i);
    }

    return v;
  }


  getSelectedSubject(subjectv: any) {
    //console.log(subjectv);
  }
  changeValues(mark: number, header: EvaluationHeader, student: StudentListOnLecture) {
    let k = 0;
    for (let i = 0; i < this.changedMarks.length; i++) {
      if (this.changedMarks[i].sTT == header.syllabusComponentTimeTableId && this.changedMarks[i].sd == student.ed) {
        this.changedMarks[i].mark = mark;
        k = 1;
      }
    }
    if (k == 0) {
      this.changedMarks.push({
        sd: student.ed, sE: null, mark: mark, sTT: header.syllabusComponentTimeTableId, calculationTypeID: header.calculationTypeID,
        evalutionTypeId: header.evalutionTypeId, maxEnterMark: header.maxEnterMark, maxMark: header.maxMark, maxRanking: header.maxRanking,
        minEnterMark: header.minEnterMark, componentOnSyllabusId: header.componentOnSyllabusId, maxCompCountOnWeeks: header.maxCompCountOnWeeks
      });
    }

  }
  
  Filter(groupId: number, lectureTypeId: number) {
    this.syllabusComponents = new Array<EvaluationHeader>();
    this.GraideService.GetStudentEvaluationListOnLecture({ courseId: this.data.courseId, groupId: groupId, lacureTypeId: lectureTypeId, enrollId: this.data.id }).then(e => {
      this.studentEvaluationGenerator = e;
      this.FillSumMark(this.studentEvaluationGenerator);

      for (let i = 0; i < this.studentEvaluationGenerator.evaluationHeader.length; i++) {
        let n = 0;
        for (let k = 0; k < this.syllabusComponents.length; k++) {
          if (this.studentEvaluationGenerator.evaluationHeader[i].componentOnSyllabusId == this.syllabusComponents[k].componentOnSyllabusId) {
            n = 1;
            break;
          }
        }
        if (n == 0) {
          this.syllabusComponents.push(this.studentEvaluationGenerator.evaluationHeader[i]);
        }
      }
      this.cdr.detectChanges();
      //this.cdr.detach();
    });
  }

  FillSumMark(marks: StudentEvaluationGenerator) {
    for (let i = 0; i < marks.studentListOnLecture.length; i++) {
      let sum = this.SumStudentsGraids(marks.studentListOnLecture[i]);
    }
  }

  SumStudentsGraids(st: StudentListOnLecture): number {
    let sum = 0;
    for (let i = 0; i < st.eM.length; i++) {
      if (st.eM[i] != null && st.eM[i] > 0) {
        let header = this.studentEvaluationGenerator.evaluationHeader[i];
        sum += GradeFunctions.CalculatateMarkFromFormule(st.eM[i], header.maxCompCountOnWeeks,
          header.maxRanking,
          header.calculationTypeID,
          header.maxMark);
      }
    }

    st.sumMarks = (Math.round(sum * 100) / 100).toString();
    return sum;
  }


}
