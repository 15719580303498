import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';
//import { map } from 'rxjs/operators';
import { map, tap } from 'rxjs/operators';
//import 'rxjs/add/operator/toPromise';
import { Observable } from 'rxjs';
import { IUserResponse, User } from '../../Models/Users';
import { StudAnketaData } from '../../Models/StudAnketa';



@Injectable()
export class ProfileService {

  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  private headers2 = new HttpHeaders({ 'Content-Type': 'multipart/form-data' });
  private heroesUrl = '/';
  constructor(private http: HttpClient) { }


  StudentAnkData(): Promise<any> {

    //let body = {
    //  Id: sylabusId
    //};
    return this.http.post("/api/Profile/StudentAnkData", null, { headers: this.headers })
      .toPromise()
      .then(response => response)
      .catch(this.handleError);
  }

  UpdateStudentAnkByStudentData(profile: StudAnketaData): Promise<any> {

    //let body = {
    //  Id: sylabusId
    //};
    return this.http.post("/api/Profile/UpdateStudentAnkByStudentData", profile, { headers: this.headers })
      .toPromise()
      .then(response => response)
      .catch(this.handleError);
  }

  private handleError(error: any): Promise<any> {
    console.error('An error occurred', error); // for demo purposes only
    return Promise.reject(error.message || error);
  }
}
