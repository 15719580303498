<div class="_cs_flex _cs_flex-a--center _cs_color-bg-gradient--primary-3 _cs_position-absolute _cs_position-left-px--0 _cs_position-up-px--0 _cs_size-w-percent--100 _cs_p-top--sm _cs_p-down--sm _cs_p-left--md _cs_p-right--md">

  <span class="_cs_label _cs_label-lg _cs_label-font-setting-case-on _cs_label-500 _cs_color-primary-5" *ngIf="data.languageId==1">
    შეტყობინება
  </span>
  <span class="_cs_label _cs_label-lg _cs_label-font-setting-case-on _cs_label-500 _cs_color-primary-5" *ngIf="data.languageId==2">
    Notification
  </span>
  <button class="_cs_m-left--auto _cs_icon _cs_icon-lg _cs_border-radius--full _cs_shadow-primary-0-0--3 _cs_color-bg--primary-5"
          [mat-dialog-close]="true">
    <span class="_cs_icon _cs_icon-xs _cs_icon-cross _cs_color-primary-2"></span>
  </button>
</div>

<mat-dialog-content>
  <div style="color:black;font-size:12pt;font-family:Calibri,Arial,Helvetica,sans-serif;" *ngIf="data.languageId==1">
    <p style="font-size:11pt;font-family:Calibri,sans-serif;margin:0 0 8pt 0;line-height:107%;">
      <b>
        <span style="color:black;font-family:Sylfaen,serif;">გთხოვთ, შეავსოთ სტუდენტის კითხვარი,აკადემიური და მოწვეული პერსონალის</span><span style="color:#C00000;font-family:Sylfaen,serif;"><span style="color:black;">&nbsp; </span></span><span style="color:black;font-family:Sylfaen,serif;">
          და
          სასწავლო კურსის შეფასების შესახებ''. გამოკითხვის მიზანია, უნივერსიტეტის ადმინისტრაციამ დეტალურად იკვლიოს სტუდენტის მოსაზრებები სწავლებასა და ადმინისტრირებასთან დაკავშირებით სასწავლო პროცესის გაუმჯობესებისთვის.
        </span><span style="color:#C00000;font-family:Sylfaen,serif;"><span style="color:black;">&nbsp;</span></span>
      </b>
    </p>
    <p style="font-size:11pt;font-family:Calibri,sans-serif;margin:0 0 8pt 0;line-height:107%;">
      <b><span style="color:black;font-family:Sylfaen,serif;">თქვენ მიერ მოწოდებული ინფორმაცია მნიშვნელოვანია, იყავით გულწრფელები და გაგვიზიარეთ თქვენი მოსაზრებები.</span><span style="color:#C00000;font-family:Sylfaen,serif;"><span style="color:black;">&nbsp;</span></span></b>
    </p>
    <p style="font-size:11pt;font-family:Calibri,sans-serif;margin:0 0 8pt 0;line-height:107%;">
      <b><span style="color:black;font-family:Sylfaen,serif;">კითხვარის შესავსებად დაგჭირდებათ დაახლოებით 5-10 წუთი.&nbsp; წინასწარ მადლობას მოგახსენებთ თანამშრომლობისთვის!</span><span style="color:#C00000;font-family:Sylfaen,serif;"><span style="color:black;">&nbsp;</span></span></b>
    </p>
  </div>
  <div style="color:black;font-size:12pt;font-family:Calibri,Arial,Helvetica,sans-serif;" *ngIf="data.languageId==2">
    Please, Fill in the questionnaire about ,,evaluation of academic and invited staff and teaching Course''
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">

  <button mat-button [mat-dialog-close]="true">
    <span *ngIf="data.languageId==1">დახურვა</span>
    <span *ngIf="data.languageId==2">Close</span>
  </button>
</mat-dialog-actions>
