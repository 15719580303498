
export class StudyMaterial {
  public id: number | null;
  public groupId: number | null;
  public weekId: number | null;
  public fileStreamId: string | null;
  public forDayDate: Date | null;
  public weekName: string | null;
  public description: string | null;
  public fileName: string | null;
}

export class StudyMaterilaGroupData {
  public studyMaterialList: Array<StudyMaterial> | null;
  public groupId: number;
}
