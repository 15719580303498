<div>
  <div *ngIf="error">
    {{ error.message }}
  </div>
  <div *ngIf="uploadResponse.status === 'error'">
    {{ uploadResponse.message }}
  </div>
  <div *ngIf="uploadResponse.status === 'progress'">


    <div role="progressbar" [style.width.%]="uploadResponse.message" aria-valuenow="25" aria-valuemin="0"
      aria-valuemax="100">
      {{uploadResponse.message}}%
    </div>

  </div>

  <form class="_cs_flex _cs_m-down--lg" [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="_cs_upload">
      <button class="_cs_button _cs_button-sm  _cs_button-border--positive">
        <span class="_cs_icon _cs_icon-sm _cs_icon-hiring _cs_color-primary-6"></span>
        <span class="_cs_label _cs_label-sm _cs_label-font-setting-case-on">{{captions.fileSelect}}</span>
      </button>
      <input type="file" name="avatar" (change)="onFileChange($event)" />
    </div>
    <button type="submit" class="_cs_m-left--md _cs_button _cs_button-sm  _cs_button-border--positive">
      <span class="_cs_icon _cs_icon-sm _cs_icon-upload _cs_color-primary-6"></span>
      <span class="_cs_label _cs_label-sm _cs_label-font-setting-case-on">{{captions.upload}} </span>
    </button>

  </form>
</div>
