import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Caption, PortalLanguageCaption } from '../LanguageModel/Caption';
import { LanguageService } from '../Service/LanguageService';
import { HttpClient } from '@angular/common/http';
import { InformationBoardService } from '../Student/Service/InformationBoardService';
import { Infofilter, InformationBoardResult, InformationBoardList } from '../Models/InformationBoardModel';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { SharedService } from '../Student/Service/SharedService';
import { NestedTreeControl, FlatTreeControl } from '@angular/cdk/tree';
import { MatTreeNestedDataSource, MatTreeFlattener, MatTreeFlatDataSource } from '@angular/material/tree';

import { ViewNewsComponent } from './ViewNews/view-news/ViewNews';
import { merge ,of as observableOf } from 'rxjs';
import { switchMap, map, catchError } from 'rxjs/operators';
import { ChartType, ChartOptions } from 'chart.js';
import { SingleDataSet, Label, BaseChartDirective } from 'ng2-charts';
import { CardService } from '../Student/Service/CardService';
import { StudentProfileData } from '../Models/StudentProfile';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent {
  public studentsSubjects: StudentProfileData[];
  isDataLoaded: boolean = false;
  public captions: Caption;

  public pieChartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    title: {
      text: this.isCaptionNull().executedCreditsBySemester,
      display: true
    }
  };
  public pieChartOptions1: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    title: {
      text: this.isCaptionNull().GPAsemester,
      display: true
    }
  };
  public pieChartOptions2: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    title: {
      text: this.isCaptionNull().secondaryArithmeticSemester,
      display: true
    }
  };


  isCaptionNull(): Caption {
    if (this.captions != null) {
      return this.captions;
    }
    else {
      this.captions = this.captions = PortalLanguageCaption[parseInt(localStorage.getItem("language")) - 1];
      return this.captions;
    }
  }


  public pieChartLabels: Label[] = new Array<Label>(); //= ['Download Sales', 'In-Store Sales', 'Mail Sales'];
  public pieChartData: SingleDataSet = new Array<number>(); //= [300, 500, 100];
  public pieChartType: ChartType = 'doughnut';
  public pieChartLabels1: Label[] = new Array<Label>(); //= ['Download Sales', 'In-Store Sales', 'Mail Sales'];
  public pieChartData1: SingleDataSet = new Array<number>(); //= [300, 500, 100];
  public pieChartType1: ChartType = 'doughnut';
  public pieChartLabels2: Label[] = new Array<Label>(); //= ['Download Sales', 'In-Store Sales', 'Mail Sales'];
  public pieChartData2: SingleDataSet = new Array<number>(); //= [300, 500, 100];
  public pieChartType2: ChartType = 'doughnut';
  public pieChartLegend = true;
  public pieChartPlugins = [{
    afterLayout: function (chart) {
      chart.legend.legendItems.forEach(
        (label) => {
          let value = chart.data.datasets[0].data[label.index];

          label.text += ' ' + value;
          return label;
        }
      )
    }
  }];
  public dynHeight = 250;
  public dynWidth = 300;
  @ViewChild(BaseChartDirective, { static: false }) chart: BaseChartDirective;
  direction = 100;

  
  public informationBoardResult: InformationBoardResult;
  public infoTypeList: any;
  activeNode: any;
  resultsLength = 0;
  isLoadingResults = false;
  isRateLimitReached = false;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  public _filter: Infofilter = new Infofilter();
  //public treeControl = new NestedTreeControl<boardType>(node => node.children);
  
  hasChild = (_: number, node: ExampleFlatNode) => node.expandable;
  private _transformer = (node: boardType, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.name,
      level: level,
      id: node.id,
      icon: node.icon
    };
  }

  treeControl = new FlatTreeControl<ExampleFlatNode>(
    node => node.level, node => node.expandable);

  treeFlattener = new MatTreeFlattener(
    this._transformer, node => node.level, node => node.expandable, node => node.children);

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  constructor(public dialog: MatDialog,
    private activatedRoute: ActivatedRoute, private route: ActivatedRoute,

    private router: Router,
    public LanguageService: LanguageService,
    public informationBoardService: InformationBoardService,
    public sharedService: SharedService,
    public cardService: CardService,
    private http: HttpClient) {
    

    
   
  }


  ngOnInit() {
    this.captions = PortalLanguageCaption[parseInt(localStorage.getItem("language")) - 1];
    //this.isCaptionNull();

    this.sharedService.GetInformationBoardTypes().then(e => {
      //this._filter.infoTypes = new Array<number>();
      //this._filter.infoTypes.push(e[0].id);
      this._filter.take = 10;
      this._filter.skip = 0;
      this.informationBoardService.GetInfoList(this._filter).then(e => { this.informationBoardResult = e; this.resultsLength = e.count; });
      this.GenerateTypeMap(e);
    });


    this.cardService.StudentProfile().then(e => {
      this.studentsSubjects = e.studentProfile;
      let yearS = new Array<StudyYearSemesterGroup>();
      let CurrentYear = "";
      let CurrentSemester = "";
      this.isDataLoaded = false;
      
      for (let i = 0; i < this.studentsSubjects.length; i++) {
        if (CurrentYear == this.studentsSubjects[i].yearName && CurrentSemester == this.studentsSubjects[i].semName) {
          let cur = yearS.filter(e => e.studyYear == CurrentYear && e.studySemester == CurrentSemester)[0];
          cur.subjectCount += 1;
          cur.sumCredits += (this.studentsSubjects[i].enrollStatusId == 1 ? this.studentsSubjects[i].credit : 0);
          cur.sumGPA += (this.studentsSubjects[i].gpa * this.studentsSubjects[i].credit);
          cur.sumMark += this.studentsSubjects[i].sumMark;
          cur.sumCreditsGPA += this.studentsSubjects[i].credit;
        }       
        else {
          CurrentYear = this.studentsSubjects[i].yearName;
          CurrentSemester = this.studentsSubjects[i].semName;
          yearS.push({
            studyYear: this.studentsSubjects[i].yearName,
            studySemester: this.studentsSubjects[i].semName, subjectCount: 1, sumCredits: (this.studentsSubjects[i].enrollStatusId == 1 ? this.studentsSubjects[i].credit : 0),
            sumMark: this.studentsSubjects[i].sumMark, sumGPA: (this.studentsSubjects[i].gpa * this.studentsSubjects[i].credit), type: 1, sumCreditsGPA: this.studentsSubjects[i].credit
          })
        }
      }
      for (let lt = 0; lt < yearS.length; lt++) {
        this.pieChartLabels.push(yearS[lt].studyYear + ", " + yearS[lt].studySemester);
        this.pieChartData.push(yearS[lt].sumCredits);
        this.pieChartLabels1.push(yearS[lt].studyYear + ", " + yearS[lt].studySemester);
        this.pieChartData1.push(Math.round((yearS[lt].sumGPA / yearS[lt].sumCreditsGPA)*100)/100);
        this.pieChartLabels2.push(yearS[lt].studyYear + ", " + yearS[lt].studySemester);
        this.pieChartData2.push(Math.round((yearS[lt].sumMark / yearS[lt].subjectCount)*100)/100);
      }

      setTimeout(() => {
        this.isDataLoaded = true;
      }, 0);
      //this.pieChartLabels=
    });

    //merge(//this.sort.sortChange,
    //  this.paginator.page)
    //  .pipe(
    //    //startWith({}),
    //    switchMap(() => {
    //      this.isLoadingResults = true;
    //      this._filter.take = this.paginator.pageSize;
    //      this._filter.skip = (this.paginator.pageIndex * this.paginator.pageSize);
    //      return this.informationBoardService.GetInfoList(this._filter);
    //    }),
    //    map(data => {
    //      // Flip flag to show that loading has finished.
    //      this.isLoadingResults = false;
    //      this.isRateLimitReached = false;
    //      this.resultsLength = data.count;

    //      return data.informationBoardList;
    //    }),
    //    catchError(() => {
    //      this.isLoadingResults = false;
    //      // Catch if the GitHub API has reached its rate limit. Return empty data.
    //      this.isRateLimitReached = true;
    //      return observableOf([]);
    //    })
    //).subscribe(data => this.informationBoardResult.informationBoardList = data);



      //setInterval(() => {
      //  this.dynHeight += this.direction;
      //  if (this.dynHeight >= 800) {
      //    this.direction = -100;
      //  } else if (this.dynHeight <= 300) {
      //    this.direction = 100;
      //  }
      //  this.pieChartOptions = { ...this.pieChartOptions }; // This is a hack to force refresh
      //}, 1000);

  }


  BehavRet(image:string): BehaviorSubject<string> {
    return new BehaviorSubject(image);
  }

  OpenFullData(v: InformationBoardList): void {
    let dialogRef = this.dialog.open(ViewNewsComponent, {
      panelClass: 'my-centered-dialog',
      height: '80%',
      width: '80%',
      disableClose: true,
      data: v
    });

    dialogRef.afterClosed().subscribe(result => {

      //this.animal = result;
    });
  }
  FilterNews(node: ExampleFlatNode) {
    
   
    this._filter.infoTypes = new Array<number>();
    this._filter.infoTypes.push(node.id);
    this._filter.take = 10;
    this._filter.skip = 0;
    this.informationBoardService.GetInfoList(this._filter).then(e => { this.informationBoardResult = e; this.resultsLength = e.count; });
  }
  GenerateTypeMap(data: any) {
    let boardTypeList = new Array<boardType>();
    let boardChilde = new Array<boardType>();
    for (let i = 0; i < data.length; i++) {
      if (data[i].parentid == null) {
        boardTypeList.push({ id: data[i].id, parentid: data[i].parentid, name: data[i].text, icon: data[i].icon });
      }
      else {
        boardChilde.push({ id: data[i].id, parentid: data[i].parentid, name: data[i].text, icon: data[i].icon });
      }
    }
    for (let i = 0; i < boardTypeList.length; i++) {
      this.SubTypeGenerate(boardTypeList[i], boardChilde);
    }
    this.dataSource.data = boardTypeList;
    console.log(boardTypeList);
  }

  SubTypeGenerate(board: boardType, array: Array<boardType>) {
    for (let i = 0; i < array.length; i++) {
      if (board.id == array[i].parentid) {
        if (board.children == null) {
          board.children = new Array<boardType>();
        }
        board.children.push(array[i]);
        this.SubTypeGenerate(array[i], array);
      }
    }
  }

}
interface boardType {
  id: number;
  name: string;
  parentid: number;
  icon: string;
  children?: boardType[];
}
interface ExampleFlatNode {
  expandable: boolean;
  name: string;
  level: number;
  icon: string;
  id: number;
}


export class StudyYearSemesterGroup {
  studyYear: string;
  studySemester: string;
  sumCredits: number;
  sumCreditsGPA: number;
  sumMark: number;
  sumGPA: number;
  subjectCount: number;
  type: number;
}
