import { Location, registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/ka';
import { Component, ViewChild } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { CheckBoxComponent } from '@syncfusion/ej2-angular-buttons';
import { AgendaService, DayService, DragAndDropService, EventRenderedArgs, EventSettingsModel, MonthService, ResizeService, ScheduleComponent, TimeScaleModel, View, WeekService, WorkWeekService } from '@syncfusion/ej2-angular-schedule';
import { extend, Internationalization, L10n, loadCldr } from '@syncfusion/ej2-base';
import { Predicate, Query } from '@syncfusion/ej2-data';
import { UserService } from '../../Identity/_services';
import { Caption, PortalLanguageCaption } from '../../LanguageModel/Caption';
import { georgian } from '../../LanguageModel/Caption.GE';
// import { User } from '../../../identity/_models';
import { ClassRoomsListForTree } from '../../Models/ClassRooms';
import { LanguageService } from '../../Service/LanguageService';
import { RegistrationService } from '../Service/RegistrationService';
import { SharedService } from '../Service/SharedService';



registerLocaleData(localeEs);



declare var require: any


if (parseInt(localStorage.getItem("language")) == 1) {
  loadCldr(
    require('cldr-data/supplemental/numberingSystems.json'),
    require('../../LanguageJson/ka.json'),
    require('cldr-data/main/en-GB/numbers.json'),
    require('cldr-data/main/en-GB/timeZoneNames.json'));

  L10n.load(georgian);
}
else {
  loadCldr(
    require('cldr-data/supplemental/numberingSystems.json'),
    require('cldr-data/main/en-GB/ca-gregorian.json'),
    require('cldr-data/main/en-GB/numbers.json'),
    require('cldr-data/main/en-GB/timeZoneNames.json'));
}





@Component({
  selector: 'AudExamSchedule',
  templateUrl: './AudExamSchedule.html',
  styleUrls: ['./AudExamSchedule.css'],
  providers: [DayService, WeekService, WorkWeekService, MonthService, AgendaService, ResizeService, DragAndDropService]
})
/** schedule component*/
export class AudExamSchedule {

  public selectedDate: Date = new Date(Date.now());
  public eventSettings: EventSettingsModel;
  public timeScale: TimeScaleModel = {
    enable: true,
    //interval: 60,
    //slotCount: 1,
    majorSlotTemplate: '#majorSlotTemplate'//,
    //minorSlotTemplate: '#minorSlotTemplate'
  };
  @ViewChild('schedule', { static: false }) scheduleObj: ScheduleComponent;
  @ViewChild('ownerOneObj') ownerOneObj: CheckBoxComponent;
  @ViewChild('ownerTwoObj') ownerTwoObj: CheckBoxComponent;


  selectedItemNumber: number = 1;
  public captions: Caption;
  audList = new Array<ClassRoomsListForTree>();
  public currentView: View = 'Month';
/** schedule ctor */
  public ownerCollections: Object[];

  public resourceDataSource: Object[];


  public allowMultiple: Boolean = true;
  language: number = null;
  shedulerShow: boolean = null;
  constructor(private activatedRoute: ActivatedRoute,
    private route: ActivatedRoute,
    private location: Location,
    private StudListService: RegistrationService,
    private UserService: UserService,
    private SharedService: SharedService,
    private router: Router, private fb: UntypedFormBuilder,
    public LanguageService: LanguageService, private _snackBar: MatSnackBar) {
    this.language = parseInt(localStorage.getItem("language"));
    this.captions = PortalLanguageCaption[parseInt(localStorage.getItem("language")) - 1];
    
    this.ownerCollections = [
      { OwnerText: this.captions.LearnScheduler, OwnerId: 1, Color: '#f57f17' },
      { OwnerText: this.captions.ExmaScheduler, OwnerId: 2, Color: '#49f149' },
    ];
    this.resourceDataSource = this.ownerCollections;
    this.SharedService.GetClassRooms().then(e => {
      this.audList = e
    });
    this.LoadTimeTable();
    
  }



  LoadTimeTable() {


    let scheduleData = new Array<Object>();

    this.StudListService.GetSelectedCoursesTimeTable().then(e => {

      let timeTable = e;



      let scheduleData = new Array<Object>();
      for (let i = 0; i < timeTable.length; i++) {
        try {
          let localData = new Date();
          let date: string[] = timeTable[i].date!=null? timeTable[i].date.split("T")[0].split("-"):["",""];
          let time: string[] = timeTable[i].date!= null? timeTable[i].date.split("T")[1].split(":") : ["", ""];
          var currentDay = localData.getDay();
          if (timeTable[i].weekId != null) {
            var distance = timeTable[i].weekId - currentDay;
            localData.setDate(localData.getDate() + distance);
          }

          let timeStart = timeTable[i].startTime != null ? new Date(localData.getFullYear(), localData.getMonth(), localData.getDate(), parseInt(timeTable[i].startTime.substring(0, 2)), parseInt(timeTable[i].startTime.substring(3, 5))) : timeTable[i].date;
          let timeEnd = new Date(localData.getFullYear(), localData.getMonth(), localData.getDate(),
            parseInt(timeTable[i].endTime.substring(0, 2)),
            parseInt(timeTable[i].endTime.substring(3, 5)));
          let dateEnd = timeTable[i].startTime == null ? new Date(Number.parseInt(date[0]), Number.parseInt(date[1]) - 1, Number.parseInt(date[2]),
            parseInt(timeTable[i].endTime.substring(0, 2)),
            parseInt(timeTable[i].endTime.substring(3, 5))) : null;


          if (timeTable[i].startTime == null) {
            scheduleData.push({
              Id: (i + 1),
              Subject: timeTable[i].syllabusName,
              Location: (e[i].remoteRoomType != null && e[i].remoteRoomType.length > 2 ? e[i].remoteRoomType : this.ClassRoomRootShow(timeTable[i].classRoomsId)),
              Description: this.captions.lecturers + ":" + timeTable[i].fullName + ", " + this.captions.lectureType + ":" + timeTable[i].lectureTypeName + (e[i].remoteRoomType != null && e[i].remoteRoomType.length > 2 ? "<br/>" + e[i].remoteRoomDescription : ""),
              StartTime: timeTable[i].date != null ? (new Date(Number.parseInt(date[0]), Number.parseInt(date[1]) - 1, Number.parseInt(date[2]),
                Number.parseInt(time[0]), Number.parseInt(time[1]))) : timeStart,
              EndTime: dateEnd != null ? dateEnd : timeEnd,
              CategoryColor: '#f57f17',
              OwnerId: 1
            });
          }
          else {
            for (let k = 0; k < 6; k++) {
              let start = timeTable[i].startTime != null ? new Date(localData.getFullYear(), localData.getMonth(), localData.getDate() + (k * 7), parseInt(timeTable[i].startTime.substring(0, 2)), parseInt(timeTable[i].startTime.substring(3, 5))) : timeTable[i].date;
              let end = new Date(localData.getFullYear(), localData.getMonth(), localData.getDate() + (k * 7), parseInt(timeTable[i].endTime.substring(0, 2)), parseInt(timeTable[i].endTime.substring(3, 5)));
              scheduleData.push({
                Id: (i + k + 1),
                Subject: timeTable[i].syllabusName,
                Location: (e[i].remoteRoomType != null && e[i].remoteRoomType.length > 2 ? e[i].remoteRoomType : this.ClassRoomRootShow(timeTable[i].classRoomsId)),
                Description: this.captions.lecturers + ":" + timeTable[i].fullName + ", " + this.captions.lectureType + ":" + timeTable[i].lectureTypeName + (e[i].remoteRoomType != null && e[i].remoteRoomType.length > 2 ? "<br/>" + "<a target='_blank' href='" + e[i].remoteRoomDescription + "'>" + e[i].remoteRoomDescription+"</a>" : ""),
                StartTime: start,
                EndTime: end,
                CategoryColor: '#f57f17',
                OwnerId: 1
              });
            }
          }
        }
        catch (exeption) {
          console.log(exeption);
        }
      }


      this.StudListService.GetExamTimeTable().then(e => {
        for (let i = 0; i < e.length; i++) {
          try {
            let date: string[] = e[i].date.split("T")[0].split("-");
            let time: string[] = e[i].date.split("T")[1].split(":");
            scheduleData.push({
              Id: (i + 1),
              Subject: e[i].syllabusName,
              Location: e[i].roomId != null ? this.ClassRoomRootShow(e[i].sectorId == null ? e[i].roomId : e[i].sectorId) + (e[i].placeNum != null ? "/" + e[i].placeNum.toString() : "") : e[i].remoteRoomType,
              Description: e[i].accessOnExam ?e[i].remoteRoomDescription:this.captions.accessFailedOnSubject ,
              StartTime: new Date(Number.parseInt(date[0]), Number.parseInt(date[1]) - 1, Number.parseInt(date[2]),
                Number.parseInt(time[0]), Number.parseInt(time[1])),
              EndTime: new Date((new Date(Number.parseInt(date[0]), Number.parseInt(date[1]) - 1, Number.parseInt(date[2]),
                Number.parseInt(time[0]), Number.parseInt(time[1]))).getTime() + e[i].durationMin * 60000),
              CategoryColor: e[i].accessOnExam ? '#49f149' :"#FF0000",
              OwnerId: 2
            });
          }
          catch (exeption) {
            console.log(exeption);
          }
        }

       

        this.eventSettings = {
          dataSource: <Object[]>extend([], scheduleData, null, true), allowEditing: false, allowAdding: false, allowDeleting: false, enableTooltip: true,
        };
      });

    });

   
  }
  public instance: Internationalization = new Internationalization();
  getMajorTime(date: Date): string {
    return this.instance.formatDate(date, { skeleton: 'Hm' });
  }
  getMinorTime(date: Date): string {
    return this.instance.formatDate(date, { skeleton: 'ms' }).replace(':00', '');
  }

  ClassRoomRootShow(classRoomId: number): string {

    let parentId = 0;
    let parentRoom = "";
    for (let i = 0; i < this.audList.length; i++) {
      if (this.audList[i].id == classRoomId) {
        parentId = this.audList[i].parentid;
        parentRoom = this.audList[i].text;
        break;
      }
    }
    let v = new Array<string>();
    this.ClassRoomParentShow(parentId, v);
    let retS = "";
    for (let i = (v.length - 1); i >= 0; i--) {
      retS += v[i] + "/";
    }

    retS += parentRoom;
    return retS;
  }
  ClassRoomParentShow(classRoomId: number, v: Array<string>): void {

    for (let i = 0; i < this.audList.length; i++) {
      if (this.audList[i].id == classRoomId) {
        v.push(this.audList[i].text);
        this.ClassRoomParentShow(this.audList[i].parentid, v);
        break;
      }
    }
  }

  onEventRendered(args: EventRenderedArgs): void {
    let categoryColor: string = args.data.CategoryColor as string;
    if (!args.element || !categoryColor) {
      return;
    }
    if (this.scheduleObj.currentView === 'Agenda') {
      (args.element.firstChild as HTMLElement).style.borderLeftColor = categoryColor;
    } else {
      args.element.style.backgroundColor = categoryColor;
    }
  }

  onChange(): void {
    let predicate: Predicate;
    let checkBoxes: CheckBoxComponent[] = [this.ownerOneObj, this.ownerTwoObj];
    checkBoxes.forEach((checkBoxObj: CheckBoxComponent) => {
      if (checkBoxObj.checked) {
        if (predicate) {
          predicate = predicate.or('OwnerId', 'equal', parseInt(checkBoxObj.value, 10));
        } else {
          predicate = new Predicate('OwnerId', 'equal', parseInt(checkBoxObj.value, 10));
        }
      }
    });
    this.scheduleObj.eventSettings.query = new Query().where(predicate);
  }


}
