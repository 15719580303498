<div class="_cs_flex _cs_size-w-percent--100">
  <div class="_cs_size-w-percent--100 _cs_p-left--lg _cs_p-right--lg _cs_p-down--lg">
    <div class="_cs_size-h-percent--100 sidebarbg _cs_flex">
      <!-- sidebarbg__sidebar -->
      <div class="sidebarbg__wrapper _cs_p-left--none-i _cs_size-w-percent--100 _cs_flex _cs_flex-d--column">
        <div class="_cs_m-top--xxl">
          <div class="_cs_p-xs _cs_shadow-primary-0-0--1 _cs_border-radius--sm _cs_border-down--primary-6 _cs_flex _cs_flex-a--center">
            <span class="_cs_icon _cs_icon-xl _cs_shadow-primary-6-0--2 _cs_border-radius--full _cs_border--primary-6 _cs_m-right--md">
              <span class="_cs_icon _cs_icon-md _cs_color-primary-6 _cs_icon-student-card"></span>

            </span> <span class="_cs_label _cs_label-sm _cs_label-font-setting-case-on _cs_label-bold _cs_color-primary-6">{{captions.quiz}}</span>
          </div>
        </div>

        <div>
          <div class="  _cs_size-w-percent--100 _cs_m-top--lg">
            <table class='table table-bordered'>
              <thead>
                <tr style="font-size:16px">
                  <th>#</th>
                  <th>{{captions.quiz}}</th>
                  <th>{{captions.status}}</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let sl of quizList; let i=index" [ngClass]="{'blockFilled' :sl.complete==true, 'blockIsNotFilled':sl.complete==false}">
                  <td>{{i+1}}</td>
                  <td>{{sl.subjectName}}-{{sl.caption}}</td>
                  <td> {{sl.complete?captions.filled:captions.forFill}}</td>
                  <td><button (click)="LoadQuestiond(sl)">{{captions.open}}</button></td>
                </tr>
              </tbody>
            </table>

            <div *ngFor="let sl of quizQuestions; let i=index">
              <div class="question">{{sl.caption}}</div>
              <div [ngSwitch]="sl.typeId">
                <div *ngSwitchCase="1">
                  <mat-radio-group class="example-radio-group">
                    <mat-radio-button class="example-radio-button" *ngFor="let an of sl.answers" [value]="an.id"
                                      [(checked)]="an.answerSelected" (change)="onChange($event,sl,an)">
                      {{an.caption}}
                      <br *ngIf="an.other==true" />
                      <textarea matImput *ngIf="an.other==true" ref-textarea [(ngModel)]="an.answerText" rows="1" style="width:450px"></textarea>
                    </mat-radio-button>
                  </mat-radio-group>
                </div>
                <div *ngSwitchCase="2">
                  <div *ngFor="let an of sl.answers">
                    <mat-checkbox [value]="an.id" [(ngModel)]="an.answerSelected">
                      {{an.caption}}
                      <br *ngIf="an.other==true" />
                      <textarea matImput *ngIf="an.other==true" [(ngModel)]="an.answerText" rows="1" style="width:450px"></textarea>
                    </mat-checkbox>
                  </div>
                </div>
                <div *ngSwitchCase="3">
                  <div *ngFor="let an of sl.answers">
                    <p>{{an.caption}}</p>
                    <mat-form-field class="example-full-width" data-id="{{an.caption}}">
                      <textarea matInput placeholder={{captions.pleasFill}} ref-textarea [(ngModel)]="an.answerText" rows="4" (change)="onChangeText($event,sl,an)"></textarea>
                    </mat-form-field>
                  </div>
                </div>
                <div *ngSwitchCase="4">

                  <table class='table table-bordered table-hover '>
                    <tbody>
                      <tr *ngFor="let an of sl.answers">

                        <td> {{an.caption}}</td>
                        <td>
                          <mat-radio-group [id]="an.id" [value]="an.id">
                            <mat-radio-button class="mat-radio-button1" *ngFor="let san of an.subAnswers"
                                              [value]="san.id" [checked]="san.id==an.subAnswersSelected?true:false"
                                              (change)="onChangeSub($event,an,sl)">
                              {{san.caption}}
                            </mat-radio-button>
                          </mat-radio-group>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div *ngSwitchDefault>Error</div>
              </div>
            </div>

            <button mat-raised-button class="butsaveCol" (click)="SaveQuizResult()" *ngIf="quizQuestions">{{captions.saveResult}}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
