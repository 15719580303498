
<div
  class="_cs_flex _cs_flex-a--center _cs_color-bg-gradient--primary-3 _cs_position-absolute _cs_position-left-px--0 _cs_position-up-px--0 _cs_size-w-percent--100 _cs_p-top--sm _cs_p-down--sm _cs_p-left--md _cs_p-right--md">
  <span class="_cs_label _cs_label-lg _cs_label-font-setting-case-on _cs_label-500 _cs_color-primary-5">{{captions.subjectSelect}}</span>
  <button
    class="_cs_m-left--auto _cs_icon _cs_icon-lg _cs_border-radius--full _cs_shadow-primary-0-0--3 _cs_color-bg--primary-5"
    [mat-dialog-close]="true">
    <span class="_cs_icon _cs_icon-xs _cs_icon-cross _cs_color-primary-2"></span>
  </button>
</div>
<mat-dialog-content>
  <!--<div *ngFor="let grType of GroupsTypes" *ngIf="isNotStructured">-->

  <div *ngFor="let groupData of courseCroupTypesWithGroupsDataList">
    <div class="_cs_flex _cs_flex-a--center _cs_p-md _cs_shadow-primary-0-0-1">
      <span class="_cs_label _cs_label-bold _cs_color-primary-6 _cs_label-font-setting-case-on _cs_m-right--md">{{captions.lectureType}}:</span>
      <span class="_cs_label _cs_label-bold _cs_color-primary-2 _cs_label-font-setting-case-on">{{groupData.groupTypeCaption}} </span>
    </div>
    <table  class="table table-bordered table-hover">
      <thead>
        <tr>
          <th>#</th>
          <th>{{captions.group}}</th>
          <th>{{captions.timeTable}}</th>
          <th>{{captions.totalPlace}}</th>
          <th>{{captions.selected}}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let groups of groupData.groupDataList; let i=index;" >
          <td>{{(1+i)}}</td>
          <td>{{groups.lectureNum}}</td>
          <td>
            <div *ngFor="let timeT of groups.timeTableOnLectures; let ti=index">
              <div>
                <span>{{(1+ti)}}. </span>
                <span>{{timeT.sName + " "+timeT.fName}}, </span>
                <span *ngIf="timeT.dayName">{{timeT.dayName}}, </span>
                <span>{{ClassRoomRootShow(timeT.classRoomsId)}}, </span>
                <span *ngIf="timeT.startTime"> {{timeT.startTime.toString().substring(0,5)}} - </span>
                <span *ngIf="timeT.date">{{timeT.date | date:'yyyy-MM-dd HH:mm'}} - </span>
                <span *ngIf="timeT.endTime">{{timeT.endTime.toString().substring(0,5)}}</span>
              </div>
            </div>
          </td>
          <td>
            <div *ngFor="let lq of groups.lectureQuotes; let lqi=index">
              <div>
                <span>{{(1+lqi)}}. </span>
                <span *ngIf="lq.facultyName">{{lq.facultyName}}, </span>
                <span *ngIf="lq.studyLevelName">{{lq.studyLevelName}}, </span>
                <span *ngIf="lq.minQuantities">{{lq.minQuantities}}, </span>
                <span><strong>{{captions.allocatedPlace}}:</strong> {{lq.maxQuantities}}</span>
                <span><strong>{{captions.theRemaining}}:</strong> {{lq.maxQuantities-lq.studQuantity}}</span>
              </div>
            </div>
          </td>
          <td>
            <mat-checkbox color="primary" [disabled]="groups.disabled" [(ngModel)]="groups.selected" (change)="SelectGroup(groups,groupData.groupDataList,$event.checked)"></mat-checkbox>
          </td>
        </tr>
      </tbody>
    </table>

  </div>
  <!--</div>-->


</mat-dialog-content>

<mat-dialog-actions align="end">
  <button class="_cs_button _cs_button-sm _cs_button-border--positive _cs_m-left--md"(click)="SaveSelectedGroups()">
    <span class="_cs_label _cs_label-sm _cs_label-font-setting-case-on">შენახვა, დახურვა</span>
  </button>
  <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->
  <button class="_cs_button _cs_button-sm _cs_button-border--positive _cs_m-left--md" [mat-dialog-close]="true">
    <span class="_cs_label _cs_label-sm _cs_label-font-setting-case-on">გაუქმება</span>
  </button>
</mat-dialog-actions>
