<div
  class="_cs_flex _cs_flex-a--center _cs_color-bg-gradient--primary-3 _cs_position-absolute _cs_position-left-px--0 _cs_position-up-px--0 _cs_size-w-percent--100 _cs_p-top--sm _cs_p-down--sm _cs_p-left--md _cs_p-right--md">
  <span class="_cs_label _cs_label-lg _cs_label-font-setting-case-on _cs_label-500 _cs_color-primary-5"
        *ngIf="data.formType==1">{{captions.createMessage}}</span>
  <span class="_cs_label _cs_label-lg _cs_label-font-setting-case-on _cs_label-500 _cs_color-primary-5"
        *ngIf="data.formType==3&&(correspondencStatementList!==null&&correspondencStatementList.length==1)">
    {{captions.readMessageAuthor}} :{{correspondencStatementList[0].sName+' '+correspondencStatementList[0].fName}})
  </span>
  <span class="_cs_label _cs_label-lg _cs_label-font-setting-case-on _cs_label-500 _cs_color-primary-5"
        *ngIf="data.formType==2">{{captions.answer}}</span>
  <button
    class="_cs_m-left--auto _cs_icon _cs_icon-lg _cs_border-radius--full _cs_shadow-primary-0-0--3 _cs_color-bg--primary-5"
    [mat-dialog-close]="true">
    <span class="_cs_icon _cs_icon-xs _cs_icon-cross _cs_color-primary-2"></span>
  </button>
</div>


<mat-dialog-content>

  <div class="_cs_flex _cs_flex-d--column">
    <div id="LetterType" class="_cs_size-w-percent--100 _cs_flex _cs_flex-wrap">
      <div class="_cs_size-w-percent--100 _cs_p-left--sm _cs_p-right--sm" *ngIf="data.formType==1">
        <mat-form-field class="_cs_size-w-percent--100 _cs_p-down--none">
          <mat-label>{{captions.messageType}}</mat-label>
          <mat-select [(value)]="selectedCoreType" (selectionChange)="LoadCurrentSemesterSubject($event.value)">
            <mat-option *ngFor="let c of correspondenceType" [value]="c">{{c.caption}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="_cs_size-w-percent--100 _cs_p-left--sm _cs_p-right--sm" *ngIf="data.formType==1||data.formType==2">
        <mat-form-field class="_cs_size-w-percent--100 _cs_p-down--none">
          <mat-label>{{captions.importanceLevel}}</mat-label>
          <mat-select [(value)]="selectedImportentStatus">
            <mat-option *ngFor="let c of importantStatus" [value]="c.id">{{c.caption}}</mat-option>
          </mat-select>
        </mat-form-field>

      </div>
      <div class="_cs_size-w-percent--100 _cs_p-left--sm _cs_p-right--sm" *ngIf="selectedCoreType&&selectedCoreType.studyCourseStudentSet">
        <mat-form-field class="_cs_size-w-percent--100 _cs_p-down--none">
          <mat-label>{{captions.subjectForExamCorrespondece}}</mat-label>
          <mat-select [(value)]="selectedCourseId">
            <mat-option *ngFor="let c of studentCurrentSessionSelectedSubjects" [value]="c.courseId">{{c.syllabusCaption}}</mat-option>
          </mat-select>
        </mat-form-field>

      </div>

    </div>

    <div *ngIf="data.formType==3&&correspondencStatementList!=null">
      <div class="_cs_m-down--md">
        <span class="_cs_label _cs_label-md _cs_label-font-setting-case-on _cs_color-primary-1">
          <b>{{captions.messageType}}:</b> {{correspondencStatementList[0].correspondeceTypeCaption}} <br />
          <b>{{captions.importanceLevel}}:</b> {{correspondencStatementList[0].importentStatusCaption}} <br />
          <b>{{captions.status}}:</b> {{correspondencStatementList[0].stateCaption}} <br />
        </span>
      </div>
    </div>

  </div>
  <div id="subject" class="_cs_flex">
    <div class="_cs_size-w-percent--100  _cs_p-left--sm _cs_p-right--sm">

      <mat-form-field
        class="_cs_size-w-percent--100 _cs_p-down--none"
        *ngIf="data.formType==1||data.formType==2">
        <input matInput placeholder={{captions.title}} [(ngModel)]="subjectText">
      </mat-form-field>
    </div>

  </div>
  <div id="description" class="_cs_size-w-percent--100  _cs_p-left--sm _cs_p-right--sm">
    <div *ngIf="correspondencStatementList==null||this.correspondencStatementList.length==1">
      <span class="_cs_label _cs_label-md _cs_label-font-setting-case-on _cs_color-primary-2 _cs_m-down--md">{{captions.content}}:</span>
      <div class="_cs_col-xs-12" style="height:300px">
        <angular-editor id="descriptionC" [placeholder]="'შეიყვანეთ აღწერილობა...'" [(ngModel)]="letterContent"></angular-editor>
      </div>
    </div>
    <div *ngIf="data.formType==2">

      <div class="_cs_col-xs-12" style="height:300px">
        <span class="_cs_label _cs_label-md _cs_label-font-setting-case-on _cs_color-primary-2 _cs_m-down--md">{{captions.answer2}}:</span>
        <angular-editor id="description" [placeholder]="'შეიყვანეთ აღწერილობა...'" [(ngModel)]="answerletterContent" ></angular-editor>
      </div>
    </div>
  </div>
  <div id="documents">
    <div>
      {{captions.documents}}:
    </div>
    <div *ngIf="data.formType!=3">
      <FileUpload (onFileUpload)="FileUploaded($event)">

      </FileUpload>
    </div>
    <table class="table-sm table-bordered">
      <thead>
       
        <tr>
          <th>#</th>
          <th>{{captions.file}}</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let f of correspondencAttachments; let i=index;">
          <td>{{(1+i)}}</td>
          <td> <button class="link" (click)="DownloadDocument(f.fileId)">{{f.fileName}}</button></td>
          <td><button (click)="RemoveFile('syllabusFiles',f)">{{captions.delete}}</button></td>
        </tr>
      </tbody>
    </table>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end" class="_cs_col-xs-12 _cs_p-left--sm _cs_p-right--sm">
  <button class="_cs_button _cs_button-sm _cs_button-border--positive _cs_m-left--md" (click)="SendLetter()" *ngIf="data.formType!=3">
    <span class="_cs_label _cs_label-sm _cs_label-font-setting-case-on">{{captions.send}}</span>
  </button>
  <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->
  <button class="_cs_button _cs_button-sm _cs_button-border--positive _cs_m-left--md" [mat-dialog-close]="true">
    <span class="_cs_label _cs_label-sm _cs_label-font-setting-case-on">{{captions.close}}</span>
  </button>
</mat-dialog-actions>
