import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { User } from '../_models';
import { map } from 'rxjs/internal/operators/map';
import { ChangePasswordData } from '../../Models/Profile';
import { StudentsListData } from '../../Models/StudentsList';
import { SelectedStudentUserMainData } from '../../Models/SelectedStudentUserMainData';
import { ReturnResultValue } from '../../Models/ResultReturnParameters';


@Injectable({ providedIn: 'root' })
export class UserService {
  private baseUrl = window.location.origin + '/api/Auth';
  private base2Url = window.location.origin + '/api/UserChange';

    constructor(private http: HttpClient) { }

    getAll() {
        return this.http.get<User[]>(`""/users`);
    }

    getById(id: number) {
        return this.http.get(`""/users/${id}`);
    }

    register(user: User) {
      return this.http.post(`${this.baseUrl}/InsertUser`, user);
    }

  GetStudentListOnPerson(peronId: number): Promise<Array<StudentsListData>> {
    return this.http.post<any>(`${this.base2Url}/GetStudentListOnPerson`, peronId).toPromise().catch(this.handleError);
  }

  ChangeDefaultStudent(studentId: number): Promise<SelectedStudentUserMainData> {
    return this.http.post<any>(`${this.base2Url}/ChangeDefaultStudent`, studentId).toPromise().catch(this.handleError);
  }


  changePassword(newPassword: ChangePasswordData): Promise<boolean> {
    

    return this.http.post<any>(`${this.baseUrl}/ChangePassword`, newPassword).toPromise().catch(this.handleError);
  }

  resetPassword(resetData: any): Promise<ReturnResultValue> {
    return this.http.post<any>(`${this.baseUrl}/ResetPassword`, resetData).toPromise().catch(this.handleError);
  }

  ResetCodeToEmail(resetData: any): Promise<ReturnResultValue> {
    
    return this.http.post<any>(`${this.baseUrl}/ResetCodeToEmail`, resetData).toPromise().catch(this.handleError);
  }


  private handleError(error: any): Promise<any> {
    console.error('An error occurred', error); // for demo purposes only
    return Promise.reject(error.message || error);
  }
}
