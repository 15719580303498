import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UIfilter } from '../../Models/filters';
import { StudyProgramStructure } from '../../Models/ProgramStructure';
import { ReturnResultValue } from '../../Models/ResultReturnParameters';


@Injectable()
export class StudyProgramService {

  private headers = new HttpHeaders({ 'Content-Type': 'application/json' });
  private headers2 = new HttpHeaders({ 'Content-Type': 'multipart/form-data' });
  private heroesUrl = '/';
  constructor(private http: HttpClient) { }


  GetProgramStructure(Id: any): Promise<any> {
    var pr = { Id: Id };
    return this.http.post("/api/StudyPrograms/GetStudyProgramStrucutre", pr, { headers: this.headers })
      .toPromise()
      .then(response => response)
      .catch(this.handleError);
  }
  GetSubProgramList(Id: number): Promise<Array<StudyProgramStructure>> {
    return this.http.post("/api/StudyPrograms/GetSubProgramList", Id, { headers: this.headers })
      .toPromise()
      .then(response => response)
      .catch(this.handleError);
  }
  GetStudyProgramsList(filter: UIfilter): Promise<any> {

    return this.http.post("/api/StudyPrograms/GetStudyProgramsList", filter, { headers: this.headers })
      .toPromise()
      .then(response => response)
      .catch(this.handleError);
  }

  GetStudyProgramData(Id: any): Promise<any> {
    var pr = { Id: Id };
    return this.http.post("/api/StudyPrograms/GetStudyProgramData", pr, { headers: this.headers })
      .toPromise()
      .then(response => response)
      .catch(this.handleError);
  }

  UpdateStudentSpecialization(Id: string): Promise<ReturnResultValue> {
    var pr = { id: Id };
    return this.http.post("/api/StudyPrograms/UpdateStudentSpecialization", pr, { headers: this.headers })
      .toPromise()
      .then(response => response)
      .catch(this.handleError);
  }


  private handleError(error: any): Promise<any> {
    console.error('An error occurred', error); // for demo purposes only
    return Promise.reject(error.message || error);
  }
}
