import { animate, state, style, transition, trigger } from '@angular/animations';
import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Caption, PortalLanguageCaption } from '../../LanguageModel/Caption';

@Component({
  selector: 'StudentFinanceMainForm',
  templateUrl: 'StudentFinanceMainForm.html',
  styleUrls: ['StudentFinanceMainForm.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0', display: 'none' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})



export class StudentFinanceMainForm implements OnInit {

  public captions: Caption;
  constructor(
    private route: ActivatedRoute,
    private location: Location,
    private router: Router) {

    this.captions = PortalLanguageCaption[parseInt(localStorage.getItem("language")) - 1];
  }

  ngOnInit() {
  }
}
