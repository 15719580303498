<div *ngIf="studAnketaData" class="_cs_flex _cs_size-w-percent--100">
  <div class="_cs_size-w-percent--100 _cs_p-left--lg _cs_p-right--lg _cs_p-down--lg">
    <div [ngClass]="status ? 'sidebarbg--hidden' : 'sidebarbg--show'"
      class="_cs_size-h-percent--100 sidebarbg _cs_flex">
      <!-- sidebarbg__sidebar -->
      <div
        class="sidebarbg__sidebar  _cs_border-radius--sm _cs_color-bg--primary-5 _cs_shadow-primary-0-0--1 _cs_m-right--md">
        <div class="_cs_position-absolute _cs_position-left-percent--100 _cs_position-up-px--70 dropmenu">
          <div id="nav-icon" class="dropsidebar" (click)="clickEvent()">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
        <mat-tree class="_cs_size-w-percent--100 _cs_size-h-percent--100 _cs_overflow-x--auto" [dataSource]="dataSource"
          [treeControl]="treeControl">
          <!-- This is the tree node template for leaf nodes -->
          <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
            <!-- use a disabled button to provide padding for tree leaf -->
            <button mat-icon-button disabled></button>

            <button
              class="_cs_p-right--xxl _cs_size-w-percent--100 _cs_color-bg--primary-5  _cs_p-sm _cs_size-h-percent--100 _cs_position-relative _cs_flex _cs_activeitem-opacity-bg-primary-6-0--1 _cs_transition-0--3 _cs_border-rgba-down-primary-6-0--1"
              (click)="FilterItem(node); activeNode = node;" [ngClass]="{ '_cs_active': activeNode === node }">
              <span class="{{node.icon}}"></span>
              <span
                class="_cs_m-left--sm _cs_label _cs_label-sm _cs_label-font-setting-case-on _cs_color-primary-2 _cs_activeitem-color--primary-6 _cs_activeitem-color-bg--rgba-primary  _cs_m-top--xs _cs_m-down--xs _cs_label-a-start">
                {{node.name}}
              </span>

            </button>
          </mat-tree-node>
          <!-- This is the tree node template for expandable nodes -->
          <mat-tree-node *matTreeNodeDef="let node;when: hasChild" matTreeNodePadding>
            <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'toggle ' + node.name">
              <mat-icon class="mat-icon-rtl-mirror">
                {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
              </mat-icon>
            </button>
            <div
              class="_cs_p-right--xxl _cs_size-w-percent--100 _cs_color-bg--primary-5  _cs_p-sm _cs_size-h-percent--100 _cs_position-relative _cs_flex _cs_activeitem-opacity-bg-primary-6-0--1 _cs_transition-0--3 _cs_border-rgba-down-primary-6-0--1">
              <span class="{{node.icon}}"></span>
              <span
                class="_cs_m-left--sm _cs_label _cs_label-sm _cs_label-font-setting-case-on _cs_color-primary-2 _cs_activeitem-color--primary-6 _cs_activeitem-color-bg--rgba-primary  _cs_m-top--xs _cs_m-down--xs _cs_label-a-start">

                {{node.name}}</span>
            </div>
          </mat-tree-node>
        </mat-tree>
      </div>

      <div class="sidebarbg__wrapper _cs_size-w-percent--100 _cs_flex _cs_flex-d--column _cs_p-left--md _cs_mat-tab">
        
        <div class="_cs_m-down--md _cs_m-top--xxl">
          <div
            class="_cs_p-xs _cs_shadow-primary-0-0--1 _cs_border-radius--sm _cs_border-down--primary-6 _cs_flex _cs_flex-a--center">
            <span
              class="_cs_icon _cs_icon-xl _cs_shadow-primary-6-0--2 _cs_border-radius--full _cs_border--primary-6 _cs_m-right--md">
              <span class="_cs_icon _cs_icon-md _cs_color-primary-6 _cs_icon-profile"></span>

            </span>
            <span class="_cs_label _cs_label-sm _cs_label-font-setting-case-on _cs_label-bold
               _cs_color-primary-6">{{captions.profile}}</span>
          </div>
        </div>
        <div class=" _cs_size-w-percent--100">
          <!-- <mat-tab-group class="_cs_size-w-percent--100 _cs_p-lg _cs_overflow-hidden _cs_shadow-primary-0-0--1 _cs_border-radius--sm _cs_p-top--none"> -->
          <div class="_cs_size-w-precent--100 " *ngIf="profileItemNumber==1">
            <div>
              <div>
                <div class="_cs_flex _cs_flex-d--column">
                  <div class="_cs_flex _cs_flex-wrap _cs_size-w-percent--100">
                    <div class="_cs_col-md-4 _cs_col-xs-12 _cs_col-sm-4 _cs_p-right--lg _cs_mobile-p--none">
                      <div class="_cs_m-down--lg _cs_p-lg _cs_border-radius--sm _cs_color-bg--primary-5 _cs_flex-d--column _cs_shadow-primary-0-0--1 _cs_flex _cs_flex-j--center _cs_flex-a--center">
                        <div class="_cs_flex _cs_flex-j--center _cs_flex-a--center _cs_m-down--lg">
                          <ProfileImage class="_cs_flex _cs_flex-a--start _cs_flex-j--center _cs_shadow-primary-0-0--1 _cs_border-radius--full _cs_overflow-hidden  _cs_child-img-w--full _cs_size-w-px--130 _cs_size-h-px--130"
                                        [fileId]="BehavRet()">
                          </ProfileImage>
                        </div>

                        <div class="_cs_flex _cs_flex-j--center _cs_flex-a--center _cs_m-down--lg"
                             *ngIf="studAnketaData.studentName">
                          <div class="_cs_flex _cs_flex-a--center _cs_flex-j--center"
                               *ngFor="let st of studAnketaData.studentName; let i=index">

                            <span class="_cs_label _cs_label-md _cs_color-primary-2 _cs_m-right--sm" *ngIf="st.languageId==languageId">{{st.fName}}</span>
                            <span class="_cs_label _cs_label-md _cs_color-primary-2 " *ngIf="st.languageId==languageId">{{st.sName}}</span>

                          </div>
                        </div>
                        <FileUpload (onFileUpload)="FileUploadedProfileImage($event)"></FileUpload>
                      </div>
                    </div>
                    <div class="_cs_col-md-8 _cs_col-xs-12 _cs_col-sm-8">
                      <table class='table table-bordered table-hover '>
                        <tbody>
                            <ng-container *ngFor="let st of studAnketaData.studentName; let i=index">

                              <tr>
                                <td>
                                  {{captions.fName}}  {{st.languageId==2&&languageId==1?" (ლათ.)":(st.languageId==1&&languageId==2?" (Geo.)":"")}}:
                                </td>
                                <td>
                                  {{st.fName}}
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  {{captions.sName}}  {{st.languageId==2&&languageId==1?" (ლათ.)":(st.languageId==1&&languageId==2?" (Geo.)":"")}}:
                                </td>
                                <td>
                                  {{st.sName}}
                                </td>
                              </tr>

                            </ng-container>

                          <tr>
                            <td><b>{{captions.persNumber}}:</b></td>
                            <td>
                              {{studAnketaData.persNumber}} &nbsp;
                            </td>
                          </tr>
                          <tr>
                            <td><b>{{captions.birthDay}}:</b></td>
                            <td>

                              {{studAnketaData.birthDay| date:'yyyy-MM-dd'}} &nbsp;
                            </td>
                          </tr>
                          <tr>
                            <td><b>{{captions.sex}}:</b></td>
                            <td>
                              {{studAnketaData.sexCaption}}
                            </td>
                          </tr>
                          <tr>
                            <td><b>{{captions.faculty}}:</b></td>
                            <td>


                              {{ studAnketaData.fakulty }}

                            </td>
                          </tr>
                          <tr>
                            <td><b>{{captions.studyLevel}}:</b></td>
                            <td>


                              {{ studAnketaData.studyLevel }}

                            </td>
                          </tr>
                          <ng-container *ngFor="let st of studAnketaData.studentPrograms; let i=index">
                            <tr>
                              <td> <b>{{captions.studyProgram}}:</b></td>
                              <td>

                                {{ st.studyProgramName }}
                              </td>
                            </tr>
                            <tr *ngIf="st.subProgramName">
                              <td> <b>{{captions.specialization}}:</b></td>
                              <td>

                                {{ st.subProgramName }}
                              </td>
                            </tr>
                          </ng-container>
                          <tr>
                            <td><b>{{captions.group}}:</b></td>
                            <td *ngIf="studAnketaData.studGroupId">

                              {{studAnketaData.studGroup}}

                            </td>
                          </tr>
                          <tr>
                            <td><b>{{captions.enterYear}}:</b></td>
                            <td>{{ studAnketaData.enterYear }}</td>
                          </tr>
                          <tr>
                            <td><b>{{captions.naecCode}}:</b></td>
                            <td>{{ studAnketaData.naecReiting }}</td>
                          </tr>
                          <tr>
                            <td><b>{{captions.semester}}:</b></td>
                            <td>
                              {{studAnketaData.semester}} &nbsp;
                            </td>
                          </tr>
                          <tr>
                            <td><b>{{captions.status}}:</b></td>
                            <td>{{ studAnketaData.studyStatus }}</td>
                          </tr>
                          <ng-container *ngFor="let st of studAnketaData.editionAnketData; let i=index">
                            <tr *ngIf="!st.evaluateEnabled">
                              <td>
                                {{ st.dataTypeCaption }}&nbsp; &nbsp;
                              </td>
                              <td>
                                {{st.dataEnterTypeId==1? st.description:st.dataEnterTypeId==2?st.elementCaption:st.boolAnswer }}
                              </td>
                            </tr>
                          </ng-container>
                        </tbody>
                      </table>
   
                    </div>
                  </div>
                </div>
              </div>


            </div>
          </div>
          <div class="_cs_size-w-precent--100" *ngIf="profileItemNumber==2">

            <div class="_cs_color-bg--primary-5 _cs_size-w-percent--100  _cs_shadow-primary-0-0--1 _cs_border-radius--sm _cs_flex _cs_flex-wrap _cs_position-relative _cs_p-sm _cs_p-down--none">
              <button class="_cs_button _cs_button-sm _cs_button-border--positive _cs_border-radius--sm _cs_m-down--sm _cs_m-left--xs _cs_p-left--sm _cs_p-right--sm"
                      (click)="addCRow('contact')">
                <span class="_cs_icon _cs_icon-sm _cs_icon-add"></span>
                <!-- <span
      class="_cs_label _cs_label-sm _cs_label-font-setting-case-on _cs_label-bold _cs_label-t-u _cs_color-primary-6">კონტაქტის
      დამატება</span> -->
              </button>
              <button (click)="SaveStudentData()"><mat-icon aria-hidden="false" aria-label="Example save icon">save</mat-icon></button>
            </div>
            <div class="_cs_col-xs-12 _cs_scrolled-table">
              <table class='table table-bordered table-hover ' *ngIf="studAnketaData.personContactData">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>{{captions.category}}</th>
                    <th>{{captions.description}}</th>
                    <th>{{captions.language}}</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let st of studAnketaData.personContactData; let i=index">
                    <td>{{ i }}</td>
                    <td>
                      <mat-form-field *ngIf="editCell.rowId==(i+1) && editCell.cellValue=='contactcategoryName'">
                        <!--<mat-label>Pokemon</mat-label>-->
                        <mat-select placeholder={{captions.contactType}} [(ngModel)]="st.categoryId">
                          <mat-option>-- None --</mat-option>
                          <mat-optgroup *ngFor="let group of contactTypeGroupCategorie" [label]="group.caption"
                            [disabled]="group.disabled">
                            <mat-option *ngFor="let ct of group.contactTypeCategories" [value]="ct.id"
                              (click)="st.categoryName=ct.caption;">
                              {{ct.caption}}
                            </mat-option>
                          </mat-optgroup>
                        </mat-select>
                      </mat-form-field>
                      <div *ngIf="!(editCell.rowId==(i+1) && editCell.cellValue=='contactcategoryName')"
                        (click)="toggleCaption({rowId:(i+1),cellValue:'contactcategoryName'},st)">
                        {{ st.categoryName }}&nbsp; &nbsp;
                      </div>
                    </td>
                    <td (click)="toggleCaption({rowId:(i+1),cellValue:'contactdescriptionLanguage'},st)">
                      <div *ngIf="editCell.rowId==(i+1) &&editCell.cellValue=='contactdescriptionLanguage'">
                        <input   [(ngModel)]="st.description"  class="widthInput"/>
                      </div>
                      <div *ngIf="!(editCell.rowId==(i+1) && editCell.cellValue=='contactdescriptionLanguage')">
                        {{ st.description }} &nbsp;&nbsp;
                      </div>


                    </td>
                    <td>
                      <mat-select placeholder={{captions.language}} [(ngModel)]="st.languageId"
                        *ngIf="editCell.rowId==(i+1) && editCell.cellValue=='contactLanguage'">
                        <mat-option *ngFor="let ls of languageList" [value]="ls.id" (click)="st.language=ls.caption;">
                          {{ls.caption}}
                        </mat-option>
                      </mat-select>
                      <div *ngIf="!(editCell.rowId==(i+1) && editCell.cellValue=='contactLanguage')"
                        (click)="toggleCaption({rowId:(i+1),cellValue:'contactLanguage'},st)">
                        {{st.language}} &nbsp; &nbsp;
                      </div>
                    </td>
                    <td>
                      <button (click)="RemoveCRaw('contact',st)">{{captions.delete}}</button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="_cs_size-w-precent--100" *ngIf="profileItemNumber==31">
            <div class="_cs_color-bg--primary-5 _cs_size-w-percent--100  _cs_shadow-primary-0-0--1 _cs_border-radius--sm _cs_flex _cs_flex-wrap _cs_position-relative _cs_p-sm _cs_p-down--none">

              <button class="_cs_button _cs_button-sm _cs_button-border--positive _cs_border-radius--sm _cs_m-down--sm _cs_m-left--xs _cs_p-left--sm _cs_p-right--sm"
                      (click)="addCRow('perEducataion')">
                <span class="_cs_icon _cs_icon-sm _cs_icon-add"></span>
                <!-- <span
    class="_cs_label _cs_label-sm _cs_label-font-setting-case-on _cs_label-bold _cs_label-t-u _cs_color-primary-6">დასახელების
    დამატება</span> -->
              </button>
              <button (click)="SaveStudentData()"><mat-icon aria-hidden="false" aria-label="Example save icon">save</mat-icon></button>
            </div>
            <div class="_cs_col-xs-12 _cs_scrolled-table">
              <table class='table table-bordered table-hover' *ngIf="studAnketaData.perEducataion">
                <thead>
                  <tr>
                    <!--<th>#</th>-->
                    <th>{{captions.language}}</th>
                    <th>{{captions.quality}}</th>
                    <th>{{captions.assignmentDate}}</th>
                    <th>{{captions.diplomaNumber}}</th>
                    <th>{{captions.releaseDate}}</th>
                    <th>{{captions.topic}}</th>
                    <th>{{captions.field}}</th>
                    <th>{{captions.qualification}}</th>
                    <th>{{captions.grantingFacility}}</th>
                    <th>{{captions.comment}}</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let st of studAnketaData.perEducataion; let i=index">
                    <!--<td>{{ i }}</td>-->
                    <td>
                      <mat-select placeholder={{captions.language}} [(ngModel)]="st.languageId"
                        *ngIf="editCell.rowId==(i+1) && editCell.cellValue=='DipLanguage'">
                        <mat-option *ngFor="let ls of languageList" [value]="ls.id"
                          (click)="st.languageCaption=ls.caption;">
                          {{ls.caption}}</mat-option>
                      </mat-select>
                      <div *ngIf="!(editCell.rowId==(i+1) && editCell.cellValue=='DipLanguage')"
                        (click)="toggleCaption({rowId:(i+1),cellValue:'DipLanguage'},st)">
                        {{st.languageCaption}} &nbsp;
                      </div>
                    </td>
                    <td>
                      <mat-form-field *ngIf="editCell.rowId==(i+1) &&editCell.cellValue=='edStudyLevel'">
                        <mat-select id="studyLevel" placeholder={{captions.quality}} [(ngModel)]="st.studyLevel">
                          <mat-option *ngFor="let ls of studylevelList" (click)="st.studyLevelCaption=ls.qvalification;"
                                      [value]="ls.id">{{ls.qvalification}}</mat-option>
                        </mat-select>
                      </mat-form-field>
                      <div *ngIf="!(editCell.rowId==(i+1) && editCell.cellValue=='edStudyLevel')"
                        (click)="toggleCaption({rowId:(i+1),cellValue:'edStudyLevel'},st)">
                        {{st.studyLevelCaption}} &nbsp;
                      </div>
                    </td>
                    <td (click)="toggleCaption({rowId:(i+1),cellValue:'assignDate'},st)">
                      <mat-form-field *ngIf="editCell.rowId==(i+1) &&editCell.cellValue=='assignDate'">
                        <input matInput [matDatepicker]="assignDate" placeholder="Choose a date"
                          [(ngModel)]="st.assignDate">
                        <mat-datepicker-toggle matSuffix [for]="assignDate"></mat-datepicker-toggle>
                        <mat-datepicker #assignDate></mat-datepicker>
                      </mat-form-field>
                      <div *ngIf="!(editCell.rowId==(i+1) && editCell.cellValue=='assignDate')">
                        {{st.assignDate}} &nbsp;
                      </div>
                    </td>
                    <td (click)="toggleCaption({rowId:(i+1),cellValue:'documentNumber'},st)">
                      <div *ngIf="editCell.rowId==(i+1) &&editCell.cellValue=='documentNumber'">
                        <input  placeholder={{captions.diplomaNumber}} [(ngModel)]="st.documentNumber" class="widthInput"/>
                      </div>
                      <div *ngIf="!(editCell.rowId==(i+1) && editCell.cellValue=='documentNumber')">
                        {{st.documentNumber}} &nbsp;
                      </div>
                    </td>
                    <td (click)="toggleCaption({rowId:(i+1),cellValue:'givingDate'},st)">
                      <mat-form-field *ngIf="editCell.rowId==(i+1) &&editCell.cellValue=='givingDate'">
                        <input matInput [matDatepicker]="givingDate" placeholder="Choose a date"
                          [(ngModel)]="st.givingDate">
                        <mat-datepicker-toggle matSuffix [for]="givingDate"></mat-datepicker-toggle>
                        <mat-datepicker #givingDate></mat-datepicker>
                      </mat-form-field>
                      <div *ngIf="!(editCell.rowId==(i+1) && editCell.cellValue=='givingDate')">
                        {{st.givingDate}} &nbsp;
                      </div>
                    </td>
                    <td (click)="toggleCaption({rowId:(i+1),cellValue:'theme'},st)">
                      <div *ngIf="editCell.rowId==(i+1) &&editCell.cellValue=='theme'">
                        <input  placeholder={{captions.topic}} [(ngModel)]="st.theme" class="widthInput"/>
                      </div>
                      <div *ngIf="!(editCell.rowId==(i+1) && editCell.cellValue=='theme')">
                        {{st.theme}} &nbsp;
                      </div>
                    </td>
                    <td (click)="toggleCaption({rowId:(i+1),cellValue:'sphere'},st)">
                      <div *ngIf="editCell.rowId==(i+1) &&editCell.cellValue=='sphere'">
                        <input  placeholder={{captions.field}} [(ngModel)]="st.sphere" class="widthInput"/>
                      </div>
                      <div *ngIf="!(editCell.rowId==(i+1) && editCell.cellValue=='sphere')"
                        >
                        {{st.sphere}} &nbsp;
                      </div>
                    </td>
                    <td (click)="toggleCaption({rowId:(i+1),cellValue:'classification'},st)">
                      <div *ngIf="editCell.rowId==(i+1) &&editCell.cellValue=='classification'">
                        <input  placeholder={{captions.qualification}} [(ngModel)]="st.classification" class="widthInput"/>
                      </div>
                      <div *ngIf="!(editCell.rowId==(i+1) && editCell.cellValue=='classification')">
                        {{st.classification}} &nbsp;
                      </div>
                    </td>
                    <td  (click)="toggleCaption({rowId:(i+1),cellValue:'receiverOrganisation'},st)">
                      <div *ngIf="editCell.rowId==(i+1) &&editCell.cellValue=='receiverOrganisation'">
                        <input  placeholder={{captions.grantingFacility}} [(ngModel)]="st.receiverOrganisation" class="widthInput"/>
                      </div>
                      <div *ngIf="!(editCell.rowId==(i+1) && editCell.cellValue=='receiverOrganisation')">
                        {{st.receiverOrganisation}} &nbsp;
                      </div>
                    </td>
                    <td (click)="toggleCaption({rowId:(i+1),cellValue:'comment'},st)">
                      <div *ngIf="editCell.rowId==(i+1) &&editCell.cellValue=='comment'">
                        <input class="widthInput" placeholder={{captions.comment}} [(ngModel)]="st.comment" />
                      </div>
                      <div *ngIf="!(editCell.rowId==(i+1) && editCell.cellValue=='comment')"
                        >
                        {{st.comment}} &nbsp;
                      </div>
                    </td>
                    <td>
                      <button (click)="RemoveCRaw('perEducataion',st)">{{captions.delete}}</button>
                  </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="_cs_size-w-precent--100" *ngIf="profileItemNumber==32">
            <div class="_cs_color-bg--primary-5 _cs_size-w-percent--100  _cs_shadow-primary-0-0--1 _cs_border-radius--sm _cs_flex _cs_flex-wrap _cs_position-relative _cs_p-sm _cs_p-down--none">

              <button class="_cs_button _cs_button-sm _cs_button-border--positive _cs_border-radius--sm _cs_m-down--sm _cs_m-left--xs _cs_p-left--sm _cs_p-right--sm"
                      (click)="addCRow('perConference')">
                <span class="_cs_icon _cs_icon-sm _cs_icon-add"></span>
                <!-- <span
    class="_cs_label _cs_label-sm _cs_label-font-setting-case-on _cs_label-bold _cs_label-t-u _cs_color-primary-6">დასახელების
    დამატება</span> -->
              </button>
              <button (click)="SaveStudentData()"><mat-icon aria-hidden="false" aria-label="Example save icon">save</mat-icon></button>
            </div>
            <div class="_cs_col-xs-12 _cs_scrolled-table">
              <table class='table table-bordered table-hover' *ngIf="studAnketaData.perConference">
                <thead>
                  <tr>
                    <!--<th>#</th>-->
                    <th>{{captions.language}}</th>
                    <th>{{captions.conferenceName}}</th>
                    <th>{{captions.place}}</th>
                    <th>{{captions.date}}</th>
                    <th>{{captions.role}}</th>
                    <th>{{captions.topicReport}}</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let st of studAnketaData.perConference; let i=index">
                    <!--<td>{{ i }}</td>-->
                    <td  (click)="toggleCaption({rowId:(i+1),cellValue:'DipLanguage'},st)">
                      <mat-select placeholder={{captions.language}} [(ngModel)]="st.languageId"
                        *ngIf="editCell.rowId==(i+1) && editCell.cellValue=='DipLanguage'">
                        <mat-option *ngFor="let ls of languageList" [value]="ls.id"
                          (click)="st.languageCaption=ls.caption;">
                          {{ls.caption}}</mat-option>
                      </mat-select>
                      <div *ngIf="!(editCell.rowId==(i+1) && editCell.cellValue=='DipLanguage')">
                        {{st.languageCaption}} &nbsp;
                      </div>
                    </td>
                    <td (click)="toggleCaption({rowId:(i+1),cellValue:'placeName'},st)">
                      <div *ngIf="editCell.rowId==(i+1) &&editCell.cellValue=='placeName'">
                        <input class="widthInput"  [(ngModel)]="st.placeName" />
                      </div>
                      <div *ngIf="!(editCell.rowId==(i+1) && editCell.cellValue=='placeName')"
                        >
                        {{st.placeName}} &nbsp;
                      </div>
                    </td>
                    <td (click)="toggleCaption({rowId:(i+1),cellValue:'conferenceName'},st)">
                      <div *ngIf="editCell.rowId==(i+1) &&editCell.cellValue=='conferenceName'">
                        <input class="widthInput" [(ngModel)]="st.conferenceName" />
                      </div>
                      <div *ngIf="!(editCell.rowId==(i+1) && editCell.cellValue=='conferenceName')">
                        {{st.conferenceName}} &nbsp;
                      </div>
                    </td>
                    <td (click)="toggleCaption({rowId:(i+1),cellValue:'startTimeC'},st)">
                      <mat-form-field *ngIf="editCell.rowId==(i+1) &&editCell.cellValue=='startTimeC'">
                        <input matInput [matDatepicker]="startTimeC" placeholder="Choose a date"
                          [(ngModel)]="st.startTime">
                        <mat-datepicker-toggle matSuffix [for]="startTimeC"></mat-datepicker-toggle>
                        <mat-datepicker #startTimeC></mat-datepicker>
                      </mat-form-field>
                      <div *ngIf="!(editCell.rowId==(i+1) && editCell.cellValue=='startTimeC')">
                        {{st.startTime}} &nbsp;
                      </div>
                    </td>
                    <td (click)="toggleCaption({rowId:(i+1),cellValue:'role'},st)">
                      <div *ngIf="editCell.rowId==(i+1) &&editCell.cellValue=='role'">
                        <input class="widthInput"  [(ngModel)]="st.role" />
                      </div>
                      <div *ngIf="!(editCell.rowId==(i+1) && editCell.cellValue=='role')">
                        {{st.role}} &nbsp;
                      </div>
                    </td>

                    <td (click)="toggleCaption({rowId:(i+1),cellValue:'theme'},st)">
                      <div *ngIf="editCell.rowId==(i+1) &&editCell.cellValue=='theme'">
                        <input class="widthInput" [(ngModel)]="st.theme" />
                      </div>
                      <div *ngIf="!(editCell.rowId==(i+1) && editCell.cellValue=='theme')">
                        {{st.theme}} &nbsp;
                      </div>
                    </td>
                    <td>
                      <button (click)="RemoveCRaw('perConference',st)">{{captions.delete}}</button>
                  </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="_cs_size-w-precent--100" *ngIf="profileItemNumber==33">
            <div class="_cs_color-bg--primary-5 _cs_size-w-percent--100  _cs_shadow-primary-0-0--1 _cs_border-radius--sm _cs_flex _cs_flex-wrap _cs_position-relative _cs_p-sm _cs_p-down--none">

              <button class="_cs_button _cs_button-sm _cs_button-border--positive _cs_border-radius--sm _cs_m-down--sm _cs_m-left--xs _cs_p-left--sm _cs_p-right--sm"
                      (click)="addCRow('perPublications')">
                <span class="_cs_icon _cs_icon-sm _cs_icon-add"></span>
                <!-- <span
    class="_cs_label _cs_label-sm _cs_label-font-setting-case-on _cs_label-bold _cs_label-t-u _cs_color-primary-6">დასახელების
    დამატება</span> -->
              </button>
              <button (click)="SaveStudentData()"><mat-icon aria-hidden="false" aria-label="Example save icon">save</mat-icon></button>
            </div>
            <div class="_cs_col-xs-12 _cs_scrolled-table">
              <table class='table table-bordered table-hover' *ngIf="studAnketaData.perPublications">
                <thead>
                  <tr>
                    <!--<th>#</th>-->
                    <th>{{captions.language}}</th>
                    <th>{{captions.thetitleOfThePaper}}</th>
                    <th>{{captions.publishingHouse}}</th>
                    <th>{{captions.nameOfThePublication}}</th>
                    <th>{{captions.types}}</th>
                    <th>{{captions.coAuthor}}</th>
                    <th>{{captions.date}}</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let st of studAnketaData.perPublications; let i=index">
                    <!--<td>{{ i }}</td>-->
                    <td>
                      <mat-select placeholder={{captions.language}} [(ngModel)]="st.languageId"
                        *ngIf="editCell.rowId==(i+1) && editCell.cellValue=='DipLanguage'">
                        <mat-option *ngFor="let ls of languageList" [value]="ls.id"
                          (click)="st.languageCaption=ls.caption;">
                          {{ls.caption}}</mat-option>
                      </mat-select>
                      <div *ngIf="!(editCell.rowId==(i+1) && editCell.cellValue=='DipLanguage')"
                        (click)="toggleCaption({rowId:(i+1),cellValue:'DipLanguage'},st)">
                        {{st.languageCaption}} &nbsp;
                      </div>
                    </td>
                    <td (click)="toggleCaption({rowId:(i+1),cellValue:'workName'},st)">
                      <div *ngIf="editCell.rowId==(i+1) &&editCell.cellValue=='workName'">
                        <input class="widthInput" placeholder="workName" [(ngModel)]="st.workName" />
                      </div>
                      <div *ngIf="!(editCell.rowId==(i+1) && editCell.cellValue=='workName')">
                        {{st.workName}} &nbsp;
                      </div>
                    </td>
                    <td (click)="toggleCaption({rowId:(i+1),cellValue:'publishingHouse'},st)">
                      <div *ngIf="editCell.rowId==(i+1) &&editCell.cellValue=='publishingHouse'">
                        <input class="widthInput" placeholder="publishingHouse" [(ngModel)]="st.publishingHouse" />
                      </div>
                      <div *ngIf="!(editCell.rowId==(i+1) && editCell.cellValue=='publishingHouse')">
                        {{st.publishingHouse}} &nbsp;
                      </div>
                    </td>
                    <td (click)="toggleCaption({rowId:(i+1),cellValue:'publicationName'},st)">
                      <div *ngIf="editCell.rowId==(i+1) &&editCell.cellValue=='publicationName'">
                        <input class="widthInput" placeholder="publicationName" [(ngModel)]="st.publicationName" />
                      </div>
                      <div *ngIf="!(editCell.rowId==(i+1) && editCell.cellValue=='publicationName')">
                        {{st.publicationName}} &nbsp;
                      </div>
                    </td>
                    <td (click)="toggleCaption({rowId:(i+1),cellValue:'typeName'},st)">
                      <div *ngIf="editCell.rowId==(i+1) &&editCell.cellValue=='typeName'">
                        <input class="widthInput" placeholder="typeName" [(ngModel)]="st.typeName" />
                      </div>
                      <div *ngIf="!(editCell.rowId==(i+1) && editCell.cellValue=='typeName')">
                        {{st.typeName}} &nbsp;
                      </div>
                    </td>
                    <td (click)="toggleCaption({rowId:(i+1),cellValue:'coAuthor'},st)">
                      <div *ngIf="editCell.rowId==(i+1) &&editCell.cellValue=='coAuthor'">
                        <input class="widthInput" placeholder="coAuthor" [(ngModel)]="st.coAuthor" />
                      </div>
                      <div *ngIf="!(editCell.rowId==(i+1) && editCell.cellValue=='coAuthor')">
                        {{st.coAuthor}} &nbsp;
                      </div>
                    </td>
                    <td (click)="toggleCaption({rowId:(i+1),cellValue:'publicDate'},st)">
                      <mat-form-field *ngIf="editCell.rowId==(i+1) &&editCell.cellValue=='publicDate'">
                        <input matInput [matDatepicker]="publicDate" placeholder="Choose a date"
                          [(ngModel)]="st.publicDate">
                        <mat-datepicker-toggle matSuffix [for]="publicDate"></mat-datepicker-toggle>
                        <mat-datepicker #publicDate></mat-datepicker>
                      </mat-form-field>
                      <div *ngIf="!(editCell.rowId==(i+1) && editCell.cellValue=='publicDate')">
                        {{st.publicDate}} &nbsp;
                      </div>
                    </td>
                    <td>
                      <button (click)="RemoveCRaw('perPublications',st)">{{captions.delete}}</button>
                  </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="_cs_size-w-precent--100" *ngIf="profileItemNumber==34">
            <div class="_cs_color-bg--primary-5 _cs_size-w-percent--100  _cs_shadow-primary-0-0--1 _cs_border-radius--sm _cs_flex _cs_flex-wrap _cs_position-relative _cs_p-sm _cs_p-down--none">

              <button class="_cs_button _cs_button-sm _cs_button-border--positive _cs_border-radius--sm _cs_m-down--sm _cs_m-left--xs _cs_p-left--sm _cs_p-right--sm"
                      (click)="addCRow('perProfesionGrowth')">
                <span class="_cs_icon _cs_icon-sm _cs_icon-add"></span>
                <!-- <span
    class="_cs_label _cs_label-sm _cs_label-font-setting-case-on _cs_label-bold _cs_label-t-u _cs_color-primary-6">დასახელების
    დამატება</span> -->
              </button>
              <button (click)="SaveStudentData()"><mat-icon aria-hidden="false" aria-label="Example save icon">save</mat-icon></button>
            </div>
            <div class="_cs_col-xs-12 _cs_scrolled-table">
              <table class='table table-bordered table-hover' *ngIf="studAnketaData.perProfesionGrowth">
                <thead>
                  <tr>
                  </tr>
                  <tr>
                    <!--<th>#</th>-->
                    <th>{{captions.language}}</th>
                    <th>{{captions.activity}}</th>
                    <th>{{captions.institution}}</th>
                    <th>{{captions.fromTheDate}}</th>
                    <th>{{captions.beforeTheDate}}</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let st of studAnketaData.perProfesionGrowth; let i=index">
                    <!--<td>{{ i }}</td>-->
                    <td (click)="toggleCaption({rowId:(i+1),cellValue:'DipLanguage'},st)">
                      <mat-select placeholder={{captions.language}} [(ngModel)]="st.languageId"
                        *ngIf="editCell.rowId==(i+1) && editCell.cellValue=='DipLanguage'">
                        <mat-option *ngFor="let ls of languageList" [value]="ls.id"
                          (click)="st.languageCaption=ls.caption;">
                          {{ls.caption}}</mat-option>
                      </mat-select>
                      <div *ngIf="!(editCell.rowId==(i+1) && editCell.cellValue=='DipLanguage')"
                        >
                        {{st.languageCaption}} &nbsp;
                      </div>
                    </td>
                    <td (click)="toggleCaption({rowId:(i+1),cellValue:'activity'},st)">
                      <div *ngIf="editCell.rowId==(i+1) &&editCell.cellValue=='activity'">
                        <input class="widthInput" placeholder="activity" [(ngModel)]="st.activity" />
                      </div>
                      <div *ngIf="!(editCell.rowId==(i+1) && editCell.cellValue=='activity')"
                        >
                        {{st.activity}} &nbsp;
                      </div>
                    </td>
                    <td (click)="toggleCaption({rowId:(i+1),cellValue:'organisation'},st)">
                      <div *ngIf="editCell.rowId==(i+1) &&editCell.cellValue=='organisation'">
                        <input class="widthInput" placeholder="organisation" [(ngModel)]="st.organisation" />
                      </div>
                      <div *ngIf="!(editCell.rowId==(i+1) && editCell.cellValue=='organisation')"
                        (click)="toggleCaption({rowId:(i+1),cellValue:'organisation'},st)">
                        {{st.organisation}} &nbsp;
                      </div>
                    </td>

                    <td (click)="toggleCaption({rowId:(i+1),cellValue:'startTimeG'},st)">
                      <mat-form-field *ngIf="editCell.rowId==(i+1) &&editCell.cellValue=='startTimeG'">
                        <input matInput [matDatepicker]="startTimeG" placeholder="Choose a date"
                          [(ngModel)]="st.startTime">
                        <mat-datepicker-toggle matSuffix [for]="startTimeG"></mat-datepicker-toggle>
                        <mat-datepicker #startTimeG></mat-datepicker>
                      </mat-form-field>
                      <div *ngIf="!(editCell.rowId==(i+1) && editCell.cellValue=='startTimeG')"
                        >
                        {{st.startTime}} &nbsp;
                      </div>
                    </td>
                    <td (click)="toggleCaption({rowId:(i+1),cellValue:'endTimeG'},st)">
                      <mat-form-field *ngIf="editCell.rowId==(i+1) &&editCell.cellValue=='endTimeG'">
                        <input matInput [matDatepicker]="endTimeG" placeholder="Choose a date" [(ngModel)]="st.endTime">
                        <mat-datepicker-toggle matSuffix [for]="endTimeG"></mat-datepicker-toggle>
                        <mat-datepicker #endTimeG></mat-datepicker>
                      </mat-form-field>
                      <div *ngIf="!(editCell.rowId==(i+1) && editCell.cellValue=='endTimeG')"
                        >
                        {{st.endTime}} &nbsp;
                      </div>
                    </td>
                    <td>
                      <button (click)="RemoveCRaw('perProfesionGrowth',st)">{{captions.delete}}</button>
                  </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="_cs_size-w-precent--100" *ngIf="profileItemNumber==35">
            <div class="_cs_color-bg--primary-5 _cs_size-w-percent--100  _cs_shadow-primary-0-0--1 _cs_border-radius--sm _cs_flex _cs_flex-wrap _cs_position-relative _cs_p-sm _cs_p-down--none">

              <button class="_cs_button _cs_button-sm _cs_button-border--positive _cs_border-radius--sm _cs_m-down--sm _cs_m-left--xs _cs_p-left--sm _cs_p-right--sm"
                      (click)="addCRow('perPracticalExperience')">
                <span class="_cs_icon _cs_icon-sm _cs_icon-add"></span>
                <!-- <span
    class="_cs_label _cs_label-sm _cs_label-font-setting-case-on _cs_label-bold _cs_label-t-u _cs_color-primary-6">დასახელების
    დამატება</span> -->
              </button>
              <button (click)="SaveStudentData()"><mat-icon aria-hidden="false" aria-label="Example save icon">save</mat-icon></button>
            </div>
            <div class="_cs_col-xs-12 _cs_scrolled-table">
              <table class='table table-bordered table-hover' *ngIf="studAnketaData.perPracticalExperience">
                <thead>
                  <tr>
                    <!--<th>#</th>-->
                    <th>{{captions.language}}</th>
                    <th>{{captions.organization}}</th>
                    <th>{{captions.positionP}}</th>
                    <th>{{captions.function}}</th>
                    <th>{{captions.fromTheDate}}</th>
                    <th>{{captions.beforeTheDate}}</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let st of studAnketaData.perPracticalExperience; let i=index">
                    <!--<td>{{ i }}</td>-->
                    <td (click)="toggleCaption({rowId:(i+1),cellValue:'DipLanguage'},st)">
                      <mat-select placeholder={{captions.language}} [(ngModel)]="st.languageId"
                        *ngIf="editCell.rowId==(i+1) && editCell.cellValue=='DipLanguage'">
                        <mat-option *ngFor="let ls of languageList" [value]="ls.id"
                          (click)="st.languageCaption=ls.caption;">
                          {{ls.caption}}</mat-option>
                      </mat-select>
                      <div *ngIf="!(editCell.rowId==(i+1) && editCell.cellValue=='DipLanguage')"
                        >
                        {{st.languageCaption}} &nbsp;
                      </div>
                    </td>
                    <td (click)="toggleCaption({rowId:(i+1),cellValue:'organisation'},st)">
                      <div *ngIf="editCell.rowId==(i+1) &&editCell.cellValue=='organisation'">
                        <input  class="widthInput"  [(ngModel)]="st.organisation" />
                      </div>
                      <div *ngIf="!(editCell.rowId==(i+1) && editCell.cellValue=='organisation')"
                        >
                        {{st.organisation}} &nbsp;
                      </div>
                    </td>
                    <td (click)="toggleCaption({rowId:(i+1),cellValue:'positionName'},st)">
                      <div *ngIf="editCell.rowId==(i+1) &&editCell.cellValue=='positionName'">
                        <input class="widthInput"  [(ngModel)]="st.positionName" />
                      </div>
                      <div *ngIf="!(editCell.rowId==(i+1) && editCell.cellValue=='positionName')"
                        >
                        {{st.positionName}} &nbsp;
                      </div>
                    </td>
                    <td (click)="toggleCaption({rowId:(i+1),cellValue:'functionsName'},st)">
                      <div *ngIf="editCell.rowId==(i+1) &&editCell.cellValue=='functionsName'">
                        <input class="widthInput"  [(ngModel)]="st.functionsName" />
                      </div>
                      <div *ngIf="!(editCell.rowId==(i+1) && editCell.cellValue=='functionsName')"
                        >
                        {{st.functionsName}} &nbsp;
                      </div>
                    </td>
                    <td (click)="toggleCaption({rowId:(i+1),cellValue:'startTimeE'},st)">
                      <mat-form-field *ngIf="editCell.rowId==(i+1) &&editCell.cellValue=='startTimeE'">
                        <input matInput [matDatepicker]="startTimeE" placeholder="Choose a date"
                          [(ngModel)]="st.startTime">
                        <mat-datepicker-toggle matSuffix [for]="startTimeE"></mat-datepicker-toggle>
                        <mat-datepicker #startTimeE></mat-datepicker>
                      </mat-form-field>
                      <div *ngIf="!(editCell.rowId==(i+1) && editCell.cellValue=='startTimeE')"
                        >
                        {{st.startTime}} &nbsp;
                      </div>
                    </td>
                    <td (click)="toggleCaption({rowId:(i+1),cellValue:'endTimeE'},st)">
                      <mat-form-field *ngIf="editCell.rowId==(i+1) &&editCell.cellValue=='endTimeE'">
                        <input matInput [matDatepicker]="endTimeE" placeholder="Choose a date" [(ngModel)]="st.endTime">
                        <mat-datepicker-toggle matSuffix [for]="endTimeE"></mat-datepicker-toggle>
                        <mat-datepicker #endTimeE></mat-datepicker>
                      </mat-form-field>
                      <div *ngIf="!(editCell.rowId==(i+1) && editCell.cellValue=='endTimeE')"
                        >
                        {{st.endTime}} &nbsp;
                      </div>
                    </td>
                    <td>
                      <button (click)="RemoveCRaw('perPracticalExperience',st)">{{captions.delete}}</button>
                  </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="_cs_size-w-precent--100" *ngIf="profileItemNumber==36">
            <div class="_cs_color-bg--primary-5 _cs_size-w-percent--100  _cs_shadow-primary-0-0--1 _cs_border-radius--sm _cs_flex _cs_flex-wrap _cs_position-relative _cs_p-sm _cs_p-down--none">

              <button class="_cs_button _cs_button-sm _cs_button-border--positive _cs_border-radius--sm _cs_m-down--sm _cs_m-left--xs _cs_p-left--sm _cs_p-right--sm"
                      (click)="addCRow('perProjects')">
                <span class="_cs_icon _cs_icon-sm _cs_icon-add"></span>
                <!-- <span
    class="_cs_label _cs_label-sm _cs_label-font-setting-case-on _cs_label-bold _cs_label-t-u _cs_color-primary-6">დასახელების
    დამატება</span> -->
              </button>
              <button (click)="SaveStudentData()"><mat-icon aria-hidden="false" aria-label="Example save icon">save</mat-icon></button>
            </div>
            <div class="_cs_col-xs-12 _cs_scrolled-table">

              <table class='table table-bordered table-hover' *ngIf="studAnketaData.perProjects">
                <thead>
                  <tr>
                    <!--<th>#</th>-->
                    <th>{{captions.language}}</th>
                    <th>{{captions.nameOfProjects}}</th>
                    <th>{{captions.briefDescriptionOfTheProjects}}</th>
                    <th>{{captions.placeOfImplementation}}</th>
                    <th>{{captions.fromTheDate}}</th>
                    <th>{{captions.beforeTheDate}}</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let st of studAnketaData.perProjects; let i=index">
                    <!--<td>{{ i }}</td>-->
                    <td (click)="toggleCaption({rowId:(i+1),cellValue:'DipLanguage'},st)">
                      <mat-select placeholder={{captions.language}} [(ngModel)]="st.languageId"
                        *ngIf="editCell.rowId==(i+1) && editCell.cellValue=='DipLanguage'">
                        <mat-option *ngFor="let ls of languageList" [value]="ls.id"
                          (click)="st.languageCaption=ls.caption;">
                          {{ls.caption}}</mat-option>
                      </mat-select>
                      <div *ngIf="!(editCell.rowId==(i+1) && editCell.cellValue=='DipLanguage')"
                        >
                        {{st.languageCaption}} &nbsp;
                      </div>
                    </td>
                    <td (click)="toggleCaption({rowId:(i+1),cellValue:'projectName'},st)">
                      <div *ngIf="editCell.rowId==(i+1) &&editCell.cellValue=='projectName'">
                        <input class="widthInput" placeholder="projectName" [(ngModel)]="st.projectName" />
                      </div>
                      <div *ngIf="!(editCell.rowId==(i+1) && editCell.cellValue=='projectName')"
                        >
                        {{st.projectName}} &nbsp;
                      </div>
                    </td>
                    <td (click)="toggleCaption({rowId:(i+1),cellValue:'projectshortDescription'},st)">
                      <div *ngIf="editCell.rowId==(i+1) &&editCell.cellValue=='projectshortDescription'">
                        <input  class="widthInput" placeholder="projectshortDescription"
                          [(ngModel)]="st.projectshortDescription" />
                      </div>
                      <div *ngIf="!(editCell.rowId==(i+1) && editCell.cellValue=='projectshortDescription')"
                        >
                        {{st.projectshortDescription}} &nbsp;
                      </div>
                    </td>
                    <td (click)="toggleCaption({rowId:(i+1),cellValue:'implementationPlace'},st)">
                      <div *ngIf="editCell.rowId==(i+1) &&editCell.cellValue=='implementationPlace'">
                        <input class="widthInput" placeholder="implementationPlace" [(ngModel)]="st.implementationPlace" />
                      </div>
                      <div *ngIf="!(editCell.rowId==(i+1) && editCell.cellValue=='implementationPlace')"
                        >
                        {{st.implementationPlace}} &nbsp;
                      </div>
                    </td>
                    <td (click)="toggleCaption({rowId:(i+1),cellValue:'startTimePr'},st)">
                      <mat-form-field *ngIf="editCell.rowId==(i+1) &&editCell.cellValue=='startTimePr'">
                        <input matInput [matDatepicker]="startTimePr" placeholder="Choose a date"
                          [(ngModel)]="st.startTime">
                        <mat-datepicker-toggle matSuffix [for]="startTimePr"></mat-datepicker-toggle>
                        <mat-datepicker #startTimePr></mat-datepicker>
                      </mat-form-field>
                      <div *ngIf="!(editCell.rowId==(i+1) && editCell.cellValue=='startTimePr')"
                        >
                        {{st.startTime}} &nbsp;
                      </div>
                    </td>
                    <td (click)="toggleCaption({rowId:(i+1),cellValue:'endTimePr'},st)">
                      <mat-form-field *ngIf="editCell.rowId==(i+1) &&editCell.cellValue=='endTimePr'">
                        <input matInput [matDatepicker]="endTimePr" placeholder="Choose a date"
                          [(ngModel)]="st.endTime">
                        <mat-datepicker-toggle matSuffix [for]="endTimePr"></mat-datepicker-toggle>
                        <mat-datepicker #endTimePr></mat-datepicker>
                      </mat-form-field>
                      <div *ngIf="!(editCell.rowId==(i+1) && editCell.cellValue=='endTimePr')"
                        >
                        {{st.endTime}} &nbsp;
                      </div>
                    </td>
                    <td>
                      <button (click)="RemoveCRaw('perProjects',st)">{{captions.delete}}</button>
                  </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="_cs_size-w-precent--100" *ngIf="profileItemNumber==37">
            <div class="_cs_color-bg--primary-5 _cs_size-w-percent--100  _cs_shadow-primary-0-0--1 _cs_border-radius--sm _cs_flex _cs_flex-wrap _cs_position-relative _cs_p-sm _cs_p-down--none">

              <button class="_cs_button _cs_button-sm _cs_button-border--positive _cs_border-radius--sm _cs_m-down--sm _cs_m-left--xs _cs_p-left--sm _cs_p-right--sm"
                      (click)="addAnk2Data(true)">
                <span class="_cs_icon _cs_icon-sm _cs_icon-add"></span>
                <!-- <span
    class="_cs_label _cs_label-sm _cs_label-font-setting-case-on _cs_label-bold _cs_label-t-u _cs_color-primary-6">მონაცემების
    დამატება</span> -->
              </button>
              <button (click)="SaveStudentData()"><mat-icon aria-hidden="false" aria-label="Example save icon">save</mat-icon></button>
            </div>
            <div class="_cs_col-xs-12 _cs_scrolled-table">

              <table class='table table-bordered table-hover ' *ngIf="studAnketaData.personContactData">
                <thead>
                  <tr>
                    <th>{{captions.category}}</th>
                    <th>{{captions.description}}</th>
                    <th>{{captions.level}}</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container *ngFor="let st of studAnketaData.editionAnketData; let i=index">
                    <tr *ngIf="st.evaluateEnabled">

                      <td (click)="toggleCaption({rowId:(i+1),cellValue:'dataTypeCaption'},st)">
                        <mat-form-field *ngIf="editCell.rowId==(i+1) && editCell.cellValue=='dataTypeCaption'">
                          <!--<mat-label>Pokemon</mat-label>-->
                          <mat-select placeholder={{captions.dataType}} [(ngModel)]="st.dataTypeId">
                            <!--<mat-option *ngFor="let ct of editionDataTypeList" [value]="ct.id" (click)="st.dataTypeCaption=ct.caption;st.dataEnterTypeId=ct.dataEnterTypeId;st.dataTypeId=ct.id;st.evaluateEnabled=ct.evaluateEnabled">
                            {{ct.caption}}
                          </mat-option>-->
                            <ng-container *ngFor="let ct of editionDataTypeList">
                              <mat-option *ngIf="ct.evaluateEnabled" [value]="ct.id"
                                (click)="st.dataTypeCaption=ct.caption;st.dataEnterTypeId=ct.dataEnterTypeId;st.dataTypeId=ct.id;st.evaluateEnabled=ct.evaluateEnabled">
                                {{ct.caption}}
                              </mat-option>
                            </ng-container>
                          </mat-select>
                        </mat-form-field>
                        <div *ngIf="!(editCell.rowId==(i+1) && editCell.cellValue=='dataTypeCaption')"
                          >
                          {{ st.dataTypeCaption }}&nbsp; &nbsp;
                        </div>
                      </td>
                      <td (click)="toggleCaption({rowId:(i+1),cellValue:'elementCaption'},st);LoadEditionDataElementList(st.dataTypeId);">
                        <mat-form-field
                          *ngIf="editCell.rowId==(i+1) && editCell.cellValue=='elementCaption' &&st.dataEnterTypeId==2">
                          <!--<mat-label>Pokemon</mat-label>-->
                          <mat-select placeholder={{captions.data}} [(ngModel)]="st.elementId">
                            <mat-option *ngFor="let ct of editionDataElementList" [value]="ct.id"
                              (click)="st.elementCaption=ct.caption;">
                              {{ct.caption}}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                        <div
                          *ngIf="editCell.rowId==(i+1) &&editCell.cellValue=='elementCaption' &&st.dataEnterTypeId==1">
                          <input matInput placeholder="" [(ngModel)]="st.description" />
                        </div>
                        <mat-checkbox
                          *ngIf="editCell.rowId==(i+1) &&editCell.cellValue=='elementCaption' &&st.dataEnterTypeId==3"
                          [(ngModel)]="st.boolAnswer"></mat-checkbox>

                        <div *ngIf="!(editCell.rowId==(i+1) && editCell.cellValue=='elementCaption')"
                          >

                          {{st.dataEnterTypeId==1? st.description:st.dataEnterTypeId==2?st.elementCaption:st.boolAnswer }}
                          &nbsp;&nbsp;
                        </div>
                      </td>
                      <td (click)="toggleCaption({rowId:(i+1),cellValue:'evaluate'},st)">

                        <mat-form-field *ngIf="editCell.rowId==(i+1) && editCell.cellValue=='evaluate'">
                          <!--<mat-label>Pokemon</mat-label>-->
                          <mat-select placeholder={{captions.gradeSimbole}} [(ngModel)]="st.evaluateId">
                            <mat-option *ngFor="let ct of evaluationTypes" [value]="ct.id"
                              (click)="st.evaluateCaption=ct.caption;">
                              {{ct.caption}}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>

                        <div *ngIf="!(editCell.rowId==(i+1) && editCell.cellValue=='evaluate')"
                          >
                          {{ st.evaluateCaption }}&nbsp; &nbsp;
                        </div>

                      </td>
                      <td>
                        <button (click)="RemoveCRaw('anket2',st)">{{captions.delete}}</button>
                    </td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
            </div>
          </div>
        
          <div class="_cs_size-w-precent--100" *ngIf="profileItemNumber==5">

            <div class="_cs_col-xs-12 _cs_scrolled-table ">

              <table class='table table-bordered table-hover ' *ngIf="studAnketaData.personOrders">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>{{captions.numberN}}</th>
                    <th>{{captions.date}}</th>
                    <th>{{captions.structType}}</th>
                    <th>{{captions.title}}</th>
                    <th>{{captions.description}}</th>
                    <th>{{captions.document}}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let st of studAnketaData.personOrders; let i=index" class='clickable-row'
                    (dblclick)="showAnketa()" (click)="setClickedNameRow(i,st.id)" [class.active]="i == nameRowindex">
                    <td>{{ (i+1) }}</td>
                    <td>{{ st.orderNumber }}</td>
                    <td>{{ st.orderDate| date:'yyyy-MM-dd' }}</td>
                    <td>{{ st.orderCategoryName }}</td>
                    <td>{{ st.orderSubject }}</td>
                    <td>{{ st.orderDescription }}</td>
                    <td>
                      <button *ngFor="let s of st.fileList; let k=index" class="link"
                        (click)="DownloadDocument(s.fileId)">
                        {{(k+1)+". "+s.fileName}}
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="_cs_size-w-precent--100" *ngIf="profileItemNumber==6">
            <div class="_cs_flex _cs_flex-j--center cs_size-w-precent--100 _cs_border-radius--xxl _cs_shadow-primary-0-0--1
          _cs_color-bg--primary-5 _cs_m-down--xl _cs_p-left--md _cs_p-right--md">
              <div class="_cs_flex _cs_flex-a--center _cs_col-xs-12">
                <mat-form-field class="_cs_size-w-percent--100 _cs_p-md _cs_p-down--none">
                  <mat-select placeholder={{captions.documentType}} [(ngModel)]="selectedFileFor">
                    <mat-option *ngFor="let ls of fileForList" [value]="ls">{{ls.caption}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>

            <FileUpload (onFileUpload)="FileUploaded($event)" *ngIf="selectedFileFor">

            </FileUpload>
            <div class="_cs_col-xs-12 _cs_scrolled-table ">

              <table class='table table-bordered'>
                <thead>

                  <tr>
                    <th>#</th>
                    <th>{{captions.documentCategory}}</th>
                    <th>{{captions.documentName}}</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let ll of studAnketaData.files; let i=index" class='clickable-row'>
                    <td>{{i+1}}</td>
                    <td>{{ll.fileFor}}</td>
                    <td>
                      <button class="link" (click)="DownloadDocument(ll.fileStream_id)">{{ll.fileName}}</button>
                    </td>
                    <td>
                      <button (click)="RemoveCRaw('documentFile',ll)">{{captions.delete}}</button>
                   </td>
                  </tr>

                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>


</div>
