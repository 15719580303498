<div class="_cs_flex _cs_size-w-percent--100">
  <div class="_cs_size-w-percent--100 _cs_p-left--lg _cs_p-right--lg _cs_p-down--lg">
    <div [ngClass]="status ? 'sidebarbg--hidden' : 'sidebarbg--show'"
      class="_cs_size-h-percent--100 sidebarbg _cs_flex">
      <!-- sidebarbg__sidebar -->
      <div
        class="sidebarbg__sidebar  _cs_border-radius--sm _cs_color-bg--primary-5 _cs_shadow-primary-0-0--1 _cs_m-right--md">
        <div class="_cs_position-absolute _cs_position-left-percent--100 _cs_position-up-px--70 dropmenu">
          <div id="nav-icon" class="dropsidebar" (click)="clickEvent()">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
        <mat-tree class="_cs_size-w-percent--100 _cs_size-h-percent--100" [dataSource]="dataSource"
          [treeControl]="treeControl">
          <!-- This is the tree node template for leaf nodes -->
          <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
            <!-- use a disabled button to provide padding for tree leaf -->
            <button mat-icon-button disabled></button>

            <button
              class="_cs_size-w-percent--100 _cs_color-bg--primary-5 _cs_p-sm _cs_size-h-percent--100 _cs_position-relative _cs_flex _cs_activeitem-opacity-bg-primary-6-0--1 _cs_transition-0--3 _cs_border-rgba-down-primary-6-0--1"
              (click)="FilterItem(node); activeNode = node;" [ngClass]="{ '_cs_active': activeNode === node }">
              <!-- <mat-icon class="mat-icon-rtl-mirror">
                {{node.icon}}
              </mat-icon> -->
              <span class="_cs_m-left--xl _cs_label _cs_label-sm _cs_label-font-setting-case-on _cs_color-primary-2 _cs_activeitem-color--primary-6 _cs_activeitem-color-bg--rgba-primary  _cs_m-top--xs _cs_m-down--xs _cs_label-a-start
">
                {{node.name}}
              </span>
            </button>
          </mat-tree-node>
          <!-- This is the tree node template for expandable nodes -->
          <mat-tree-node *matTreeNodeDef="let node;when: hasChild" matTreeNodePadding>
            <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'toggle ' + node.name">
              <!-- <mat-icon class="mat-icon-rtl-mirror">
                {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
              </mat-icon> -->
            </button>
            <div
              class="_cs_size-w-percent--100 _cs_color-bg--primary-5 _cs_p-sm _cs_size-h-percent--100 _cs_position-relative _cs_flex _cs_activeitem-opacity-bg-primary-6-0--1 _cs_transition-0--3 _cs_border-rgba-down-primary-6-0--1">

               <mat-icon class="mat-icon-rtl-mirror">
                {{node.icon}}
              </mat-icon> 
              <span
                class="_cs_m-left--xl _cs_label _cs_label-sm _cs_label-font-setting-case-on _cs_color-primary-2 _cs_activeitem-color--primary-6 _cs_activeitem-color-bg--rgba-primary  _cs_m-top--xs _cs_m-down--xs _cs_label-a-start">

                {{node.name}}
              </span>
            </div>
          </mat-tree-node>
        </mat-tree>
      </div>
      <div class="sidebarbg__wrapper _cs_size-w-percent--100 _cs_flex _cs_flex-d--column _cs_p-left--md _cs_mat-tab">
        <div class="_cs_m-down--md _cs_m-top--xxl">
          <div
            class="_cs_p-xs _cs_shadow-primary-0-0--1 _cs_border-radius--sm _cs_border-down--primary-6 _cs_flex _cs_flex-a--center">
            <span
              class="_cs_icon _cs_icon-xl _cs_shadow-primary-6-0--2 _cs_border-radius--full _cs_border--primary-6 _cs_m-right--md">
              <span class="_cs_icon _cs_icon-md _cs_color-primary-6 _cs_icon-academic-registration"></span>

            </span> <span class="_cs_label _cs_label-sm _cs_label-font-setting-case-on _cs_label-bold
                _cs_color-primary-6">{{captions.acadRegistration}}</span>
          </div>
        </div>
        <div *ngIf="selectedItemNumber==1&&entitiesDataSource" class=" _cs_size-w-percent--100">
         
              <div *ngIf="registrationStatus&&!registrationEnabled">
                <div *ngFor="let s of registrationStatus">
                  <p *ngIf="!s.status&&s.process==1">{{captions.academicRegistrationProcessIsClosed}}</p>
                  <p *ngIf="!s.status&&s.process==2">{{captions.allowedAcademicRegistration}}</p>
                </div>

              </div>
              <div *ngIf="registrationStatus&&registrationEnabled">
                <div id="search" style="text-align: center;"
                     class=" _cs_size-w-percent--100 _cs_p-md _cs_shadow-primary-0-0--1 _cs_border-radius--sm  _cs_color-bg--primary-5">
                  <div class="_cs_flex  _cs_size-w-percent--100">
                    <div class=" _cs_flex _cs_flex-wrap _cs_size-w-percent--100 _cs_flex _cs_flex-j--center">
                      <div class="_cs_col-xs-12 _cs_col-sm-12 _cs_col-md-6 _cs_m-down--lg _cs_p-left--sm _cs_p-right--sm">
                        <div class="_cs_flex _cs_flex-d--column _cs_p-sm _cs_shadow-primary-0-0--1 _cs_color-bg--primary-5 _cs_border-radius--xxl">
                          <span class="_cs_label _cs_label-md _cs_color-primary-2 _cs_label-500 _cs_label-font-setting-case-on  _cs_flex-j--center">
                            {{captions.allocatedCreditsInSemester}}
                          </span>
                          <span class="_cs_label _cs_label-xl _cs_label-a--center _cs_label-600 _cs_m-top--xxs _cs_flex-j--center">{{enabledCredits}}</span>
                        </div>
                      </div>
                      <div class="_cs_col-xs-12 _cs_col-sm-12 _cs_col-md-6 _cs_m-down--lg _cs_p-left--sm _cs_p-right--sm">
                        <div class="_cs_flex _cs_flex-d--column _cs_p-sm _cs_shadow-primary-0-0--1 _cs_color-bg--primary-5 _cs_border-radius--xxl">
                          <span class="_cs_label _cs_label-md _cs_color-primary-2 _cs_label-500 _cs_label-font-setting-case-on  _cs_flex-j--center">
                            {{captions.abandonedCreditsForChoose}}
                          </span>
                          <span class="_cs_label _cs_label-xl _cs_label-a--center _cs_label-600 _cs_m-top--xs _cs_flex-j--center">{{availableCredits}}</span>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div class="_cs_row">
                    <div class="_cs_col-xs-12 _cs_col-sm-12 _cs_col-md-4 _cs_p-left--sm _cs_p-right--sm">
                      <mat-form-field class="_cs_p-md _cs_shadow-primary-0-0--1 _cs_border-radius--xxl _cs_size-w-percent--100 _cs_color-bg--primary-5 _cs_m-down--md _cs_p-down--none">
                        <mat-select placeholder={{captions.selectFaculty}} [(ngModel)]="facultySelected"
                                    (selectionChange)="fakultyCahnged($event)">
                          <mat-option *ngFor="let ls of fakultyList" [value]="ls.id">{{ls.caption}}</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div class="_cs_col-xs-12 _cs_col-sm-12 _cs_col-md-4 _cs_p-left--sm _cs_p-right--sm">
                      <mat-form-field class="_cs_p-md _cs_shadow-primary-0-0--1 _cs_border-radius--xxl _cs_size-w-percent--100 _cs_color-bg--primary-5 _cs_m-down--md _cs_p-down--none">
                        <mat-select id="program" placeholder={{captions.selectStudyProgram}} [(ngModel)]="selectedProgram">
                          <mat-option *ngFor="let ls of progarmList" [value]="ls.id">{{ls.caption}}</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div class="_cs_col-xs-12 _cs_col-sm-12 _cs_col-md-4 _cs_p-left--sm _cs_p-right--sm">
                      <mat-form-field class="_cs_p-md _cs_shadow-primary-0-0--1 _cs_border-radius--xxl _cs_size-w-percent--100 _cs_color-bg--primary-5 _cs_m-down--md _cs_p-down--none">
                        <input matInput placeholder={{captions.courseName}} [(ngModel)]="courseName">
                      </mat-form-field>
                    </div>
                  </div>
                  <button class="_cs_button _cs_button-sm _cs_button-border--positive _cs_size-w-min-px--150"
                          (click)="Filter()">
                    <span class="_cs_label _cs_label-sm _cs_label-font-setting-case-on">ძიება</span>
                  </button>


                </div>
                <div class="example-loading-shade" *ngIf="isLoadingResults || isRateLimitReached">
                  <mat-spinner *ngIf="isLoadingResults"></mat-spinner>
                  <div class="example-rate-limit-reached" *ngIf="isRateLimitReached">
                    GitHub's API rate limit has been reached. It will be reset in one minute.
                  </div>
                </div>
                <div class="_cs_col-xs-12 _cs_table-container _cs_scrolled-table"
                     id="searchResult">

                  <table cdk-table [dataSource]="entitiesDataSource" class="table table-bordered table-hover">
                    <ng-container cdkColumnDef="#">
                      <th cdk-header-cell *cdkHeaderCellDef> # </th>
                      <td mat-cell *matCellDef="let element; let i = index">{{(i+1)}}</td>
                    </ng-container>
                    <!-- Position Column -->
                    <ng-container cdkColumnDef="syllabusCaption">
                      <th cdk-header-cell *cdkHeaderCellDef>{{captions.subjectName}}  </th>
                      <td cdk-cell *cdkCellDef="let element"> {{element.syllabusCaption}} </td>
                    </ng-container>

                    <!-- Name Column -->
                    <ng-container cdkColumnDef="subjectTypeCaption">
                      <th cdk-header-cell *cdkHeaderCellDef>{{captions.status}} </th>
                      <td cdk-cell *cdkCellDef="let element"> {{element.subjectStatus}} </td>
                    </ng-container>


                    <!-- Weight Column -->
                    <ng-container cdkColumnDef="credit">
                      <th cdk-header-cell *cdkHeaderCellDef>{{captions.credit}} </th>
                      <td cdk-cell *cdkCellDef="let element"> {{element.credit}} </td>
                    </ng-container>

                    <!-- Symbol Column -->
                    <ng-container cdkColumnDef="preconditionComment">
                      <th cdk-header-cell *cdkHeaderCellDef>{{captions.comment}}</th>
                      <td cdk-cell *cdkCellDef="let element">
                        <span [innerHTML]="element.preconditionComment | sanitizeHtml"></span>
                      </td>
                    </ng-container>
                    <ng-container cdkColumnDef="syllabus">
                      <th cdk-header-cell *cdkHeaderCellDef>{{captions.syllabus}}  </th>
                      <td cdk-cell *cdkCellDef="let element">
                        <div *ngFor="let sl of element.syllabusList; let k=index;">
                          <button (click)="DownloadDocument(sl.fileId)" class="btnDocument30Open">
                            <!--class="_cs_button _cs_button-xs _cs_button-border--positive _cs_border-radius--sm _cs_m-left--xs _cs_m-right--xs _cs_p-left--xs _cs_p-right--xs"
                            <span class="_cs_icon _cs_icon-sm _cs_icon-touch"></span>-->
                          </button>                         
                        </div>
                      </td>
                    </ng-container>
                    <ng-container cdkColumnDef="select">
                      <th cdk-header-cell *cdkHeaderCellDef>{{captions.selectCourse}} </th>
                      <td cdk-cell *cdkCellDef="let element">
                        <button *ngIf="availableCredits>=element.credit&&element.enrollEnableOnSyllabus"
                                class="_cs_button _cs_button-border--positive _cs_button-xs _cs_m-dow--none"
                                (click)="ShowCourseGroupsForSelect(element.courseId)">
                          <span class="_cs_label _cs_label-sm _cs_label-font-setting-case-on">{{captions.select}}</span>
                        </button>
                      </td>
                    </ng-container>

                    <tr cdk-header-row *cdkHeaderRowDef="displayedColumns"></tr>
                    <tr cdk-row *cdkRowDef="let row; columns: displayedColumns;  let i = index"></tr>
                  </table>

                </div>
                <mat-paginator class="_cs_m-down--md mat-paginator  _cs_shadow-primary-0-0--1 _cs_border-radius--sm mat-paginator"
                               [pageSizeOptions]="[20, 50, 100]" showFirstLastButtons #paginator [length]="resultsLength">
                </mat-paginator>

              </div>
           
        </div>

        <div *ngIf="selectedItemNumber==2" class=" _cs_size-w-percent--100">

          <div id="selectedSubjects">
            <table *ngIf="studentCurrentSessionSelectedSubjects" class="table table-bordered table-hover">
              <thead>
                <tr>
                  <th>#</th>
                  <th>{{captions.subjectName}}</th>
                  <th>{{captions.lecturers}}</th>
                  <th>{{captions.syllabus}}</th>
                  <th>{{captions.credit}}</th>
                  <th>{{captions.studyMaterial}}</th>
                  <ng-container *ngIf="registrationEnabled">
                    <th></th>
                    <th></th>
                  </ng-container>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let sc of studentCurrentSessionSelectedSubjects; let i=index;">
                  <td>{{(i+1)}}</td>
                  <td>{{sc.syllabusCaption}}</td>
                  <td>
                    <div *ngFor="let sl of sc.lectureList; let k=index;">
                      {{(k+1)+". "+sl.groupTypeCaption+": "+sl.fName+" "+sl.sName}}
                    </div>
                  </td>
                  <td>
                    <div *ngFor="let sl of sc.syllabusList; let k=index;">
                      <button (click)="DownloadDocument(sl.fileId)" class="btnDocument30Open">
                        <!--class="_cs_button _cs_button-xs _cs_button-border--positive _cs_border-radius--sm _cs_m-left--xs _cs_m-right--xs _cs_p-left--xs _cs_p-right--xs"
                        <span class="_cs_icon _cs_icon-sm _cs_icon-touch"></span>-->

                      </button>
                    </div>
                  </td>
                  <td>{{sc.crdit}}</td>
                  <td>
                    <button class="btnDocument30Open"
                            (click)="showStudyMaterial(sc)">
                      <!--class="_cs_button _cs_button-xs _cs_button-border--positive _cs_border-radius--sm _cs_m-left--xs _cs_m-right--xs _cs_p-left--xs _cs_p-right--xs"
                      <span class="_cs_icon _cs_icon-sm _cs_icon-touch"></span>-->
                      <!-- <span class="_cs_label _cs_label-sm _cs_label-font-setting-case-on _cs_label-500 _cs_label-t-u _cs_color-primary-6">სასწავლო მასალა</span> -->
                    </button>
                  </td>
                  <ng-container *ngIf="registrationEnabled">
                    <td style="text-align: center;">

                      <button class="btnEdit"
                              (click)="EditSelectedCourseGroups(sc.enrollId)">
                        <!--class="_cs_button _cs_button-xs _cs_button-border--positive _cs_border-radius--sm _cs_m-left--xs _cs_m-right--xs _cs_p-left--xs _cs_p-right--xs"
                        <span class="_cs_icon _cs_icon-sm _cs_icon-edit"></span>-->
                        <!-- <span class="_cs_label _cs_label-sm _cs_label-font-setting-case-on _cs_label-500 _cs_label-t-u _cs_color-primary-6"> რედაქტირება</span> -->
                      </button>
                    </td>
                    <td style="text-align: center;">
                      <button class="btnDelete" (click)="DeleteEnrollSubject(sc.enrollId,sc)">

                              <!--class="_cs_button _cs_button-xs _cs_button-border--positive _cs_border-radius--sm _cs_m-left--xs _cs_m-right--xs _cs_p-left--xs _cs_p-right--xs"-->
                              
                        <!--<span class="_cs_icon _cs_icon-sm _cs_icon-cross"></span>-->
                        <!-- <span class="_cs_label _cs_label-sm _cs_label-font-setting-case-on _cs_label-500 _cs_label-t-u _cs_color-primary-6">წაშლა</span> -->
                      </button>
                    </td>
                  </ng-container>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>
